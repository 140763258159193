import { Button, Flex, Heading, Text } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useState } from 'react'
import Billing from '../../../networking/Billing'
import { useRootStore } from '../../../stores/RootStoreContext'
import MarketplaceApp from '../../marketplace/MarketplaceApp'
import AppLauncherHelpText from '../AppLauncherHelpText'
import AppLauncherUpgradeAction from '../AppLauncherUpgradeAction'

interface SubscriptionFrozenProps {
  applicationIdentifier: string
  appDetails: MarketplaceApp | undefined
}

const SubscriptionFrozen = (props: SubscriptionFrozenProps) => {
  const { organisationStore } = useRootStore()

  const [isRedirecting, setIsRedirecting] = useState<boolean>(false)
  const canEditPaymentDetails =
    organisationStore.currentOrganisation?.stripeCustomerId

  const editPaymentDetails = async () => {
    setIsRedirecting(true)
    await Billing.openCustomerPortal(
      organisationStore.currentIdentifier!,
      organisationStore.currentOrganisation!.stripeCustomerId!,
      true
    )
  }

  return (
    <Flex
      className='subscription-expired'
      horizontalAlignment='center'
      verticalAlignment='center'
      direction='vertical'
      gap='small'
      fillContainer
      style={{
        height: '100vh',
        backgroundColor: '#FCFDFE',
      }}
    >
      <img
        src={'/assets/apps/icons/' + props.appDetails?.icon}
        alt=''
        className='app-icon'
        width={48}
      />
      <Heading type='h3' bare>
        Abonnement konnte nicht abgerechnet werden
      </Heading>
      <Text className='explanation'>
        Die Kartenzahlung für {props.appDetails?.title ?? 'diese App'} ist
        mehrmals fehlgeschlagen. Bitte behebe das Zahlungsproblem, um dein
        Abonnement zu reaktivieren.
      </Text>
      <AppLauncherUpgradeAction
        applicationIdentifier={props.applicationIdentifier}
        appDetails={props.appDetails}
        customText='Abonnement reaktivieren'
      />
      {canEditPaymentDetails && (
        <Flex
          className='app-launcher-payment-details-edit'
          verticalAlignment='center'
          direction='vertical'
        >
          <Text>Falls nötig kannst du auch deine Zahlungsmethode ändern.</Text>
          <Button
            onClick={() => editPaymentDetails()}
            variant='secondary'
            isLoading={isRedirecting}
          >
            Zahlungsmethode bearbeiten
          </Button>
        </Flex>
      )}
      <AppLauncherHelpText />
    </Flex>
  )
}

export default observer(SubscriptionFrozen)
