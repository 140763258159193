import MarketplaceAppOffer from './MarketplaceAppOffer'
import MarketplaceApps from './MarketplaceApps'

class MarketplaceAppOffers {
  public static findByCode(
    code: string,
    forApp: string
  ): MarketplaceAppOffer[] | null {
    const app = MarketplaceApps.find((app) => app.identifier === forApp)
    if (app?.pricing.kind !== 'tiered') return null

    const tiers = app.pricing.tiers.filter((tier) =>
      tier.offers?.find((offer) => offer.code === code)
    )
    const offers = tiers?.map(
      (tier) =>
        tier.offers?.find((offer) => offer.code === code) as MarketplaceAppOffer
    )
    if (!offers?.length) return null
    return offers
  }
}

export default MarketplaceAppOffers
