import { motion } from 'framer-motion'
import React from 'react'

export const SettingsHomeTransition = (props: {
  children: React.ReactNode
}) => (
  <motion.div
    initial={{ y: 50, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    transition={{
      duration: 0.4,
      type: 'spring',
      stiffness: 50,
      mass: 0.5,
      delay: 0.1,
    }}
  >
    {props.children}
  </motion.div>
)
