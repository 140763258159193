import { Card, Flex } from '@nextbusiness/infinity-ui'
import { Helmet } from 'react-helmet'
import ManageTeamList from './ManageTeamList'

const SettingsTeam = () => {
  return (
    <div className='settings-subpage page-team'>
      <Helmet>
        <title>Team verwalten - Infinity</title>
      </Helmet>
      <Flex>
        <Card style={{ flexGrow: 1 }} className='team-info'>
          <ManageTeamList />
        </Card>
      </Flex>
    </div>
  )
}

export default SettingsTeam
