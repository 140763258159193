import { Flex, Heading } from '@nextbusiness/infinity-ui'
import MarketplaceApp from '../../marketplace/MarketplaceApp'

interface StartingAppProps {
  appDetails?: MarketplaceApp
}

const StartingApp = (props: StartingAppProps) => (
  <Flex
    horizontalAlignment='center'
    verticalAlignment='center'
    direction='vertical'
    gap='large'
    fillContainer
    style={{
      height: '100vh',
      backgroundColor: '#FCFDFE',
    }}
  >
    {props.appDetails?.icon ? (
      <img
        src={'/assets/apps/icons/' + props.appDetails?.icon}
        alt=''
        className='app-icon'
        width={72}
      />
    ) : (
      <Heading type='h1' bareTop style={{ marginBottom: 0 }}>
        {props.appDetails?.launcherTitle ?? props.appDetails?.title}
      </Heading>
    )}
  </Flex>
)

export default StartingApp
