import {
  Flex,
  Heading,
  InputField,
  Spacer,
  Text,
} from '@nextbusiness/infinity-ui'
import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import { observer } from 'mobx-react'
import { useRootStore } from '../../../stores/RootStoreContext'

interface LoginWelcomeBackFieldsProps {
  password: string
  setPassword: (password: string) => void
  onEnterPressed: () => void
}

const LoginWelcomeBackFields = (props: LoginWelcomeBackFieldsProps) => {
  const { authenticationStore } = useRootStore()
  return (
    <>
      <Heading type='h1' bareTop>
        Willkommen zurück
      </Heading>
      <Spacer size='small' />
      <Flex verticalAlignment='center'>
        <Icon
          className='login-icon'
          icon='identity'
          tint='default'
          size={IconSize.Small}
        />
        <div className='login-identity'>
          <Text className='login-name'>{authenticationStore.loginName}</Text>
          <Text variant='subtle'>{authenticationStore.loginEmail}</Text>
        </div>
      </Flex>
      <Spacer size='tiny' />
      <Text type='paragraph'>
        Bitte gib dein Infinity-Kennwort ein, um dich wieder einzuloggen.
      </Text>
      <InputField
        className='input-fields'
        placeholder='Kennwort'
        type='password'
        value={props.password}
        onChange={props.setPassword}
        icon='access'
        fullWidth
        onKeyDown={(e) => {
          if (e.key === 'Enter') props.onEnterPressed()
        }}
      />
    </>
  )
}

export default observer(LoginWelcomeBackFields)
