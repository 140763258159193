import { Button, Flex } from '@nextbusiness/infinity-ui'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { ReactComponent as AuthPageLogo } from '../../assets/auth-page-logo.svg'
import '../../styles/AuthPages.scss'

interface AuthPageProps {
  children?: React.ReactNode
  className?: string
  pageClassName?: string
}

const AuthPage = (props: AuthPageProps) => {
  const history = useHistory()
  return (
    <div
      className={
        'auth-page' + (props.pageClassName ? ' ' + props.pageClassName : '')
      }
    >
      <Button
        variant='quaternary'
        className='auth-page-logo'
        aria-label='Zur Startseite'
        onClick={() => history.push('/')}
      >
        <AuthPageLogo height='1.9rem' width='9.7rem' />
      </Button>
      <Flex
        direction='vertical'
        horizontalAlignment='center'
        verticalAlignment='center'
        fillContainer
        className='container'
      >
        <div
          className={'content' + (props.className ? ' ' + props.className : '')}
        >
          <Flex
            direction='vertical'
            horizontalAlignment='center'
            verticalAlignment='stretch'
          >
            {props.children}
          </Flex>
        </div>
      </Flex>
    </div>
  )
}

export default AuthPage
