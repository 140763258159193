import classNames from 'classnames'
import './PasswordRequirementCheck.scss'

interface PasswordRequirementCheckProps {
  fulfilled: boolean
  label: string
}

const PasswordRequirementCheck = (props: PasswordRequirementCheckProps) => (
  <div
    className={classNames('password-requirement-check', {
      fulfilled: props.fulfilled,
    })}
  >
    <span>{props.label}</span>
  </div>
)

export default PasswordRequirementCheck
