import { Card, Flex, Text } from '@nextbusiness/infinity-ui'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Contact } from '../../../../model/Contact'
import ContactCSVImportList from './ContactCSVImportList'
import ContactCSVImportArea from './ContactImportArea'

const SettingsContactImport = () => {
  const [contacts, setContacts] = useState<Partial<Contact>[]>()
  const [isImporting, setIsImporting] = useState<boolean>(false)

  return (
    <div className='settings-subpage page-contact-import'>
      <Helmet>
        <title>Kontakte importieren - Infinity</title>
      </Helmet>
      <Flex>
        <Card style={{ flexGrow: 1 }}>
          <div className='settings-content-container'>
            <Text>
              Importiere Kontakte aus anderen Business-Tools.
              <br />
              Es werden CSV-Dateien unterstützt, die aus Bexio exportiert
              wurden.
            </Text>
            <ContactCSVImportArea
              disabled={isImporting}
              onCSVParsed={(contacts) => setContacts(contacts)}
            />
            {contacts !== undefined && (
              <ContactCSVImportList
                contacts={contacts}
                isImporting={isImporting}
                setIsImporting={setIsImporting}
              />
            )}
          </div>
        </Card>
      </Flex>
    </div>
  )
}

export default SettingsContactImport
