import { Button, Text, useNotificationCenter } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import AuthCodeInput from '../../../../components/input/AuthCodeInput'
import SettingsGroup from '../../../../components/settings/SettingsGroup'
import Authentication from '../../../../networking/Authentication'
import { BackendError, ErrorType } from '../../../../networking/Errors'
import { useRootStore } from '../../../../stores/RootStoreContext'

const SetupPartConfirmAndActivate = () => {
  const { authenticationStore } = useRootStore()
  const history = useHistory()

  const notificationCenter = useNotificationCenter()

  const [confirmationCode, setConfirmationCode] = useState<string>('')
  const [isCodeIncorrect, setIsCodeIncorrect] = useState<boolean>(false)
  const [isConfirming, setIsConfirming] = useState<boolean>(false)

  useEffect(() => {
    setIsCodeIncorrect(false)
  }, [confirmationCode])

  const confirmAndEnforce = async () => {
    if (!confirmationCode) return
    setIsConfirming(true)
    try {
      await Authentication.enforce2FA(confirmationCode)
      await authenticationStore.loadUserData()
      notificationCenter.addNotification({
        children:
          'Zwei-Faktor-Authentifizierung wurde erfolgreich eingerichtet.',
        variant: 'success',
      })
      history.push('/settings/security')
    } catch (error) {
      if ((error as BackendError).type === ErrorType.Unauthorised) {
        setIsCodeIncorrect(true)
      } else {
        notificationCenter.addNotification({
          title:
            'Zwei-Faktor-Authentifizierung konnte nicht eingerichtet werden.',
          children: (error as BackendError).message,
          variant: 'error',
        })
      }
    } finally {
      setIsConfirming(false)
    }
  }

  return (
    <SettingsGroup title='3. Bestätigen und aktivieren'>
      <Text type='paragraph'>
        Gib den Bestätigungscode ein, den du in deiner Zwei-Faktor-App zurzeit
        siehst.
      </Text>
      <AuthCodeInput
        onChange={setConfirmationCode}
        hasError={isCodeIncorrect}
        helperText={
          isCodeIncorrect ? 'Der eingegebene Code ist falsch.' : undefined
        }
        onPressEnter={() => confirmAndEnforce()}
      />
      <div className='actions'>
        <Button
          variant='primary'
          onClick={() => confirmAndEnforce()}
          isLoading={isConfirming}
          disabled={!confirmationCode}
        >
          Bestätigen
        </Button>
      </div>
    </SettingsGroup>
  )
}

export default observer(SetupPartConfirmAndActivate)
