import MarketplaceApp from '../MarketplaceApp'

const DevelopmentApps: MarketplaceApp[] = [
  {
    title: 'Infinity Customer Central',
    launcherTitle: 'Customer Central',
    icon: 'app-icon-customer-central.png',
    subtitle: 'Alles für Kundenprojekte an einem Ort',
    developer: 'NextBusiness',
    identifier: 'ch.nextbusiness.infinity.crm',
    subscriptionPriceId: 'price_1Jy3WdJbwTSfij8eecowNt3d',
    keyColor: '#0F913A',
    screenshotURLs: ['crm-1.png'],
    shortAppDescription:
      'Behalte den Überblick über Kundenkommunikation und Aufträge – alles an einem zentralen Ort.',
    launcherAppearance: {
      gradientStartColor: '#096828',
      gradientEndColor: '#45ba38',
    },
    pricing: {
      kind: 'subscription',
      monthlyPrice: 9,
      numberOfTrialDays: 14,
    },
  },

  {
    title: 'Infinity Tasks',
    launcherTitle: 'Tasks',
    icon: 'app-icon-tasks.png',
    subtitle: 'Teamaufgaben besser koordinieren',
    developer: 'NextBusiness',
    identifier: 'ch.nextbusiness.infinity.tasks',
    subscriptionPriceId: 'price_1JzJPmJbwTSfij8eNIl7Frif',
    keyColor: '#086c9c',
    screenshotURLs: ['tasks-1.png', 'tasks-2.png'],
    shortAppDescription:
      'Im hektischen Geschäftsalltag gehen Aufgaben oft unter. Mit Infinity Tasks herrscht Klarheit darüber, wer was bis wann zu erledigen hat.',
    launcherAppearance: {
      gradientStartColor: '#0075ad',
      gradientEndColor: '#164b8f',
    },
    pricing: {
      kind: 'free',
    },
  },
  {
    title: 'Infinity UI Demo',
    launcherTitle: 'UI Demo',
    icon: 'app-icon-ui-demo.png',
    subtitle: 'Component Library entdecken',
    developer: 'NextBusiness',
    identifier: 'ch.nextbusiness.infinity.ui-demo',
    subscriptionPriceId: 'price_1JYniMJbwTSfij8ePWgIvhkn',
    keyColor: '#ff0089',
    screenshotURLs: [
      'ui-demo-1.png',
      'ui-demo-2.png',
      'ui-demo-3.png',
      'ui-demo-4.png',
    ],
    shortAppDescription:
      'Die Infinity UI-Demo-App ist ein Entwicklertool, das alle Komponenten der Infinity UI Library interaktiv demonstriert.',
    withholdAuthentication: true,
    pricing: {
      kind: 'free',
    },
  },
  {
    title: 'Infinity Time',
    launcherTitle: 'Time',
    icon: 'app-icon-time.png',
    subtitle: 'Einfache Zeiterfassung',
    developer: 'NextBusiness',
    identifier: 'ch.nextbusiness.infinity.time',
    subscriptionPriceId: 'price_1MrL8XJbwTSfij8eoXHoM1ve',
    mobileAppLinkingScheme: 'infinity-time',
    keyColor: '#650BAF',
    screenshotURLs: [],
    shortAppDescription:
      'Zeiterfassung für Kundenprojekte und interne Aufgaben. Einfach und übersichtlich.',
    pricing: {
      kind: 'free',
    },
    signupFlowAppearance: {
      tint: 'lavender',
    },
  },
  {
    title: 'Infinity Codename Paul',
    launcherTitle: 'Codename Paul',
    icon: 'app-icon-generic.png',
    subtitle: 'Internal development app',
    developer: 'NextBusiness',
    identifier: 'ch.nextbusiness.infinity.studio',
    subscriptionPriceId: 'price_1NeFRZJbwTSfij8eXpzA6MiQ',
    keyColor: '#F73981',
    screenshotURLs: [],
    shortAppDescription: 'Internal pre-release development app',
    pricing: {
      kind: 'subscription',
      monthlyPrice: 40,
      numberOfTrialDays: 14,
    },
    signupFlowAppearance: {
      tint: 'magenta',
    },
  },
]

export default DevelopmentApps
