import {
  Dialog,
  LoadingIndicator,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import Billing from '../../networking/Billing'
import { useRootStore } from '../../stores/RootStoreContext'
import { useQuery } from '../../utility/hooks'
import MarketplaceApp from './MarketplaceApp'
import MarketplaceAppTierModal from './MarketplaceAppTierModal'
import AppActivatedConfirmation from './app-installation/AppActivatedConfirmation'
import AppStatusAreaActive from './app-installation/AppStatusAreaActive'
import AppStatusAreaCanceled from './app-installation/AppStatusAreaCanceled'
import AppStatusAreaInactive from './app-installation/AppStatusAreaInactive'
import AppStatusAreaTrialing from './app-installation/AppStatusAreaTrialing'

interface MarketplaceAppInstallationAreaProps {
  app: MarketplaceApp
}

const MarketplaceAppInstallationArea = (
  props: MarketplaceAppInstallationAreaProps
) => {
  const query = useQuery()

  const upgrade = query.get('upgrade')
  const action = query.get('action')

  const notificationCenter = useNotificationCenter()
  const { billingStore, organisationStore } = useRootStore()

  const [hasLoadedStripeData, setHasLoadedStripeData] = useState<boolean>(false)
  const [isUpgradeOptionsModalOpen, setIsUpgradeOptionsModalOpen] =
    useState<boolean>(false)

  useEffect(() => {
    const loadStripeDetails = async () => {
      await billingStore.loadBillingDetails()
      setHasLoadedStripeData(true)
    }
    if (organisationStore.currentOrganisation?.stripeCustomerId)
      loadStripeDetails()
  }, [organisationStore.currentOrganisation?.stripeCustomerId])

  const subscription = billingStore.subscriptionForApplication(
    props.app.identifier
  )
  const status = billingStore.statusForApplication(props.app.identifier)

  const [loadingText, setLoadingText] = useState<string>()
  const [errorText, setErrorText] = useState<string>()
  const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>(false)
  const [successAction, setSuccessAction] = useState<
    'started-trial' | 'subscribed'
  >()

  useEffect(() => {
    if (errorText)
      notificationCenter.addNotification({
        children: errorText,
        variant: 'error',
      })
  }, [errorText])

  useEffect(() => {
    if (upgrade === '1') {
      const subscriptionIntent = window.localStorage.getItem(
        'tier-to-subscribe-to'
      )
      if (subscriptionIntent) {
        window.localStorage.removeItem('tier-to-subscribe-to')

        const { app, priceId } = JSON.parse(subscriptionIntent)
        if (app !== props.app.identifier) return

        subscribeToApplication(props.app, priceId)
      } else {
        notificationCenter.addNotification({
          title: 'Zahlungsdetails hinzugefügt',
          children: 'Du kannst nun ein Paket auswählen.',
        })
        if (props.app.pricing.kind === 'tiered') {
          setIsUpgradeOptionsModalOpen(true)
        }
      }
    }
  }, [upgrade])

  useEffect(() => {
    if (action === 'setup-card') {
      setupPayments()
    } else if (action === 'select-plan') {
      setIsUpgradeOptionsModalOpen(true)
    }
  }, [action])

  const subscribeToApplication = async (
    app: MarketplaceApp,
    pricingId?: string
  ) => {
    if (!organisationStore.currentIdentifier) {
      setErrorText('Es ist keine Organisation ausgewählt.')
      return
    }
    setLoadingText('Upgrade erfolgt.')
    setErrorText(undefined)
    try {
      await Billing.subscribeToApplication(
        pricingId ?? app.subscriptionPriceId,
        app.identifier,
        organisationStore.currentIdentifier
      )
      setTimeout(() => {
        setLoadingText(undefined)
        organisationStore.loadOrganisationInformation()
        setSuccessAction('subscribed')
        setShowSuccessDialog(true)
      }, 5000)
    } catch (error: any) {
      setErrorText(
        error.message ?? 'Ein Fehler ist aufgetreten. Bitte versuche es erneut.'
      )
      setLoadingText(undefined)
    }
  }

  const startTrialForApplication = async (app: MarketplaceApp) => {
    if (!organisationStore.currentOrganisation) {
      setErrorText('Es ist keine Organisation ausgewählt.')
      return
    }
    setLoadingText('Testversion wird gestartet.')
    setErrorText(undefined)
    try {
      await Billing.startTrialForApplication(
        organisationStore.currentOrganisation,
        app.identifier,
        app.subscriptionPriceId
      )
      organisationStore.loadOrganisationInformation()
      setSuccessAction('started-trial')
      setShowSuccessDialog(true)
    } catch (error: any) {
      if (error.message === 'You have already subscribed to this application') {
        notificationCenter.addNotification({
          children:
            'Für diese App wurde die Testversion bereits einmal verwendet.',
        })
        if (app.pricing.kind === 'tiered')
          window.setTimeout(() => setIsUpgradeOptionsModalOpen(true), 750)
      } else {
        setErrorText(
          error.message ??
            'Ein Fehler ist aufgetreten. Bitte versuche es erneut.'
        )
      }
    } finally {
      setLoadingText(undefined)
    }
  }

  const setupPayments = () => {
    setLoadingText('Einen Moment bitte.')
    Billing.setupPaymentDetails(
      organisationStore.currentIdentifier!,
      props.app.identifier,
      organisationStore.currentOrganisation?.stripeCustomerId
    )
  }

  const onSubscribeToProduct = () => {
    if (props.app.pricing.kind === 'tiered') {
      setIsUpgradeOptionsModalOpen(true)
    } else {
      subscribeToApplication(props.app)
    }
  }
  const onStartTrial = () => startTrialForApplication(props.app)

  const isStripeDataReady =
    !organisationStore.currentOrganisation?.stripeCustomerId ||
    hasLoadedStripeData

  if (!billingStore.isHydrated || !isStripeDataReady)
    return (
      <div className='app-installation-area'>
        <LoadingIndicator />
      </div>
    )

  return (
    <>
      <div className='app-installation-area'>
        {subscription?.status === 'canceled' ? (
          <AppStatusAreaCanceled reactivate={onSubscribeToProduct} />
        ) : (
          <>
            {status === 'inactive' && (
              <AppStatusAreaInactive
                app={props.app}
                startTrial={onStartTrial}
              />
            )}
            {status === 'trial' && subscription && (
              <AppStatusAreaTrialing
                app={props.app}
                subscription={subscription}
                setupPayments={setupPayments}
                upgrade={onSubscribeToProduct}
              />
            )}
            {status === 'active' && <AppStatusAreaActive app={props.app} />}
          </>
        )}
      </div>
      <Dialog isOpen={loadingText !== undefined} className='loading-dialog'>
        <LoadingIndicator loadingText={loadingText} />
      </Dialog>
      {successAction && (
        <AppActivatedConfirmation
          app={props.app}
          isOpen={showSuccessDialog}
          onClose={() => setShowSuccessDialog(false)}
          successAction={successAction}
        />
      )}
      {props.app.pricing.kind === 'tiered' && (
        <MarketplaceAppTierModal
          isOpen={isUpgradeOptionsModalOpen}
          app={props.app}
          onDismiss={() => setIsUpgradeOptionsModalOpen(false)}
          subscribeToApplication={subscribeToApplication}
          setupPayments={setupPayments}
        />
      )}
    </>
  )
}

export default observer(MarketplaceAppInstallationArea)
