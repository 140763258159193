import {
  Avatar,
  Button,
  Flex,
  Heading,
  Spacer,
  Text,
} from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useMemo, useRef, useState } from 'react'
import OrganisationMember from '../../../model/OrganisationMember'
import { useRootStore } from '../../../stores/RootStoreContext'
import InviteMemberDialog from './InviteMemberDialog'
import RemoveMemberDialog from './RemoveMemberDialog'

const ManageTeamList = () => {
  const { organisationStore } = useRootStore()

  const inviteMemberDialogRef = useRef<HTMLButtonElement>(null)

  const [isInviteMemberDialogOpen, setIsInviteMemberDialogOpen] =
    useState<boolean>(false)

  const membersWithStatus = (status: 'accepted' | 'pending') =>
    organisationStore.members?.filter(
      (member) => member.invitationStatus === status
    )

  return (
    <>
      <div className='settings-content-container'>
        {membersWithStatus('pending')?.length !== 0 ? (
          <Heading type='h3' bareTop>
            Ausstehende Einladungen
          </Heading>
        ) : null}

        {membersWithStatus('pending')?.map((member) => (
          <MemberRow member={member} key={member.id} />
        ))}
        <Spacer size='small' />
        <Flex
          horizontalAlignment='space-between'
          verticalAlignment='flex-start'
        >
          <Heading type='h3' bareTop>
            Mitglieder
          </Heading>
          <Button
            iconLeft='plus'
            ref={inviteMemberDialogRef}
            onClick={() => {
              setIsInviteMemberDialogOpen(true)
            }}
          >
            Mitglied einladen
          </Button>
        </Flex>
        {membersWithStatus('accepted')?.map((member) => (
          <MemberRow member={member} key={member.id} />
        ))}
      </div>
      <InviteMemberDialog
        isOpen={isInviteMemberDialogOpen}
        setIsOpen={setIsInviteMemberDialogOpen}
        ref={inviteMemberDialogRef}
      />
    </>
  )
}

interface MemberRowProps {
  member: OrganisationMember
}

const MemberRow = (props: MemberRowProps) => {
  const { authenticationStore } = useRootStore()

  const removeMemberDialogRef = useRef<HTMLButtonElement>(null)

  const [isRemoveMemberDialogOpen, setIsRemoveMemberDialogOpen] =
    useState<boolean>(false)

  const fullName =
    (props.member.invitationStatus === 'pending'
      ? props.member.id
      : props.member.firstname + ' ' + props.member.lastname) ||
    'Unbekannte Person'

  const accessLevel = useMemo(() => {
    switch (props.member.level) {
      case 'user':
        return 'Mitglied'
      case 'admin':
        return 'Administrator:in'
    }
  }, [props.member.level])

  return (
    <>
      <Flex
        gap='tiny'
        key={props.member.id}
        horizontalAlignment='space-between'
        verticalAlignment='center'
        style={{ margin: '1.6rem 0' }}
      >
        <Avatar name={fullName} />
        <Flex direction='vertical'>
          <Text type='inline' style={{ whiteSpace: 'nowrap' }}>
            {fullName}
          </Text>
          <Text type='inline' variant='subtle'>
            {accessLevel}
          </Text>
        </Flex>
        <div style={{ flexGrow: 1 }} />
        {props.member.id !== authenticationStore.currentUser?.id && (
          <>
            <Button
              iconOnly='delete'
              variant='quaternary'
              ref={removeMemberDialogRef}
              onClick={() => setIsRemoveMemberDialogOpen(true)}
            />
          </>
        )}
      </Flex>
      <RemoveMemberDialog
        isOpen={isRemoveMemberDialogOpen}
        setIsOpen={setIsRemoveMemberDialogOpen}
        ref={removeMemberDialogRef}
        member={props.member}
        fullName={fullName}
      />
    </>
  )
}

export default observer(ManageTeamList)
