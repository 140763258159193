import { Card, Flex, Heading, RadioGroup } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { Helmet } from 'react-helmet'
import { useRootStore } from '../../../stores/RootStoreContext'
import './SettingsAppearance.scss'

const SettingsAppearance = () => {
  const { authenticationStore } = useRootStore()

  return (
    <div className='settings-subpage page-appearance'>
      <Helmet>
        <title>Look & Feel anpassen - Infinity</title>
      </Helmet>
      <Flex>
        <Card style={{ flexGrow: 1 }} className='apperance-options'>
          <Heading type='h3'>Dashboard-Hintergrund</Heading>
          <Flex>
            <RadioGroup
              style={{ flexGrow: 1 }}
              options={[
                { label: 'Standard', value: 'bliss' },
                { label: 'Aurora', value: 'aurora' },
                { label: 'Wolken', value: 'cloud' },
                { label: 'Canyon', value: 'canyon' },
                { label: 'Lake', value: 'lake' },
                { label: 'Abstrakt', value: 'blue' },
                { label: 'Bäume am Ufer', value: 'trees' },
                { label: 'Dunkel', value: 'dark' },
                { label: 'Wald', value: 'forest' },
                { label: 'Architektur', value: 'architecture' },
              ]}
              value={authenticationStore.dashboardBackground}
              onChange={(value) => {
                authenticationStore.dashboardBackground = value
              }}
            />
            <div className='appearance-dashboard-example'>
              <div className='appearance-dashboard-preview'>
                <div
                  className={`background ${authenticationStore.dashboardBackground}`}
                />
                <div className='screen' />
              </div>
            </div>
          </Flex>
        </Card>
      </Flex>
    </div>
  )
}

export default observer(SettingsAppearance)
