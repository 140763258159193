import {
  Button,
  Dialog,
  FeedbackBanner,
  Flex,
  LoadingIndicator,
  Spacer,
  Text,
} from '@nextbusiness/infinity-ui'
import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect, useHistory } from 'react-router-dom'
import Authentication from '../../networking/Authentication'
import { useRootStore } from '../../stores/RootStoreContext'
import '../../styles/AuthPages.scss'
import { useQuery } from '../../utility/hooks'
import { KeyValueStore } from '../../utility/types'
import AuthPage from './AuthPage'
import RegistrationStep from './registration/RegistrationStep'

const ErrorMessages: KeyValueStore = {
  Unauthorised: 'Der verwendete Link ist ungültig oder bereits abgelaufen.',
}

const VerifyEmailPage = () => {
  const { authenticationStore } = useRootStore()

  const query = useQuery()
  const history = useHistory()

  const userId = query.get('userId')
  const secretCode = query.get('secretCode')

  const [error, setError] = useState<string | undefined>()
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false)

  useEffect(() => {
    const verify = async () => {
      if (!userId || !secretCode) return
      try {
        const token = await Authentication.verifyEmailUsingToken(
          secretCode,
          userId
        )
        if (token) {
          authenticationStore.setCurrentToken(token)
          await authenticationStore.loadUserData()
          authenticationStore.onLogin()
        }
        setIsSuccessful(true)
      } catch (error: any) {
        setError(
          error.message ||
            'Ein Fehler beim Verifizieren der E-Mail-Adresse ist aufgetreten.'
        )
      }
    }
    verify()
  }, [userId, secretCode])

  if (!userId || !secretCode) return <Redirect to='/login' />

  return (
    <AuthPage>
      <Helmet>
        <title>E-Mail-Bestätigung - Infinity</title>
      </Helmet>
      {isSuccessful ? (
        <RegistrationStep
          icon={<Icon icon='envelope' size={IconSize.Regular} />}
          title='Erfolgreich bestätigt'
        >
          <FeedbackBanner variant='success'>
            Deine E-Mail-Adresse wurde erfolgreich bestätigt.
          </FeedbackBanner>
          <Spacer size='small' />
          {authenticationStore.isAuthenticated ? (
            <Button onClick={() => history.push('/')} variant='primary'>
              Weiter zum Dashboard
            </Button>
          ) : (
            <Button onClick={() => history.push('/login')} variant='primary'>
              Weiter zum Login
            </Button>
          )}
        </RegistrationStep>
      ) : error ? (
        <Dialog
          isOpen
          title='Fehler beim Verifizieren der E-Mail'
          actions={[
            {
              children: 'Neuen Link anfordern',
              onClick: () => history.push('/verify-email'),
              variant: 'primary',
            },
          ]}
        >
          <Text type='paragraph'>{ErrorMessages[error] || error}</Text>
        </Dialog>
      ) : (
        <Flex horizontalAlignment='center'>
          <LoadingIndicator loadingText='Deine E-Mail-Adresse wird verifiziert.' />
        </Flex>
      )}
    </AuthPage>
  )
}

export default observer(VerifyEmailPage)
