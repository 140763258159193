import { ThemeProvider } from '@nextbusiness/infinity-ui'
import { CSSProperties } from 'react'
import MarketplaceApp from '../../marketplace/MarketplaceApp'
import './AppSignupFlowPage.scss'
import AppSignupFlowSidebar from './AppSignupFlowSidebar'

interface AppSignupFlowPageProps {
  app: MarketplaceApp
  children?: React.ReactNode
}

const AppSignupFlowPage = (props: AppSignupFlowPageProps) => {
  return (
    <ThemeProvider
      primaryColor={props.app.signupFlowAppearance?.tint ?? 'default'}
    >
      <div
        className='app-signup-flow'
        style={
          { '--key-colour': props.app.keyColor ?? '#026594' } as CSSProperties
        }
      >
        <AppSignupFlowSidebar app={props.app} />
        <div className='signup-content'>{props.children}</div>
      </div>
    </ThemeProvider>
  )
}

export default AppSignupFlowPage
