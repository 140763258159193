import { create } from 'mobx-persist'
import AuthenticationStore from './AuthenticationStore'
import BillingStore from './BillingStore'
import OrganisationStore from './OrganisationStore'

const hydrate = create({
  storage: localStorage,
  jsonify: true,
})

export class RootStore {
  authenticationStore: AuthenticationStore
  organisationStore: OrganisationStore
  billingStore: BillingStore

  constructor() {
    this.authenticationStore = new AuthenticationStore(this)
    this.organisationStore = new OrganisationStore(this)
    this.billingStore = new BillingStore(this)

    this.hydrateStores()
  }

  async hydrateStores() {
    await hydrate('authenticationStore', this.authenticationStore)
    await hydrate('organisationStore', this.organisationStore)
    await hydrate('billingStore', this.billingStore)

    this.authenticationStore.onHydrate()
    this.organisationStore.onHydrate()
    this.billingStore.onHydrate()
  }
}

export default RootStore
