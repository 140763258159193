import { ApplicationFrame } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { Helmet } from 'react-helmet'
import { Route, useHistory, useLocation } from 'react-router'
import { AnimatedSwitch } from 'react-router-transition'
import RouteTransitions from '../../routes/RouteTransitions'
import { useRootStore } from '../../stores/RootStoreContext'
import MarketplaceAppPage from './MarketplaceAppPage'
import MarketplaceDiscoverPage from './MarketplaceDiscoverPage'
import './MarketplacePage.scss'

const MarketplacePage = () => {
  const { authenticationStore } = useRootStore()

  const history = useHistory()
  const location = useLocation()

  return (
    <div className='marketplace-page'>
      <Helmet>
        <title>Apps entdecken - Infinity</title>
      </Helmet>
      <ApplicationFrame
        appTitle=''
        history={history}
        location={location}
        identifier='marketplace'
        userMenu={authenticationStore.userMenu}
        primaryNavigation={[
          {
            path: '/dashboard',
            description: 'Start',
          },
          {
            path: '/marketplace',
            description: 'Marketplace',
          },
          {
            path: '/settings',
            description: 'Einstellungen',
          },
        ]}
      >
        <AnimatedSwitch
          {...RouteTransitions.Subpage}
          className='marketplace-switch'
        >
          <Route path='/marketplace' exact>
            <MarketplaceDiscoverPage />
          </Route>
          <Route path='/marketplace/apps/:applicationIdentifier' exact>
            <MarketplaceAppPage />
          </Route>
        </AnimatedSwitch>
      </ApplicationFrame>
    </div>
  )
}

export default observer(MarketplacePage)
