import { Flex } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import AppTile from '../../components/apptile/AppTile'
import { showProductionApps } from '../../model/Apps'
import { useRootStore } from '../../stores/RootStoreContext'
import MarketplaceApps from '../marketplace/MarketplaceApps'
import './DashboardAppLibrary.scss'
import DashboardAppLibraryLoading from './DashboardAppLibraryLoading'

const DashboardAppLibrary = () => {
  const history = useHistory()
  const { organisationStore } = useRootStore()

  const [showAllApps, setShowAllApps] = useState<boolean>(false)

  const keyDownEventHandler = (e: KeyboardEvent) => {
    if (e.altKey) setShowAllApps(true)
  }
  const keyUpEventHandler = (e: KeyboardEvent) => {
    setShowAllApps(false)
  }

  const apps = organisationStore.currentOrganisation?.apps ?? []

  useEffect(() => {
    // Disable development modifiers on production
    if (showProductionApps) return

    document.addEventListener('keydown', keyDownEventHandler)
    document.addEventListener('keyup', keyUpEventHandler)

    return () => {
      document.removeEventListener('keydown', keyDownEventHandler)
      document.removeEventListener('keyup', keyUpEventHandler)
    }
  })

  return (
    <div>
      <div className='app-library'>
        <Flex wrap>
          {!organisationStore.hasLoadedApps && !apps.length && (
            <DashboardAppLibraryLoading />
          )}
          {apps.map((app) => {
            const appDetails = MarketplaceApps.find(
              (marketplaceApp) => marketplaceApp.identifier === app.identifier
            )
            if (!appDetails && !showAllApps) return null
            return (
              <AppTile
                key={app.identifier}
                onClick={() =>
                  history.push(
                    `/app/${app.identifier}` +
                      (showAllApps ? '?modifier=development-mode' : '')
                  )
                }
                identifier={app.identifier}
                displayName={
                  appDetails?.launcherTitle ??
                  appDetails?.title ??
                  app.identifier
                }
                themeColor={appDetails?.keyColor ?? '#3F5469'}
                appIcon={appDetails?.icon}
                customAppearance={appDetails?.launcherAppearance}
                className='app'
                animated
              />
            )
          })}
        </Flex>
      </div>
    </div>
  )
}

export default observer(DashboardAppLibrary)
