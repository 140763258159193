import { KeyValueStore } from '../utility/types'

interface App {
  id: string
  identifier: string
  isActive?: boolean
  permissions: KeyValueStore
}

export type PermissionStatus = 'deny' | 'allow' | 'ask'

export enum Permission {
  Launch = 'infinity:app:launch',
  ReadContacts = 'infinity:contacts:read',
  WriteContacts = 'infinity:contacts:write',
  ReadTasks = 'infinity:tasks:read',
  WriteTasks = 'infinity:tasks:write',
  ReadProjects = 'infinity:projects:read',
  WriteProjects = 'infinity:projects:write',
}

export const isPermission = (permission: string): permission is Permission =>
  Object.values(Permission).includes(permission as Permission)

export default App
