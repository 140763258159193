import { Contact } from '../../../../model/Contact'

export type MappableContactKey = keyof Omit<Contact, 'keywords'>

abstract class ContactCSVMapper {
  public mapToContact(headers: string[], row: string[]): Partial<Contact> {
    const contactData = this.combineHeadersAndRows(headers, row)
    return this.mapDataToContact(contactData)
  }

  protected mapDataToContact(
    contactData: Record<string, string>
  ): Partial<Contact> {
    const contact: Partial<Contact> = {}
    Object.entries(contactData).forEach(([key, value]) => {
      const mapped = this.mapKeyToContactFields(key, value, contactData)
      Object.assign(contact, mapped)
    })
    return contact
  }

  protected mapKeyToContactFields(
    key: string,
    value: string,
    _contactData: Record<string, string>
  ): Partial<Contact> {
    const mappedKey = this.mapHeader(key)
    if (mappedKey)
      return {
        [mappedKey]: value,
      }
    return {}
  }

  private combineHeadersAndRows(
    headers: string[],
    row: string[]
  ): Record<string, string> {
    const combined: Record<string, string> = {}
    headers.forEach((header, index) => {
      combined[header] = row[index]
    })
    return combined
  }

  public mapHeaders(headers: string[]): (MappableContactKey | null)[] {
    return headers.map((header) => this.mapHeader(header))
  }

  protected abstract mapHeader(header: string): MappableContactKey | null
}

export default ContactCSVMapper
