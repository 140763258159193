import { showProductionApps } from '../../model/Apps'
import DevelopmentApps from './apps/DevelopmentApps'
import InfinityFinance from './apps/InfinityFinance'
import MarketplaceApp from './MarketplaceApp'

const ProductionMarketplaceApps: MarketplaceApp[] = [InfinityFinance]

const MarketplaceApps: MarketplaceApp[] = showProductionApps
  ? ProductionMarketplaceApps
  : [...ProductionMarketplaceApps, ...DevelopmentApps]

export default MarketplaceApps
