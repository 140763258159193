import {
  Dialog,
  FeedbackBanner,
  InputField,
  Text,
} from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import Authentication from '../../../../networking/Authentication'
import { ErrorType } from '../../../../networking/Errors'
import { useRootStore } from '../../../../stores/RootStoreContext'

interface VerifyPasswordModalProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  isPasswordVerified: boolean
  setIsPasswordVerified: (isVerified: boolean) => void
}

const VerifyPasswordModal = (props: VerifyPasswordModalProps) => {
  const { authenticationStore } = useRootStore()

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  )

  const [password, setPassword] = useState<string>('')
  const [twoFactorCode, setTwoFactorCode] = useState<string>('')

  const [isVerifying, setIsVerifying] = useState<boolean>(false)
  const [isTwoFactorRequired, setIsTwoFactorRequired] = useState<boolean>(false)

  const verifyPassword = async (password: string) => {
    if (!authenticationStore.currentUser) return
    try {
      setIsVerifying(true)
      await Authentication.loginUsingCredentials(
        authenticationStore.currentUser?.email,
        password,
        twoFactorCode
      )
      props.setIsPasswordVerified(true)
      props.setIsOpen(false)
      setErrorMessage(undefined)
    } catch (error: any) {
      setErrorMessage(undefined)
      switch (error.type) {
        case ErrorType.MissingRequiredFields:
          return setErrorMessage('Bitte gebe dein Kennwort ein.')
        case ErrorType.InvalidCredentials:
          return setErrorMessage('Das eingegebene Kennwort ist falsch.')
        case ErrorType.TwoFactorRequired:
          setIsTwoFactorRequired(true)
          return
        case ErrorType.TwoFactorIncorrect:
          return setErrorMessage('Das eingegebene Zwei-Faktor-Code ist falsch.')
        case ErrorType.TwoFactorExpired:
          return setErrorMessage(
            'Das eingegebene Zwei-Faktor-Code ist abgelaufen.'
          )
        default:
          setErrorMessage(
            error.message ??
              'Ein unbekannter Fehler ist aufgetreten. Bitte versuche es erneut.'
          )
      }
    } finally {
      setIsVerifying(false)
    }
  }

  useEffect(() => {
    if (props.isOpen) {
      setErrorMessage(undefined)
      setPassword('')
      setTwoFactorCode('')
      setIsTwoFactorRequired(false)
    }
  }, [props.isOpen])

  return (
    <Dialog
      title='Kennwort verifizieren'
      appearance='system'
      initialFocusIndex={1}
      isOpen={props.isOpen}
      actions={[
        {
          children: 'Abbrechen',
          variant: 'tertiary',
          onClick: () => props.setIsOpen(false),
        },
        {
          children: 'Bestätigen',
          variant: 'primary',
          isLoading: isVerifying,
          onClick: () => verifyPassword(password),
        },
      ]}
    >
      <InputField
        value={password}
        onChange={setPassword}
        label='Kennwort'
        type='password'
      />
      {isTwoFactorRequired && (
        <div>
          <Text>Bitte gib deinen Zwei-Faktor-Code ein.</Text>
          <InputField
            value={twoFactorCode}
            onChange={setTwoFactorCode}
            label='Zwei-Faktor-Code'
            type='text'
            fullWidth
          />
        </div>
      )}
      {errorMessage && (
        <FeedbackBanner variant='error'>{errorMessage}</FeedbackBanner>
      )}
    </Dialog>
  )
}

export default observer(VerifyPasswordModal)
