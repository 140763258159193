import {
  Dialog,
  InputField,
  Text,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import { useEffect, useState } from 'react'
import Authentication from '../../networking/Authentication'

interface ResetPasswordModalProps {
  isOpen: boolean
  closeModal: (isOpen: boolean) => void
}

const ResetPasswordModal = (props: ResetPasswordModalProps) => {
  const [emailAddress, setEmailAddress] = useState<string>('')
  const [showValidationError, setShowValidationError] = useState<boolean>()
  const [isSendingLink, setIsSendingLink] = useState<boolean>(false)

  const notificationCenter = useNotificationCenter()
  const hasEnteredValidEmail =
    /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/.test(emailAddress)

  const resetPassword = async () => {
    try {
      setShowValidationError(!hasEnteredValidEmail)
      if (!hasEnteredValidEmail) return

      setIsSendingLink(true)
      await Authentication.sendResetPasswordLink(emailAddress)

      notificationCenter.addNotification({
        children: 'Link zum Kennwort zurücksetzen erfolgreich versendet.',
        variant: 'success',
      })
      props.closeModal(false)
      setShowValidationError(false)
    } catch (error: any) {
      notificationCenter.addNotification({
        children:
          'Link zum Kennwort zurücksetzen konnte nicht versendet werden.',
        variant: 'error',
      })
    } finally {
      setIsSendingLink(false)
    }
  }

  useEffect(() => {
    const resetModal = () => {
      setShowValidationError(false)
      setIsSendingLink(false)
      setEmailAddress('')
    }
    if (props.isOpen) {
      resetModal()
    }
  }, [props.isOpen])

  useEffect(() => {
    const keyDownEventHandler = (event: KeyboardEvent) => {
      if (!props.isOpen) return
      if (event.key === 'Enter') resetPassword()
    }
    document.addEventListener('keyup', keyDownEventHandler)
    return () => document.removeEventListener('keyup', keyDownEventHandler)
  }, [resetPassword, props.isOpen])

  return (
    <Dialog
      isOpen={props.isOpen}
      title='Kennwort vergessen'
      titleProps={{ divider: true }}
      actions={[
        {
          children: 'Abbrechen',
          onClick: () => props.closeModal(false),
        },
        {
          children: 'Kennwort zurücksetzen',
          variant: 'primary',
          isLoading: isSendingLink,
          onClick: () => resetPassword(),
        },
      ]}
    >
      <Text>Gib deine E-Mail-Adresse an, um dein Kennwort zurückzusetzen.</Text>
      <InputField
        fullWidth
        value={emailAddress}
        placeholder='E-Mail-Adresse'
        onChange={(enteredEmailAddress) => setEmailAddress(enteredEmailAddress)}
        hasError={showValidationError}
        helperText={
          showValidationError
            ? 'Du musst eine gültige E-Mail-Adresse eingeben.'
            : ''
        }
      />
    </Dialog>
  )
}

export default ResetPasswordModal
