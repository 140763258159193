import { useMemo } from 'react'
import { Contact } from '../../../../model/Contact'
import { ImportResult } from './ContactCSVImportResults'
import ContactCSVImportTableRowStatus from './ContactCSVImportTableRowStatus'

interface ContactCSVImportTableRowProps {
  contact: Partial<Contact>
  result?: ImportResult
}

const ContactCSVImportTableRow = ({
  contact,
  result,
}: ContactCSVImportTableRowProps) => {
  const displayName = useMemo(() => {
    if (contact.firstname || contact.lastname) {
      const names: string[] = []
      if (contact.firstname) names.push(contact.firstname)
      if (contact.lastname) names.push(contact.lastname)
      return names
        .join(' ')
        .concat(contact.companyName ? `, ${contact.companyName}` : '')
    } else {
      return `${contact.lastname ?? ''} ${contact.companyName}`
    }
  }, [contact])

  return (
    <tr>
      <td className='name'>{displayName.trim()}</td>
      <td className='address'>
        <p>{contact.address ?? '–'}</p>
      </td>
      <td className='place'>{contact.postCode ?? '–'}</td>
      <td className='status'>
        <ContactCSVImportTableRowStatus result={result} />
      </td>
    </tr>
  )
}

export default ContactCSVImportTableRow
