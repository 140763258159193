import { ApplicationFrame, Flex } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { Helmet } from 'react-helmet'
import { useHistory, useLocation } from 'react-router'
import Container from '../../components/layout/Container'
import { useRootStore } from '../../stores/RootStoreContext'
import DashboardAppLibrary from './DashboardAppLibrary'
import './DashboardPage.scss'
import DashboardTransition from './DashboardTransition'
import DashboardWidgets from './DashboardWidgets'

export type DashboardBackground =
  | 'bliss'
  | 'aurora'
  | 'cloud'
  | 'canyon'
  | 'lake'
  | 'blue'
  | 'trees'
  | 'dark'
  | 'forest'
  | 'architecture'

const DashboardPage = () => {
  const { authenticationStore } = useRootStore()
  const history = useHistory()
  const location = useLocation()

  if (!authenticationStore.currentUser) return null

  if (authenticationStore.currentUser.organisations.length === 0) {
    history.push('/setup-organisation')
  } else if (!authenticationStore.hasLoggedInBefore) {
    authenticationStore.hasLoggedInBefore = true
  }

  return (
    <ApplicationFrame
      appTitle=''
      identifier='dashboard'
      history={history}
      location={location}
      primaryNavigation={[
        { path: '/dashboard', description: 'Start' },
        { path: '/marketplace', description: 'Marketplace' },
        { path: '/settings', description: 'Einstellungen' },
      ]}
      userMenu={authenticationStore.userMenu}
    >
      <Helmet>
        <title>Start - Infinity</title>
      </Helmet>
      <div className='dashboard'>
        <div
          className={`dashboard-overlay ${authenticationStore.dashboardBackground}`}
        />
        <div className='dashboard-inner'>
          <Container>
            <DashboardTransition delay={0.2}>
              <DashboardAppLibrary />
              <Flex gap='small' horizontalAlignment='space-between'>
                <DashboardTransition delay={0.4}>
                  <DashboardWidgets />
                </DashboardTransition>
              </Flex>
            </DashboardTransition>
          </Container>
        </div>
      </div>
    </ApplicationFrame>
  )
}

export default observer(DashboardPage)
