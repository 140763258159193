import { Dialog, NotificationCenter } from '@nextbusiness/infinity-ui'
import { TypographyBase } from '@nextbusiness/infinity-ui-foundations'
import '@nextbusiness/infinity-ui/dist/index.css'
import * as Sentry from '@sentry/react'
import { configure } from 'mobx'
import React from 'react'
import ReactDOM from 'react-dom'
import 'react-loading-skeleton/dist/skeleton.css'
import App from './App'
import { BASE_URL } from './networking/networkingDefaults'
import './styles/index.scss'

configure({
  enforceActions: 'never',
})

if (import.meta.env.VITE_ENV === 'production')
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_KEY,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV5BrowserTracingIntegration({
        // eslint-disable-next-line no-restricted-globals
        history,
      }),
      Sentry.replayIntegration({
        maskAllText: true,
        maskAllInputs: true,
        blockAllMedia: true,
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
    tracePropagationTargets: ['localhost', BASE_URL],

    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 1.0,
  })

ReactDOM.render(
  <React.StrictMode>
    <TypographyBase>
      <Sentry.ErrorBoundary
        fallback={() => (
          <Dialog
            isOpen
            onDismiss={() => {}}
            icon='error'
            title='Technisches Problem'
            actions={[
              {
                children: 'Neu laden',
                variant: 'primary',
                onClick: () => window.location.reload(),
              },
            ]}
          >
            Infinity muss neu geladen werden, weil ein technisches Problem
            aufgetreten ist. Problemdetails werden automatisch an uns gesendet,
            damit wir den Fehler permanent beheben können.
          </Dialog>
        )}
      >
        <NotificationCenter>
          <App />
        </NotificationCenter>
      </Sentry.ErrorBoundary>
    </TypographyBase>
  </React.StrictMode>,
  document.getElementById('root')
)
