import { Dialog, FeedbackBanner } from '@nextbusiness/infinity-ui'
import { useEffect, useState } from 'react'
import ConfirmDeleteAccountModal from './ConfirmDeleteAccount'
import VerifyPasswordModal from './VerifyPassword'

interface DeleteAccountModalProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const DeleteAccountModal = (props: DeleteAccountModalProps) => {
  const [isVerifyPasswordModalOpen, setIsVerifyPasswordModalOpen] =
    useState<boolean>(false)

  const [isConfirmDeleteAccountModalOpen, setIsConfirmDeleteAccountModalOpen] =
    useState<boolean>(false)

  const [isPasswordVerified, setIsPasswordVerified] = useState<boolean>(false)

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  )

  useEffect(() => {
    if (props.isOpen) {
      setErrorMessage(undefined)
      setIsPasswordVerified(false)
    }
  }, [props.isOpen])

  return (
    <Dialog
      title='Konto löschen'
      initialFocusIndex={1}
      isOpen={props.isOpen}
      dismissable
      onDismiss={() => {
        props.setIsOpen(false)
      }}
      actions={[
        {
          children: 'Abbrechen',
          variant: 'tertiary',
          onClick: () => props.setIsOpen(false),
        },
        {
          iconLeft: isPasswordVerified ? undefined : 'privilege',
          children: isPasswordVerified
            ? 'Konto löschen'
            : 'Kennwort verifizieren',
          variant: isPasswordVerified ? 'destructive' : 'primary',
          onClick: () => {
            isPasswordVerified
              ? setIsConfirmDeleteAccountModalOpen(true)
              : setIsVerifyPasswordModalOpen(true)
          },
        },
      ]}
    >
      {errorMessage && (
        <FeedbackBanner variant='error'>{errorMessage}</FeedbackBanner>
      )}
      Wenn du dein Konto löschst, werden alle deine Daten aus der Datenbank
      entfernt, und du wirst dein Konto nicht mehr wiederherstellen können.
      <VerifyPasswordModal
        isOpen={isVerifyPasswordModalOpen}
        setIsOpen={setIsVerifyPasswordModalOpen}
        isPasswordVerified={isPasswordVerified}
        setIsPasswordVerified={setIsPasswordVerified}
      />
      <ConfirmDeleteAccountModal
        isOpen={isConfirmDeleteAccountModalOpen}
        setIsOpen={setIsConfirmDeleteAccountModalOpen}
        setIsCanceled={props.setIsOpen}
      />
    </Dialog>
  )
}

export default DeleteAccountModal
