import { FeedbackBanner, Text } from '@nextbusiness/infinity-ui'
import { useEffect, useState } from 'react'
import FileUploadArea from '../../../../components/input/FileUploadArea'
import { Contact } from '../../../../model/Contact'
import BexioContactCSVMapper from './BexioContactCSVMapper'
import ContactCSVParser from './ContactCSVParser'

interface ContactCSVImportAreaProps {
  disabled?: boolean
  onCSVParsed: (contacts: Partial<Contact>[]) => void
}

const ContactCSVImportArea = (props: ContactCSVImportAreaProps) => {
  const [file, setFile] = useState<File>()
  const [parsingError, setParsingError] = useState<Error>()

  const processCSVIntoContacts = async (file: File) => {
    const parser = await ContactCSVParser.fromFile(file)
    const csv = parser.parse()
    const mapper = new BexioContactCSVMapper()

    const contacts = csv.rows.map((row) =>
      mapper.mapToContact(csv.headers, row)
    )
    const validContacts = contacts.filter(
      (contact) =>
        !!contact.companyName || !!contact.firstname || !!contact.lastname
    )
    props.onCSVParsed(validContacts)
  }

  const parseCSV = async (file: File) => {
    try {
      await processCSVIntoContacts(file)
    } catch (error) {
      setParsingError(error as Error)
    }
  }

  useEffect(() => {
    if (file) parseCSV(file)
  }, [file])

  return (
    <div>
      <FileUploadArea
        disabled={props.disabled}
        accept={{
          'text/csv': ['.csv'],
        }}
        onFileSelected={(file) => {
          if (!file || props.disabled) return
          setFile(file)
        }}
        placeholder='CSV-Datei hochladen'
        dragActivePlaceholder='CSV-Datei hier hinziehen'
      />
      {parsingError && (
        <FeedbackBanner
          variant='error'
          title='CSV-Datei konnte nicht gelesen werden.'
        >
          <p>
            Bitte stelle sicher, dass die Datei in einem gültigen Format ist
            oder wende dich an unser Team.
          </p>
          <Text variant='subtle'>
            Technische Details: {parsingError.message ?? '–'}
          </Text>
        </FeedbackBanner>
      )}
    </div>
  )
}

export default ContactCSVImportArea
