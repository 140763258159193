import { Card, Flex, LoadingIndicator } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useRootStore } from '../../../stores/RootStoreContext'
import PaymentDetailsCard from './PaymentDetailsCard'
import PaymentSetupCard from './PaymentSetupCard'
import PaymentSubscriptions from './PaymentSubscriptions'
import PaymentTransactions from './PaymentTransactions'
import './SettingsBilling.scss'

const SettingsBilling = () => {
  const { organisationStore, billingStore } = useRootStore()

  useEffect(() => {
    if (organisationStore.currentOrganisation?.stripeCustomerId)
      billingStore.loadBillingDetails()
  }, [organisationStore.currentOrganisation?.stripeCustomerId])

  const isLoading =
    organisationStore.currentOrganisation?.stripeCustomerId &&
    !billingStore.details
  const hasSetupPayments =
    billingStore.details &&
    billingStore.details.invoice_settings.default_payment_method &&
    organisationStore.currentOrganisation?.stripeCustomerId
  const hasSubscriptions = billingStore.subscriptions.length > 0

  return (
    <div className='settings-subpage page-billing'>
      <Helmet>
        <title>Abrechnung - Infinity</title>
      </Helmet>
      {isLoading ? (
        <Loading />
      ) : (
        <TwoColumnLayout>
          <Card style={{ flexGrow: 1 }}>
            {hasSetupPayments ? <PaymentDetailsCard /> : <PaymentSetupCard />}
          </Card>
          {hasSubscriptions && (
            <div style={{ flexGrow: 1 }}>
              <Card>
                <PaymentSubscriptions />
              </Card>
              {hasSetupPayments && (
                <Card>
                  <PaymentTransactions />
                </Card>
              )}
            </div>
          )}
        </TwoColumnLayout>
      )}
    </div>
  )
}

const Loading = () => (
  <Flex
    horizontalAlignment='center'
    fillContainer
    style={{ padding: '2rem 0' }}
  >
    <LoadingIndicator loadingText='Informationen werden geladen.' />
  </Flex>
)

const TwoColumnLayout = (props: { children: React.ReactNode }) => (
  <Flex
    direction='horizontal-reflow'
    horizontalAlignment='space-between'
    gap='small'
    verticalAlignment='flex-start'
  >
    {props.children}
  </Flex>
)

export default observer(SettingsBilling)
