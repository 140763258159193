import { KeyValueStore } from '../utility/types'

export const Industries: KeyValueStore = {
  retail: 'Retail',
  software: 'Software',
  consulting: 'Beratungsdienstleistungen',
  tech: 'Informatik/Tech',
  media: 'Medien/Multimedia',
  food: 'Gastronomie',
  design: 'Design',
  law: 'Rechtsanwälte und Juristen',
  financial: 'Finanzielle Dienstleistungen',
  accounting: 'Accounting, Steuern oder Auditing',
  banking: 'Banking',
  education: 'Bildung',
  transport: 'Transport und Fahrzeuge',
  logistics: 'Logistik',
  marketing: 'Marketingdienstleistungen',
  nonprofit: 'Wohlfahrtseinrichtung',
  travel: 'Reisen',
  property: 'Immobilien',
  hotels: 'Hotellerie und Beherbergung',
  health: 'Gesundheitswesen',
  personal: 'Persönliche Dienstleistungen',
  entertainment: 'Unterhaltung und Freizeit',
  building: 'Bau, Architektur, Gebäudetechnik',
  communication: 'Kommunikation',
  insurance: 'Versicherungsleistungen',
  research: 'Forschung und Wissenschaft',
  other: 'Andere',
}
