import { Button, Flex, Heading, Spacer, Text } from '@nextbusiness/infinity-ui'
import { Icon } from '@nextbusiness/infinity-ui-icons'
import { Helmet } from 'react-helmet'
import '../../../styles/AuthPages.scss'
import AuthPage from '../../authentication/AuthPage'

// TODO: Connect Intercom messenger here once integration is built into Base
const IS_INTERCOM_ENABLED = false

const GoodbyePage = () => (
  <AuthPage>
    <Helmet>
      <title>Auf Wiedersehen - Infinity</title>
    </Helmet>
    <div>
      <Icon icon='closed-sign' />
      <Heading type='h1'>Auf Wiedersehen.</Heading>
    </div>
    <Text type='paragraph'>
      Dein Konto wurde gekündigt. Vielen Dank, dass du Infinity ausprobiert
      hast.
    </Text>
    <Text type='paragraph'>
      Falls du es dir nochmals anders überlegst, oder wenn du Fragen oder
      Feedback für unser Team hast, würden wir uns freuen, von dir zu hören.
    </Text>
    <Spacer size='small' />
    {IS_INTERCOM_ENABLED && (
      <Flex>
        <Button iconLeft='help' onClick={() => {}}>
          Mit unserem Team chatten
        </Button>
      </Flex>
    )}
  </AuthPage>
)

export default GoodbyePage
