import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import Authentication from '../../networking/Authentication'
import { ErrorType } from '../../networking/Errors'
import { useRootStore } from '../../stores/RootStoreContext'
import { useQuery } from '../../utility/hooks'
import AuthPage from './AuthPage'
import StepCredentials from './registration/StepCredentials'
import StepName from './registration/StepName'

export const PASSWORD_REQUIREMENTS =
  'Das Kennwort muss aus mind. 10 Zeichen bestehen, einen Gross- sowie einen Kleinbuchstaben und eine Zahl enthalten.'

enum RegistrationStep {
  Name = 0,
  Credentials = 1,
}

export const decodeSource = (source: string | null) => {
  if (!source) return
  try {
    const decodedSource = atob(source)
    if (decodedSource)
      window.localStorage.setItem('signup-source', decodedSource)
  } catch (error) {
    // Ignore
  }
}

const RegistrationPage = () => {
  const { authenticationStore } = useRootStore()
  const history = useHistory()
  const query = useQuery()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [step, setStep] = useState<RegistrationStep>(0)

  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [readTOS, setReadTOS] = useState<boolean>(false)

  const source = query.get('from')
  const invite = query.get('invite')

  const [generalErrorMessage, setGeneralErrorMessage] = useState<
    string | undefined
  >(undefined)
  const [emailErrorMessage, setEmailErrorMessage] = useState<
    string | undefined
  >(undefined)
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<
    string | undefined
  >(undefined)

  const resetErrorMessages = () => {
    setIsLoading(false)
    setGeneralErrorMessage(undefined)
    setEmailErrorMessage(undefined)
    setPasswordErrorMessage(undefined)
  }

  const validateUserInput = () => {
    const isValidEmail = !!email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    const hasErrors = !isValidEmail || !password || !readTOS

    if (!isValidEmail)
      setEmailErrorMessage('Du musst eine gültige E-Mail-Adresse eingeben.')
    if (!password) setPasswordErrorMessage('Du musst ein Kennwort eingeben.')
    if (!readTOS)
      setGeneralErrorMessage('Du musst die Nutzungsbedingungen akzeptieren.')

    return !hasErrors
  }

  const registerUser = async (
    firstname: string,
    lastname: string,
    email: string,
    password: string
  ) => {
    resetErrorMessages()
    if (!validateUserInput()) return

    try {
      setIsLoading(true)
      const [user, pollingId] = await Authentication.registerNewAccount({
        firstname,
        lastname,
        email,
        password,
        invite,
        source: invite
          ? 'invite'
          : window.localStorage.getItem('signup-source'),
      })
      authenticationStore.currentUser = user
      authenticationStore.signupIntent = {
        userId: user.id,
        verificationId: pollingId,
      }
      authenticationStore.loginEmail = email
      authenticationStore.loginName = user.firstname + ' ' + user.lastname
      if (invite) {
        history.push('/login')
      } else {
        history.push('/verification-prompt')
      }
    } catch (error: any) {
      switch (error.type) {
        case ErrorType.Conflict:
          return setEmailErrorMessage(
            'Diese E-Mail-Adresse wurde bereits registriert.'
          )
        case ErrorType.InvalidCredentials:
          return setPasswordErrorMessage(PASSWORD_REQUIREMENTS)
        case ErrorType.MissingRequiredFields:
          return setGeneralErrorMessage('Bitte fülle alle Pflichtfelder aus.')
        default:
          setGeneralErrorMessage(
            error.message ??
              'Ein unbekannter Fehler ist aufgetreten. Bitte versuche es erneut.'
          )
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (source && !invite) {
      decodeSource(source)
      document.location.replace('/register')
    }
  }, [source, invite])

  return (
    <AuthPage className='registration-page'>
      <Helmet>
        <title>Erstelle ein Infinity-Konto - Infinity</title>
      </Helmet>
      {step === 0 && (
        <StepName
          firstName={firstName}
          lastName={lastName}
          setFirstName={setFirstName}
          setLastName={setLastName}
          onContinue={() => setStep(1)}
        />
      )}
      {step === 1 && (
        <StepCredentials
          email={email}
          password={password}
          setEmail={setEmail}
          setPassword={setPassword}
          emailErrorMessage={emailErrorMessage}
          passwordErrorMessage={passwordErrorMessage}
          firstName={firstName}
          readTOS={readTOS}
          setReadTOS={setReadTOS}
          generalErrorMessage={generalErrorMessage}
          onSubmit={() => registerUser(firstName, lastName, email, password)}
          isLoading={isLoading}
        />
      )}
    </AuthPage>
  )
}
export default observer(RegistrationPage)
