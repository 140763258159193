import { LoadingIndicator, Text } from '@nextbusiness/infinity-ui'
import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import classNames from 'classnames'
import { useCallback } from 'react'
import { Accept, useDropzone } from 'react-dropzone'
import './FileUploadArea.scss'

interface FileUploadAreaProps {
  disabled?: boolean
  accept: Accept
  onFileSelected: (file: File) => void
  isUploading?: boolean
  placeholder?: string
  dragActivePlaceholder?: string
}

const FileUploadArea = (props: FileUploadAreaProps) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles[0]) props.onFileSelected(acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: props.accept,
    multiple: false,
    disabled: props.disabled,
  })

  return (
    <div
      className={classNames('file-upload-area', {
        dropzone: isDragActive,
        disabled: props.disabled,
      })}
      {...getRootProps()}
    >
      {props.isUploading ? (
        <LoadingIndicator loadingText='Wird hochgeladen' />
      ) : (
        <>
          {!isDragActive && (
            <Icon icon='list-is-empty' size={IconSize.Medium} />
          )}
          <Text className='text'>
            {isDragActive
              ? props.dragActivePlaceholder ?? 'Dokument hier hinziehen'
              : props.placeholder ?? 'Datei hochladen'}
          </Text>
        </>
      )}
      <input {...getInputProps()} disabled={props.disabled} />
    </div>
  )
}

export default FileUploadArea
