import { Heading, InputField, Spacer, Text } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'

interface LoginDefaultFieldsProps {
  email: string
  password: string
  setEmail: (email: string) => void
  setPassword: (password: string) => void
  onEnterPressed: () => void
}

const LoginDefaultFields = (props: LoginDefaultFieldsProps) => (
  <>
    <Heading type='h1' bareTop>
      Melde dich an.
    </Heading>
    <Text type='paragraph'>Melde dich mit deinem Infinity-Login an.</Text>
    <Spacer size='tiny' />
    <InputField
      className='input-fields'
      placeholder='E-Mail-Adresse'
      type='email'
      value={props.email}
      onChange={props.setEmail}
      fullWidth
      icon='email'
    />
    <InputField
      className='input-fields'
      placeholder='Kennwort'
      type='password'
      value={props.password}
      onChange={props.setPassword}
      icon='access'
      fullWidth
      onKeyDown={(e) => {
        if (e.key === 'Enter') props.onEnterPressed()
      }}
    />
  </>
)

export default observer(LoginDefaultFields)
