import { Heading } from '@nextbusiness/infinity-ui'
import './SettingsGroup.scss'

interface SettingsGroupProps {
  title?: string
  children?: React.ReactNode
}

const SettingsGroup = (props: SettingsGroupProps) => (
  <div className='settings-group'>
    {props.title && (
      <Heading className='settings-group-title' type='h3'>
        {props.title}
      </Heading>
    )}
    {props.children}
  </div>
)

export default SettingsGroup
