import { Flex, Heading } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import AppTile from '../../../components/apptile/AppTile'
import { useRootStore } from '../../../stores/RootStoreContext'
import './SettingsHome.scss'

const SettingsHome = () => {
  const history = useHistory()
  const { organisationStore } = useRootStore()

  return (
    <>
      <Helmet>
        <title>Einstellungen - Infinity</title>
      </Helmet>
      <Flex gap='large'>
        <div>
          <Heading type='h2' divider className='settings-home-title'>
            Mein Account
          </Heading>
          <AppTile
            displayName='Kontodetails'
            identifier='account'
            icon='identity'
            onClick={() => history.push('/settings/account')}
          />
          <AppTile
            displayName='Sicherheit'
            identifier='security'
            icon='lock'
            onClick={() => history.push('/settings/security')}
          />
          <AppTile
            displayName='Look & Feel'
            identifier='appearance'
            icon='appearance'
            onClick={() => history.push('/settings/appearance')}
          />
        </div>
        <div>
          <Heading type='h2' divider className='settings-home-title'>
            {organisationStore.currentOrganisation?.name} verwalten
          </Heading>
          <AppTile
            displayName='Firmendaten'
            identifier='organisation'
            icon='business'
            onClick={() => history.push('/settings/organisation')}
          />
          <AppTile
            displayName='Kontaktimport'
            identifier='contacts-import'
            icon='contacts'
            onClick={() => history.push('/settings/contacts/import')}
          />
          <AppTile
            displayName='Teammitglieder'
            identifier='team'
            icon='team'
            onClick={() => history.push('/settings/team')}
          />
          <AppTile
            displayName='Abrechnung'
            identifier='billing'
            icon='payment'
            onClick={() => history.push('/settings/billing')}
          />
        </div>
      </Flex>
    </>
  )
}

export default observer(SettingsHome)
