import { useHistory } from 'react-router'
import DashboardTeamMembers from '../DashboardTeamMembers'
import Widget from './Widget'

const WidgetPeople = () => {
  const history = useHistory()
  return (
    <Widget
      title='Team'
      identifier='team'
      onClick={() => history.push('/settings/team')}
      icon='team'
    >
      <DashboardTeamMembers />
    </Widget>
  )
}

export default WidgetPeople
