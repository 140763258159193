import { Option } from '@nextbusiness/infinity-ui'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import Organisation, { OrganisationAppSettings } from '../model/Organisation'
import OrganisationMember, { Level } from '../model/OrganisationMember'
import Organisations from '../networking/Organisations'
import RootStore from './RootStore'
import Store from './Store'

export default class OrganisationStore extends Store {
  @persist @observable currentIdentifier: string | null = null

  @persist('object') @observable currentOrganisation: Organisation | null = null
  @persist('list') @observable activeApps: OrganisationAppSettings[] | null = []
  @persist('list') @observable members: OrganisationMember[] | null = []

  @observable hasLoadedApps: boolean = false
  @observable hasLoadedMembers: boolean = false

  constructor(root: RootStore) {
    super(root)
    makeObservable(this)
  }

  @action
  selectOrganisation(
    organisationIdentifier: string,
    perceptionalDelay: boolean = false
  ) {
    this.reset()
    this.currentIdentifier = organisationIdentifier
    this.rootStore.billingStore.reset()
    if (perceptionalDelay)
      return window.setTimeout(
        () => this.selectOrganisation(organisationIdentifier),
        250
      )
    this.loadOrganisationInformation()
  }

  loadOrganisationInformation() {
    if (!this.currentIdentifier) return

    this.loadCurrentOrganisationProfile()
    this.loadActiveApps()
    this.loadOrganisationMembers()

    this.rootStore.billingStore.loadApps(this.currentIdentifier)
  }

  private async loadCurrentOrganisationProfile() {
    const organisation = await Organisations.organisationInformation(
      this.currentIdentifier!
    )
    runInAction(() => {
      if (organisation.id !== this.currentIdentifier) return
      this.currentOrganisation = organisation
    })
  }

  public async loadActiveApps() {
    const apps = await Organisations.activeAppsForOrganisation(
      this.currentIdentifier!
    )
    runInAction(() => {
      this.activeApps = apps
      this.hasLoadedApps = true
    })
  }

  private async loadOrganisationMembers() {
    const members = await Organisations.membersForOrganisation(
      this.currentIdentifier!
    )
    runInAction(() => {
      this.members = members
      this.hasLoadedMembers = true
    })
  }

  @action
  reset() {
    this.currentIdentifier = null
    this.currentOrganisation = null
    this.activeApps = []
    this.members = []
    this.hasLoadedApps = false
    this.hasLoadedMembers = false
  }

  onHydrate() {
    if (!this.currentIdentifier) return

    this.loadOrganisationInformation()
  }

  get currentUserLevel(): Level | null {
    const user = this.members?.find(
      (member) =>
        member.id === this.rootStore.authenticationStore.currentUser?.id
    )
    if (!user) return null

    return user.level
  }

  get membersAsOptions(): Option[] {
    return (
      this.members
        ?.filter((member) => member.invitationStatus === 'accepted')
        .map((member) => ({
          label: this.fullNameOfMember(member.id!),
          value: member.id!,
        })) ?? []
    )
  }

  fullNameOfMember(memberId: string): string {
    const member = this.members?.find(
      (teamMember) => teamMember.id === memberId
    )
    if (member) {
      return member.firstname + ' ' + member.lastname
    }
    return 'Unbekanntes Mitglied'
  }
}
