import { Tooltip } from '@nextbusiness/infinity-ui'
import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import { ImportResult } from './ContactCSVImportResults'

interface ContactCSVImportTableRowStatusProps {
  result?: ImportResult
}

const ContactCSVImportTableRowStatus = ({
  result,
}: ContactCSVImportTableRowStatusProps) => {
  if (result?.state === 'success') {
    return (
      <span className='success'>
        <Icon icon='check' size={IconSize.Small} />
        Importiert
      </span>
    )
  } else if (result?.state === 'error') {
    const errorMessage =
      result?.error?.message ??
      'Beim Importieren dieses Kontakts ist ein technischer Fehler aufgetreten.'
    return (
      <span className='error'>
        <Icon icon='error' size={IconSize.Small} />
        <Tooltip content={errorMessage}>Fehler</Tooltip>
      </span>
    )
  } else {
    return <span className='idle'>Ausstehend</span>
  }
}

export default ContactCSVImportTableRowStatus
