import { Text } from '@nextbusiness/infinity-ui'

interface AppLauncherHelpTextProps {
  children?: React.ReactNode
}

const AppLauncherHelpText = (props: AppLauncherHelpTextProps) => (
  <Text className='app-launcher-help-text'>
    {props.children ?? 'Benötigst du Hilfe?'}{' '}
    <a
      href='https://www.infinity.swiss/hilfe#start-chat'
      target='_blank'
      rel='noopener noreferrer'
    >
      Chatte mit uns
    </a>
  </Text>
)

export default AppLauncherHelpText
