import { Flex } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useRootStore } from '../../stores/RootStoreContext'
import AppSetupWidget from './widgets/AppSetupWidget'
import WidgetPeople from './widgets/WidgetPeople'

const DashboardWidgets = () => {
  const { organisationStore } = useRootStore()

  const hasNotInstalledApps =
    organisationStore.currentOrganisation &&
    organisationStore.currentOrganisation?.apps?.length === 0

  return (
    <div className='dashboard-widgets'>
      <Flex className='widgets-list'>
        {hasNotInstalledApps && <AppSetupWidget />}
        <WidgetPeople />
      </Flex>
    </div>
  )
}

export default observer(DashboardWidgets)
