import React from 'react'
import './Container.scss'

interface ContainerProps {
  children: React.ReactNode
}

const Container = (props: ContainerProps) => (
  <div className='container'>{props.children}</div>
)

export default Container
