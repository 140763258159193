import {
  Button,
  FeedbackBanner,
  Heading,
  InputField,
  Spacer,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Authentication from '../../networking/Authentication'
import { ErrorType } from '../../networking/Errors'
import AuthPage from './AuthPage'
import './ResetPasswordPage.scss'

interface ResetPasswordPageProps {}

const PASSWORD_REQUIREMENTS =
  'Das Kennwort muss aus mind. 10 Zeichen bestehen, einen Gross- sowie einen Kleinbuchstaben und eine Zahl enthalten.'
const PASSWORDS_NOT_EQUAL =
  'Die eingegebebenen Passwörter stimmen nicht überein.'

const ResetPasswordPage = (props: ResetPasswordPageProps) => {
  const { userId, secretId } = useParams<{
    userId: string
    secretId: string
  }>()

  const history = useHistory()
  const notificationCenter = useNotificationCenter()

  const [newPassword, setNewPassword] = useState<string>('')
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('')

  const [isSaving, setIsSaving] = useState<boolean>(false)

  const [passwordErrorMessage, setPasswordErrorMessage] = useState<
    string | undefined
  >(undefined)
  const [generalErrorMessage, setGeneralErrorMessage] = useState<
    string | undefined
  >(undefined)

  const resetErrorMessages = () => {
    setGeneralErrorMessage(undefined)
    setPasswordErrorMessage(undefined)
  }

  const savePassword = async () => {
    const hasEnteredEqualPasswords = newPassword === confirmNewPassword
    try {
      if (hasEnteredEqualPasswords) {
        setIsSaving(true)

        await Authentication.resetUserPassword(newPassword, userId, secretId)

        notificationCenter.addNotification({
          children: 'Kennwort erfolgreich gesetzt',
          variant: 'success',
        })
        resetErrorMessages()
        history.push('/login')
      } else {
        setPasswordErrorMessage(PASSWORDS_NOT_EQUAL)
      }
    } catch (error: any) {
      if (error.type === ErrorType.InvalidCredentials)
        return setPasswordErrorMessage(PASSWORD_REQUIREMENTS)
      else
        setGeneralErrorMessage(
          error.message ??
            'Ein unbekannter Fehler ist aufgetreten. Bitte versuche es erneut.'
        )
    } finally {
      setIsSaving(false)
    }
  }

  useEffect(() => {
    const keyDownEventHandler = (event: KeyboardEvent) => {
      if (event.key === 'Enter') savePassword()
    }
    document.addEventListener('keyup', keyDownEventHandler)
    return () => document.removeEventListener('keyup', keyDownEventHandler)
  }, [savePassword])

  return (
    <AuthPage className='set-new-password-page'>
      <Heading type='h1' bareTop divider>
        Neues Kennwort setzen
      </Heading>
      <Spacer size='tiny' />
      <InputField
        type='password'
        label='Neues Kennwort'
        value={newPassword}
        onChange={setNewPassword}
        hasError={
          passwordErrorMessage !== undefined ||
          generalErrorMessage !== undefined
        }
        fullWidth
      />
      <InputField
        type='password'
        label='Neues Kennwort bestätigen'
        value={confirmNewPassword}
        onChange={setConfirmNewPassword}
        hasError={
          passwordErrorMessage !== undefined ||
          generalErrorMessage !== undefined
        }
        helperText={passwordErrorMessage}
        fullWidth
      />
      {generalErrorMessage && (
        <FeedbackBanner variant='error'>{generalErrorMessage}</FeedbackBanner>
      )}
      <Spacer size='regular' />
      <Button
        className='save-password-action'
        variant='primary'
        onClick={() => savePassword()}
        isLoading={isSaving}
        disabled={!newPassword || !confirmNewPassword}
      >
        Kennwort speichern
      </Button>
    </AuthPage>
  )
}

export default ResetPasswordPage
