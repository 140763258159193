import { Button, Heading, Text } from '@nextbusiness/infinity-ui'
import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import classNames from 'classnames'
import { ReactComponent as SelectedPlanCheck } from '../../assets/plan-selected.svg'
import MarketplaceApp, { PaymentInterval } from './MarketplaceApp'
import MarketplaceAppTier from './MarketplaceAppTier'

interface MarketplaceAppTierOptionProps {
  paymentInterval: PaymentInterval
  app: MarketplaceApp
  tier: MarketplaceAppTier
  onTierSelected: () => void
  isSelected?: boolean
  offerCode: string | null
}

const MarketplaceAppTierOption = (props: MarketplaceAppTierOptionProps) => {
  const offer = props.tier.offers?.find(
    (offer) => offer.code === props.offerCode
  )

  const isBilledYearly = props.paymentInterval === 'yearly'
  const isAvailableYearly =
    props.tier.yearlyIdentifier &&
    props.tier.yearlyPricePerMonth &&
    (!offer || (offer.yearlyIdentifier && offer.yearlyPricePerMonth))

  const defaultPrice = isBilledYearly
    ? props.tier.yearlyPricePerMonth ?? props.tier.monthlyPrice
    : props.tier.monthlyPrice
  const discountedPrice = isBilledYearly
    ? offer?.yearlyPricePerMonth ?? offer?.monthlyPrice
    : offer?.monthlyPrice

  const qualifier =
    isAvailableYearly && isBilledYearly
      ? 'pro Monat, jährlich abgerechnet'
      : !isBilledYearly
      ? 'monatlich'
      : 'nicht jährlich verfügbar'

  return (
    <Button
      className={classNames('price-tier', {
        selected: props.isSelected,
        disabled: isBilledYearly && !isAvailableYearly,
      })}
      variant='quaternary'
      disabled={isBilledYearly && !isAvailableYearly}
      onClick={props.onTierSelected}
    >
      {props.isSelected && <SelectedPlanCheck className='selected-check' />}
      <img
        className='tier-icon'
        src={`/assets/apps/icons/${props.app.icon ?? 'app-icon-generic.png'}`}
        alt=''
      />
      <Heading type='h3' bare>
        {props.tier.title}
      </Heading>
      {props.tier.description && (
        <Text className='tier-description'>{props.tier.description}</Text>
      )}
      <div className='tier-features'>
        {props.tier.features?.map((feature, index) => (
          <div className='tier-feature' key={index}>
            <Icon icon='check' size={IconSize.Tiny} tint='default' />
            <span>{feature}</span>
          </div>
        ))}
      </div>
      {offer && discountedPrice ? (
        <div className='tier-price with-offer'>
          <div className='price'>
            <span
              className='previous-price'
              aria-label={`Vorher ${defaultPrice}, mit Angebot neu: `}
            >
              {defaultPrice} Fr.
            </span>
            <span className='offer-price'>{discountedPrice} Fr.</span>
          </div>
          <small className='qualifier'>{qualifier}</small>
        </div>
      ) : (
        <div className='tier-price'>
          <div
            className={classNames('price', {
              yearly: isBilledYearly && isAvailableYearly,
            })}
          >
            {isBilledYearly && isAvailableYearly && (
              <span
                className='previous-price'
                aria-label={`Wäre monatlich ${props.tier.monthlyPrice}, jährlich neu: `}
              >
                {props.tier.monthlyPrice} Fr.
              </span>
            )}
            <span className='offer-price'>{defaultPrice} Fr.</span>
          </div>
          <small className='qualifier'>{qualifier}</small>
        </div>
      )}
    </Button>
  )
}

export default MarketplaceAppTierOption
