import MarketplaceApp from '../../marketplace/MarketplaceApp'

interface AppSignupFlowSidebarProps {
  app: MarketplaceApp
}

const AppSignupFlowSidebar = (props: AppSignupFlowSidebarProps) => {
  const backdropImage = props.app.signupFlowAppearance?.backdropImage
  return (
    <div
      className='signup-sidebar'
      style={
        backdropImage
          ? {
              backgroundImage: `url('/assets/apps/signup-backdrops/${backdropImage}')`,
            }
          : undefined
      }
    >
      <img
        className='app-logo'
        src={`/assets/apps/icons/${props.app.icon ?? 'app-icon-generic.png'}`}
        alt=''
      />
      <h1>
        {props.app.signupFlowAppearance?.defaultTrialDays
          ? `${props.app.signupFlowAppearance.defaultTrialDays} Tage kostenlos testen`
          : props.app.title}
      </h1>
      <span>
        {props.app.signupFlowAppearance?.defaultTrialDays
          ? `Keine Kreditkarte erforderlich`
          : 'Kostenlos testen'}
      </span>
    </div>
  )
}

export default AppSignupFlowSidebar
