import { Button, Card, Dialog, Text } from '@nextbusiness/infinity-ui'
import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import MarketplaceAppInstallationArea from './MarketplaceAppInstallationArea'
import './MarketplaceAppPage.scss'
import MarketplaceApps from './MarketplaceApps'

const MarketplaceAppPage = () => {
  const history = useHistory()
  const { applicationIdentifier } = useParams<{
    applicationIdentifier: string
  }>()
  const app = MarketplaceApps.find(
    (app) => app.identifier === applicationIdentifier
  )

  useEffect(
    () =>
      document
        .querySelector('.marketplace-page-inner')
        ?.scrollTo({ top: 0, behavior: 'smooth' }),
    [applicationIdentifier]
  )

  if (!app)
    return (
      <Dialog
        title='App nicht gefunden'
        isOpen
        actions={[
          {
            variant: 'primary',
            onClick: () => history.push('/marketplace'),
            children: 'Zurück zum Marketplace',
          },
        ]}
      >
        Die von dir angeforderte App ist zurzeit nicht im Marketplace verfügbar.
      </Dialog>
    )

  return (
    <div className='marketplace-subpage marketplace-app-page'>
      <Card className='marketplace-sheet' elevation='highest'>
        <Button
          iconLeft='back'
          onClick={() => history.push('/marketplace')}
          variant='quaternary'
          className='back-button'
        >
          Zurück
        </Button>
        <div className='app-page-header'>
          <div className='app-icon'>
            <img src={'/assets/apps/icons/' + app.icon} alt='' />
          </div>
          <div className='app-depiction'>
            <Text className='app-title'>{app.title}</Text>
            <Text className='app-subtitle' variant='subtle'>
              {app.subtitle}
            </Text>
          </div>
          <MarketplaceAppInstallationArea app={app} />
        </div>
        <div className='app-page-short-description'>
          <Text type='paragraph'>{app.shortAppDescription}</Text>
        </div>
        {app.screenshotURLs && (
          <div className='app-previews'>
            {app.screenshotURLs.map((screenshot) => (
              <img
                src={'/assets/apps/app-previews/' + screenshot}
                alt=''
                key={screenshot}
                className='app-preview'
              />
            ))}
          </div>
        )}
      </Card>
    </div>
  )
}

export default MarketplaceAppPage
