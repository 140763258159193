import {
  Button,
  Dialog,
  FeedbackBanner,
  Flex,
  Heading,
  KeyValueStore,
  LoadingIndicator,
  Spacer,
  Text,
} from '@nextbusiness/infinity-ui'
import { Icon } from '@nextbusiness/infinity-ui-icons'
import { observer } from 'mobx-react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Subscription from '../../../model/Subscription'
import Billing from '../../../networking/Billing'
import { useRootStore } from '../../../stores/RootStoreContext'
import MarketplaceApp, {
  PaymentInterval,
} from '../../marketplace/MarketplaceApp'
import MarketplaceAppTier from '../../marketplace/MarketplaceAppTier'
import MarketplaceApps from '../../marketplace/MarketplaceApps'
import CancelSubscriptionModal, {
  CancellationReason,
} from './CancelSubscriptionModal'

const PaymentSubscriptions = () => {
  const history = useHistory()
  const { organisationStore, billingStore } = useRootStore()
  const [errorMessage, setErrorMessage] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const cancelSubscription = async (
    subscription: Subscription,
    reason: CancellationReason,
    explanation: string
  ) => {
    try {
      setIsLoading(true)
      await Billing.cancelSubscription(
        subscription,
        organisationStore.currentOrganisation!,
        reason,
        explanation
      )
      organisationStore.loadOrganisationInformation()
      setErrorMessage(undefined)
    } catch (error: any) {
      setErrorMessage(
        error.message ??
          'Ein unbekannter Fehler ist aufgetreten. Bitte später erneut versuchen.'
      )
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Heading type='h3' style={{ margin: 0 }}>
        Abonnements
      </Heading>
      <Spacer size='small' />
      <Flex direction='vertical' gap='small'>
        {billingStore.subscriptions.map((subscription) => (
          <SubscriptionRow
            key={subscription.identifier}
            subscription={subscription}
            applicationIdentifier={subscription.apps[0]}
            cancelSubscription={cancelSubscription}
          />
        ))}
      </Flex>
      <Spacer size='small' />
      {errorMessage && (
        <FeedbackBanner variant='error'>{errorMessage}</FeedbackBanner>
      )}
      <Button
        iconLeft='add'
        variant='secondary'
        onClick={() => history.push('/marketplace')}
      >
        Apps entdecken
      </Button>
      <Dialog isOpen={isLoading}>
        <LoadingIndicator loadingText='Einen Moment bitte.' />
      </Dialog>
    </>
  )
}

interface SubscriptionRowProps {
  subscription: Subscription
  applicationIdentifier: string
  cancelSubscription: (
    subscription: Subscription,
    reason: CancellationReason,
    explanation: string
  ) => void
}

const pricingTier = (
  priceId: string,
  app?: MarketplaceApp
): { tier: MarketplaceAppTier; paymentInterval: PaymentInterval } | null => {
  if (!app) return null
  if (app.pricing.kind !== 'tiered') return null

  const monthlyTier = app.pricing.tiers.find(
    (tier) =>
      tier.identifier === priceId ||
      tier.offers?.find((offer) => offer.identifier === priceId)
  )
  const yearlyTier = app.pricing.tiers.find(
    (tier) =>
      tier.yearlyIdentifier === priceId ||
      tier.offers?.find((offer) => offer.yearlyIdentifier === priceId)
  )

  if (yearlyTier) {
    return { tier: yearlyTier, paymentInterval: 'yearly' }
  } else if (monthlyTier) {
    return { tier: monthlyTier, paymentInterval: 'monthly' }
  } else {
    return null
  }
}

const pricingTierTitle = (
  priceId: string,
  app?: MarketplaceApp
): string | null => {
  if (!app) return null

  if (priceId.startsWith('ch.nextbusiness.infinity'))
    return `${app.title} (Launch-Package)`
  const pricing = pricingTier(priceId, app)

  return pricing?.tier?.title ?? null
}

const pricingTierDisplayPrice = (
  priceId: string,
  app?: MarketplaceApp
): string | null => {
  if (priceId.startsWith('ch.nextbusiness.infinity') && app?.identifier) {
    const legacyPrices: KeyValueStore = {
      'ch.nextbusiness.infinity.finance': '19 Fr./Monat',
    }
    return legacyPrices[app.identifier] ?? null
  }

  const pricing = pricingTier(priceId, app)
  const offer = pricing?.tier?.offers?.find(
    (offer) => offer.identifier === priceId
  )

  if (pricing?.paymentInterval === 'monthly') {
    const monthlyPrice = offer?.monthlyPrice ?? pricing?.tier.monthlyPrice
    return monthlyPrice ? `${monthlyPrice} Fr./Monat` : null
  } else if (pricing?.paymentInterval === 'yearly') {
    const yearlyPrice =
      offer?.yearlyPricePerMonth ?? pricing?.tier.yearlyPricePerMonth
    return yearlyPrice
      ? `${yearlyPrice} Fr./Monat (jährlich abgerechnet)`
      : null
  } else {
    return null
  }
}

const SubscriptionRow = ({
  subscription,
  applicationIdentifier,
  cancelSubscription,
}: SubscriptionRowProps) => {
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false)

  const app = MarketplaceApps.find(
    (app) => app.identifier === applicationIdentifier
  )
  const subscriptionPrice = subscription.price / 100 + ' Fr./Monat'
  const tierTitle = pricingTierTitle(subscription.identifier, app)
  const tierPrice =
    subscription.price === 0
      ? pricingTierDisplayPrice(subscription.identifier, app)
      : subscriptionPrice

  return (
    <Flex gap='small' verticalAlignment='center'>
      <div className='app-icon'>
        {app?.icon ? (
          <img src={'/assets/apps/icons/' + app?.icon} alt='' width={32} />
        ) : (
          <Icon icon='apps' size={32} />
        )}
      </div>
      <Text type='inline' style={{ flexGrow: 1 }}>
        {tierTitle ?? app?.title ?? applicationIdentifier}
      </Text>
      <Text type='inline' variant='subtle'>
        {subscription.status === 'canceled'
          ? 'Storniert'
          : subscription.type === 'trialing'
          ? 'Testversion'
          : tierPrice}
      </Text>
      {subscription.status !== 'canceled' && (
        <Button variant='secondary' onClick={() => setShowCancelModal(true)}>
          Stornieren
        </Button>
      )}
      <CancelSubscriptionModal
        isOpen={showCancelModal}
        onDismiss={() => setShowCancelModal(false)}
        app={app}
        performCancellation={(reason, explanation) =>
          cancelSubscription(subscription, reason, explanation)
        }
      />
    </Flex>
  )
}

export default observer(PaymentSubscriptions)
