import { Dialog, RadioGroup, Text, Textarea } from '@nextbusiness/infinity-ui'
import { useState } from 'react'
import MarketplaceApp from '../../marketplace/MarketplaceApp'
import './CancelSubscriptionModal.scss'

interface CancelSubscriptionModalProps {
  isOpen: boolean
  onDismiss: () => void
  performCancellation: (reason: CancellationReason, explanation: string) => void
  app: MarketplaceApp | undefined
}

export type CancellationReason =
  | 'missing-features'
  | 'too-expensive'
  | 'too-complicated'
  | 'too-many-bugs'
  | 'switch-to-another-tool'
  | 'other'

const labelForExplanationField = (reason: CancellationReason) => {
  switch (reason) {
    case 'missing-features':
      return 'Was hat dir am meisten gefehlt?'
    case 'too-many-bugs':
      return 'In welchem Bereich der App bist du am meisten auf Probleme gestossen?'
    case 'switch-to-another-tool':
      return 'Zu welchem Anbieter wechselst du?'
    default:
      return 'Zusätzliches Feedback (optional)'
  }
}

const CancelSubscriptionModal = (props: CancelSubscriptionModalProps) => {
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)

  const [reason, setReason] = useState<CancellationReason | null>(null)
  const [explanation, setExplanation] = useState<string>('')

  const handleCancellation = () => {
    setShouldValidate(true)
    if (!reason) return

    props.performCancellation(reason, explanation)
    props.onDismiss()
  }

  return (
    <Dialog
      className='cancel-subscription-modal'
      title={`Abonnement für ${props.app?.title ?? 'diese App'} stornieren`}
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      icon='error'
      dismissable
      actions={[
        {
          children: 'Abbrechen',
          onClick: props.onDismiss,
        },
        {
          children: 'Abonnement sofort kündigen',
          variant: 'destructive',
          onClick: handleCancellation,
        },
      ]}
      style={{ maxWidth: '64rem' }}
    >
      <Text>
        Wenn du dir sicher bist, dass du kündigen möchtest, wähle bitte deinen
        Grund für die Kündigung aus.
      </Text>
      {shouldValidate && !reason && (
        <Text variant='warning'>
          Bitte wähle einen Kündigungsgrund aus. Dieses Feedback ist für uns
          sehr wertvoll.
        </Text>
      )}
      <RadioGroup
        className='cancellation-reason'
        options={[
          { label: 'Fehlende Funktionen', value: 'missing-features' },
          { label: 'Zu teuer', value: 'too-expensive' },
          { label: 'Zu kompliziert für mich', value: 'too-complicated' },
          { label: 'Zu viele Softwareprobleme', value: 'too-many-bugs' },
          {
            label: 'Günstigeres Angebot von einem anderen Tool',
            value: 'switch-to-another-tool',
          },
          { label: 'Anderer Grund', value: 'other' },
        ]}
        value={reason}
        onChange={(value) => setReason(value)}
      />
      {reason && (
        <Textarea
          placeholder={labelForExplanationField(reason)}
          value={explanation}
          onChange={(value) => setExplanation(value)}
          fullWidth
        />
      )}
      <Text variant='error'>
        Du verlierst sofort Zugriff auf die App. Stelle sicher, dass du vor dem
        Stornieren alles sicherst, was du noch benötigst.
      </Text>
    </Dialog>
  )
}

export default CancelSubscriptionModal
