import {
  Button,
  FeedbackBanner,
  Heading,
  Text,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import { observer } from 'mobx-react'
import { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useHistory } from 'react-router-dom'
import Authentication from '../../networking/Authentication'
import { ErrorType } from '../../networking/Errors'
import { useRootStore } from '../../stores/RootStoreContext'
import '../../styles/AuthPages.scss'
import { useQuery } from '../../utility/hooks'
import AuthPage from './AuthPage'
import './VerificationPromptPage.scss'
import RegistrationStep from './registration/RegistrationStep'

const POLLING_TIMER_INTERVAL = 4000

const VerificationPromptPage = () => {
  const { authenticationStore } = useRootStore()
  const notificationCenter = useNotificationCenter()
  const history = useHistory()
  const query = useQuery()

  const source = query.get('from')

  const statusPollingTimer = useRef<number | null>(null)

  const [isCooldownActive, setIsCooldownActive] = useState<boolean>(false)
  const [cooldownTimer, setCooldownTimer] = useState(600)

  const remainingMinutes = Math.floor(cooldownTimer / 60)
  const remainingSeconds = cooldownTimer % 60

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  )

  useEffect(() => {
    const pollStatus = async () => {
      const signup = authenticationStore.signupIntent
      const user = authenticationStore.currentUser

      if (!signup || !user || !signup.verificationId) return

      try {
        const status = await Authentication.currentVerificationStatus(
          user,
          signup.verificationId
        )
        if (status === 'verified') {
          notificationCenter.addNotification({
            children: 'Dein Konto wurde erfolgreich verifiziert.',
            variant: 'success',
          })
          authenticationStore.signupIntent = null
          history.push('/login')
        }
      } catch (error) {
        console.warn('Polling verification status failed', error)
      }
    }
    if (authenticationStore.signupIntent)
      statusPollingTimer.current = window.setInterval(
        pollStatus,
        POLLING_TIMER_INTERVAL
      )

    return () => {
      if (statusPollingTimer.current)
        window.clearInterval(statusPollingTimer.current)
    }
  }, [authenticationStore.signupIntent])

  useEffect(() => {
    const reduceTimerByOne = setInterval(() => {
      if (cooldownTimer > 1 && isCooldownActive) {
        setCooldownTimer(cooldownTimer - 1)
      } else if (isCooldownActive) {
        setIsCooldownActive(false)
        setCooldownTimer(600)
      }
    }, 1000)
    return () => clearInterval(reduceTimerByOne)
  })

  const resendVerificationEmail = async () => {
    try {
      const user = authenticationStore.currentUser
      if (!user) throw new Error('User is not authenticated')

      await Authentication.resendVerficationEmail(user)
      authenticationStore.currentUser = user
      authenticationStore.signupIntent = null
      setIsCooldownActive(true)
    } catch (error: any) {
      switch (error.type) {
        case ErrorType.Unauthorised:
          return setErrorMessage('Benutzer konnte nicht gefunden werden.')
        case ErrorType.TooManyRequests:
          return setErrorMessage(
            'Es wurde bereits ein Link versendet. Ein neuer Link kann erst nach Ablauf des alten Links angefragt werden.'
          )
        default:
          setErrorMessage(
            'Lade die Seite erneut oder probiere es später nochmals.'
          )
      }
    }
  }

  return (
    <AuthPage className='verification-prompt'>
      <Helmet>
        <title>E-Mail-Adresse bestätigen - Infinity</title>
      </Helmet>
      <RegistrationStep>
        <Icon icon='mailing' size={IconSize.Regular} />
        <Heading type='h3'>
          Dein Konto wurde erstellt. Wir haben dir ein Bestätigungs-E-Mail
          zugeschickt.
        </Heading>
        <Text type='paragraph' className='instructions'>
          Aktiviere dein Konto, indem du auf den Bestätigungslink klickst, den
          wir dir soeben in einem E-Mail zugesendet haben.
        </Text>
        {isCooldownActive && (
          <>
            <FeedbackBanner variant='success' className='success-banner'>
              Eine neue E-Mail wurde erfolgreich an dich versendet.
            </FeedbackBanner>
            <Text type='inline' variant='subtle'>
              {`Du kannst in ${
                remainingMinutes < 10
                  ? `0${remainingMinutes}`
                  : remainingMinutes
              }:${
                remainingSeconds < 10
                  ? `0${remainingSeconds}`
                  : remainingSeconds
              } erneut einen Link anfragen.`}
            </Text>
          </>
        )}
        {errorMessage && (
          <FeedbackBanner
            variant='error'
            className='error-banner'
            title='Etwas ist schiefgelaufen'
          >
            {errorMessage}
          </FeedbackBanner>
        )}
        {!isCooldownActive && !errorMessage && (
          <>
            <Button
              onClick={() => resendVerificationEmail()}
              iconLeft='available-updates'
            >
              Erneut senden
            </Button>
          </>
        )}
        <div className='continue-to-login-hint'>
          <span>Auf einem anderen Gerät gemacht?</span>
          <Link
            to='/login'
            target={source === 'app-signup' ? '_blank' : undefined}
          >
            Weiter zum Login
          </Link>
        </div>
      </RegistrationStep>
    </AuthPage>
  )
}

export default observer(VerificationPromptPage)
