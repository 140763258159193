import { Card, FeedbackBanner, Flex } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import InlineEditList from '../../../components/input/InlineEditList'
import { Industries } from '../../../model/Industries'
import { ErrorType } from '../../../networking/Errors'
import Organisations from '../../../networking/Organisations'
import { useRootStore } from '../../../stores/RootStoreContext'
import './SettingsOrganisation.scss'

const INDUSTRY_OPTIONS = Object.keys(Industries).map((key) => ({
  label: Industries[key],
  value: key,
}))

const SettingsOrganisation = () => {
  const { organisationStore, authenticationStore } = useRootStore()

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  )

  const changeInformationOfOrganisation = async (changes: {
    name?: string
    industry?: string
  }) => {
    if (!organisationStore.currentOrganisation) return
    try {
      organisationStore.currentOrganisation =
        await Organisations.changeOrganisationInformation(
          organisationStore.currentIdentifier!,
          changes
        )
      organisationStore.loadOrganisationInformation()
      authenticationStore.loadUserData()
      setErrorMessage(undefined)
    } catch (error: any) {
      switch (error.type) {
        case ErrorType.Unauthorised:
          return setErrorMessage(
            'Nur Administrator:innen können Firmendaten ändern.'
          )
        default:
          setErrorMessage(
            error.message ??
              'Ein unbekannter Fehler ist aufgetreten. Bitte versuche es erneut.'
          )
      }
    }
  }

  return (
    <div className='settings-subpage page-organisation'>
      <Helmet>
        <title>Firmendaten bearbeiten - Infinity</title>
      </Helmet>
      <Flex>
        <Card style={{ flexGrow: 1 }} className='organisation-info'>
          {errorMessage && (
            <FeedbackBanner variant='error'>{errorMessage}</FeedbackBanner>
          )}
          <InlineEditList
            appearance='aligned'
            onCommit={changeInformationOfOrganisation}
            fields={[
              {
                type: 'input',
                key: 'name',
                title: 'Firmenname',
                initialValue: organisationStore.currentOrganisation!.name,
              },
              {
                type: 'select',
                key: 'industry',
                title: 'Industrie',
                initialValue: {
                  value: organisationStore.currentOrganisation?.industry,
                  label:
                    Industries[organisationStore.currentOrganisation!.industry],
                },
                selectWithOptions: INDUSTRY_OPTIONS,
              },
            ]}
          />
        </Card>
      </Flex>
    </div>
  )
}

export default observer(SettingsOrganisation)
