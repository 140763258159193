import { Button, Text } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import SettingsGroup from '../../../../components/settings/SettingsGroup'
import { useRootStore } from '../../../../stores/RootStoreContext'
import TwoFactorDisableDialog from './TwoFactorDisableDialog'

const TwoFactorSettingsGroup = () => {
  const history = useHistory()
  const { authenticationStore } = useRootStore()
  const [isDisableDialogOpen, setIsDisableDialogOpen] = useState<boolean>(false)

  return (
    <SettingsGroup title='Zwei-Faktor-Authentifizierung'>
      <Text>
        Hiermit kannst du dein Infinity-Konto und deine Geschäftsdaten, die sich
        darin befinden, mit einem zusätzlichen Sicherheitsfaktor schützen.
      </Text>
      {authenticationStore.currentUser?.isTwoFactorEnforced ? (
        <>
          <Button
            iconLeft='forgot-password'
            variant='destructive'
            onClick={() => setIsDisableDialogOpen(true)}
          >
            Deaktivieren
          </Button>
          <TwoFactorDisableDialog
            isOpen={isDisableDialogOpen}
            onClose={() => setIsDisableDialogOpen(false)}
          />
        </>
      ) : (
        <Button
          iconLeft='smartphone'
          variant='primary'
          onClick={() => history.push('/settings/security/setup-2fa')}
        >
          Einrichten
        </Button>
      )}
    </SettingsGroup>
  )
}

export default observer(TwoFactorSettingsGroup)
