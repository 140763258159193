import { Button, Flex, Text } from '@nextbusiness/infinity-ui'
import Skeleton from 'react-loading-skeleton'
import AppTileTransition from '../../components/apptile/AppTileTransition'

const DashboardAppLibraryLoading = () => (
  <>
    <LoadingAppTile />
    <LoadingAppTile />
    <LoadingAppTile />
  </>
)

const LoadingAppTile = () => (
  <AppTileTransition>
    <Button variant='shell' className='app-tile loading-placeholder'>
      <Flex
        fillContainer
        horizontalAlignment='flex-end'
        direction='vertical'
        verticalAlignment='flex-start'
      >
        <Skeleton width={44} height={44} className='app-icon' />
        <Text type='paragraph' className='app-title'>
          <Skeleton width={80} />
        </Text>
      </Flex>
    </Button>
  </AppTileTransition>
)

export default DashboardAppLibraryLoading
