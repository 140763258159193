import { Button, Dialog, Flex, Text } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useState } from 'react'
import Tabs from '../../proto-ui-components/Tabs'
import { useRootStore } from '../../stores/RootStoreContext'
import { useResetOnOpen } from '../../utility/hooks'
import MarketplaceApp, {
  PaymentInterval,
  isTieredPricing,
} from './MarketplaceApp'
import MarketplaceAppOfferArea from './MarketplaceAppOfferArea'
import MarketplaceAppTier from './MarketplaceAppTier'
import './MarketplaceAppTierModal.scss'
import MarketplaceAppTierOption from './MarketplaceAppTierOption'

interface MarketplaceAppTierModalProps {
  app: MarketplaceApp
  isOpen: boolean
  onDismiss: () => void
  subscribeToApplication: (app: MarketplaceApp, priceId?: string) => void
  setupPayments: () => void
}

export const priceIdForTier = (
  tier: MarketplaceAppTier,
  paymentInterval: PaymentInterval,
  offerCode?: string | null
) => {
  const offer = tier.offers?.find((offer) => offer.code === offerCode)
  if (offerCode && offer) {
    if (paymentInterval === 'monthly') {
      return offer.identifier
    } else if (paymentInterval === 'yearly' && offer.yearlyIdentifier) {
      return offer.yearlyIdentifier
    }
  }
  if (paymentInterval === 'monthly') {
    return tier.identifier
  } else {
    return tier.yearlyIdentifier ?? tier.identifier
  }
}

const MarketplaceAppTierModal = (props: MarketplaceAppTierModalProps) => {
  const { organisationStore, billingStore } = useRootStore()
  const { pricing } = props.app

  const [paymentInterval, setPaymentInterval] =
    useState<PaymentInterval>('monthly')
  const [offerCode, setOfferCode] = useState<string | null>(null)
  const [selectedTier, setSelectedTier] = useState<MarketplaceAppTier>()

  if (!isTieredPricing(pricing))
    throw new Error(
      'Cannot render MarketplaceAppTierModal for non-tiered pricing'
    )

  useResetOnOpen(() => {
    const preselectedTier = pricing.preselectedTierIdentifier
    const tierToPreselect = pricing.tiers?.find(
      (tier) => tier.identifier === preselectedTier
    )
    setOfferCode(null)
    setPaymentInterval('monthly')
    setSelectedTier(tierToPreselect)

    window.localStorage.removeItem('tier-to-subscribe-to')
  }, props.isOpen)

  const onOfferCodeEntered = (
    enteredCode: string,
    availableIntervals: PaymentInterval[]
  ) => {
    setOfferCode(enteredCode)
    if (!availableIntervals.includes(paymentInterval)) {
      setPaymentInterval(availableIntervals[0])
    }
  }

  const showOffers = props.app.showOffersOption
  const backdropImage = props.app.signupFlowAppearance?.backdropImage

  const subscribe = () => {
    if (!selectedTier) return

    const hasProvidedPaymentDetails =
      organisationStore.currentOrganisation?.stripeCustomerId !== undefined &&
      billingStore.details?.invoice_settings.default_payment_method != null

    const priceId = priceIdForTier(selectedTier, paymentInterval, offerCode)

    if (hasProvidedPaymentDetails) {
      props.onDismiss()
      props.subscribeToApplication(props.app, priceId)
    } else {
      window.localStorage.setItem(
        'tier-to-subscribe-to',
        JSON.stringify({
          app: props.app.identifier,
          priceId,
        })
      )
      props.setupPayments()
    }
  }

  return (
    <Dialog
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      className='marketplace-upgrade-dialog'
      dismissable
    >
      <div
        className='upgrade-sidebar'
        style={
          backdropImage
            ? {
                backgroundImage: `url('/assets/apps/signup-backdrops/${backdropImage}')`,
              }
            : undefined
        }
      >
        <img
          className='app-logo'
          src={`/assets/apps/icons/${props.app.icon ?? 'app-icon-generic.png'}`}
          alt=''
        />
        <h1>{props.app.title} abonnieren</h1>
        <p>Wähle das gewünschte Paket aus, das du abonnieren möchtest.</p>
      </div>
      <div className='upgrade-content'>
        <Flex>
          <Tabs<PaymentInterval>
            className='payment-interval'
            currentTabId={paymentInterval}
            tabs={[
              { identifier: 'monthly', title: 'Monatlich' },
              { identifier: 'yearly', title: 'Jährlich (günstiger)' },
            ]}
            onTabSelected={(tab) => setPaymentInterval(tab.identifier)}
          />
          <div style={{ marginLeft: 'auto' }}>
            {props.app.pricingPageUrl && (
              <Button
                variant='quaternary'
                onClick={() => window.open(props.app.pricingPageUrl, '_blank')}
                iconRight='external'
              >
                Alle Details ansehen
              </Button>
            )}
          </div>
        </Flex>
        <div className='upgrade-tiers'>
          {pricing.tiers?.map((tier) => (
            <MarketplaceAppTierOption
              paymentInterval={paymentInterval}
              app={props.app}
              tier={tier}
              key={tier.identifier}
              onTierSelected={() => setSelectedTier(tier)}
              isSelected={tier === selectedTier}
              offerCode={offerCode}
            />
          ))}
        </div>
        <div className='upgrade-actions'>
          {showOffers && (
            <MarketplaceAppOfferArea
              onOfferCodeEntered={onOfferCodeEntered}
              appIdentifier={props.app.identifier}
            />
          )}
          {selectedTier ? (
            <Button variant='primary' onClick={subscribe}>
              Auswählen und fortfahren
            </Button>
          ) : (
            <Text type='inline' variant='subtle'>
              Wähle ein Paket aus, um fortzufahren.
            </Text>
          )}
        </div>
      </div>
    </Dialog>
  )
}

export default observer(MarketplaceAppTierModal)
