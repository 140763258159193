class ContactCSVParser {
  private readonly rawCSV: string
  private readonly delimiter: string

  private constructor(rawCSV: string, delimiter: string) {
    this.rawCSV = rawCSV
    this.delimiter = delimiter
  }

  public static async fromFile(
    file: File,
    delimiter = ','
  ): Promise<ContactCSVParser> {
    const rawCSV = await file.text()
    return new ContactCSVParser(rawCSV, delimiter)
  }

  public parse(): { headers: string[]; rows: string[][] } {
    const lines = this.rawCSV.split('\n')
    const parsedLines: string[][] = []
    let continuationValue: string | null = null

    for (const line of lines) {
      const isContinuation = continuationValue !== null
      const { isDone, values, unfinishedLastItem } = this.parseLine(
        line,
        continuationValue,
        isContinuation
      )
      if (!isDone) {
        continuationValue = unfinishedLastItem
      } else if (isContinuation) {
        continuationValue = null
      }
      if (isContinuation) {
        parsedLines[parsedLines.length - 1].push(...values)
      } else {
        parsedLines.push(values)
      }
    }
    const headers = parsedLines[0]
    const data = parsedLines.slice(1)
    return { headers, rows: data }
  }

  private parseLine(
    line: string,
    unfinishedLastItem: string | null,
    isContinuation = false
  ) {
    const values: string[] = []

    let current = unfinishedLastItem ? `${unfinishedLastItem}\n` : ''
    let inQuotes = isContinuation

    for (const character of line) {
      if (character === '"') {
        inQuotes = !inQuotes
      } else if (character === this.delimiter && !inQuotes) {
        values.push(this.stripValue(current))
        current = ''
      } else {
        current += character
      }
    }

    if (!inQuotes && current !== '' && !isContinuation)
      values.push(this.stripValue(current))

    return {
      values,
      isDone: !inQuotes,
      unfinishedLastItem: current,
    }
  }

  private stripValue(value: string) {
    return value.trim().replace(/(^')|('$)/g, '')
  }
}

export default ContactCSVParser
