import { PrimaryColor } from '@nextbusiness/infinity-ui-foundations'
import MarketplaceAppTier from './MarketplaceAppTier'

interface MarketplaceApp {
  identifier: string
  mobileAppLinkingScheme?: string
  title: string
  launcherTitle?: string
  launcherAppearance?: LauncherAppearance
  signupFlowAppearance?: SignupFlowAppearance
  pricingPageUrl?: string
  icon?: string
  subtitle: string
  developer: string
  keyColor?: string
  subscriptionPriceId: string
  screenshotURLs?: string[]
  shortAppDescription?: string
  withholdAuthentication?: boolean
  pricing: FreePricingSystem | SubscriptionPricingSystem | TieredPricingSystem
  showOffersOption?: boolean
  primaryTrustedDomain?: string
}

export interface SignupFlowAppearance {
  backdropImage?: string
  defaultTrialDays?: number
  customSecurityPrompt?: string
  tint?: PrimaryColor
}

export interface LauncherAppearance {
  gradientStartColor: string
  gradientEndColor: string
  useDarkText?: boolean
}

type PricingSystemKind = 'free' | 'subscription' | 'tiered'

export interface PricingSystem {
  kind: PricingSystemKind
}

export interface FreePricingSystem extends PricingSystem {
  kind: 'free'
}
export interface SubscriptionPricingSystem extends PricingSystem {
  kind: 'subscription'
  monthlyPrice: number
  numberOfTrialDays: number
}
export interface TieredPricingSystem extends PricingSystem {
  kind: 'tiered'
  tiers: MarketplaceAppTier[]
  preselectedTierIdentifier?: string
  numberOfTrialDays: number
}

export type PaymentInterval = 'monthly' | 'yearly'

export const isTieredPricing = (
  pricing: FreePricingSystem | SubscriptionPricingSystem | TieredPricingSystem
): pricing is TieredPricingSystem => pricing.kind === 'tiered'

export default MarketplaceApp
