import { Flex, Text } from '@nextbusiness/infinity-ui'
import Skeleton from 'react-loading-skeleton'

const DashboardTeamMembersLoading = () => (
  <div>
    <MemberPlaceholder />
    <MemberPlaceholder />
    <MemberPlaceholder />
    <MemberPlaceholder />
  </div>
)

const MemberPlaceholder = () => (
  <Flex
    direction='horizontal'
    gap='tiny'
    verticalAlignment='center'
    className='member-card'
  >
    <Skeleton width={36} height={36} circle enableAnimation={false} />
    <Text type='inline' className='member-name'>
      <Skeleton width={160} />
    </Text>
  </Flex>
)
export default DashboardTeamMembersLoading
