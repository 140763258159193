import {
  Dialog,
  FeedbackBanner,
  InputField,
  Text,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import Authentication from '../../../../networking/Authentication'
import { ErrorType } from '../../../../networking/Errors'
import { useRootStore } from '../../../../stores/RootStoreContext'

interface TwoFactorDisableDialogProps {
  isOpen: boolean
  onClose: () => void
}

const TwoFactorDisableDialog = (props: TwoFactorDisableDialogProps) => {
  const { authenticationStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const [password, setPassword] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [generalError, setGeneralError] = useState<Error>()
  const [isPasswordIncorrect, setIsPasswordIncorrect] = useState<boolean>(false)

  const deactivate = async () => {
    setGeneralError(undefined)
    setIsPasswordIncorrect(false)
    setIsLoading(true)
    try {
      await Authentication.disable2FA(password)
      await authenticationStore.loadUserData()

      notificationCenter.addNotification({
        children: 'Zwei-Faktor-Authentifizierung wurde deaktiviert.',
      })
      props.onClose()
    } catch (error: any) {
      if (error.type === ErrorType.Unauthorised) {
        setIsPasswordIncorrect(true)
      } else {
        setGeneralError(error)
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (props.isOpen) {
      setPassword('')
      setGeneralError(undefined)
      setIsPasswordIncorrect(false)
    }
  }, [props.isOpen])

  return (
    <Dialog
      title='Zwei-Faktor-Authentifizierung deaktivieren'
      appearance='system'
      style={{ maxWidth: '54rem' }}
      actions={[
        {
          children: 'Abbrechen',
          onClick: () => props.onClose(),
        },
        {
          children: 'Deaktivieren',
          variant: 'destructive',
          isLoading,
          disabled: !password.trim(),
          onClick: () => deactivate(),
        },
      ]}
      {...props}
    >
      <Text type='paragraph'>
        Bitte bestätige mit deinem Account-Passwort, um
        Zwei-Faktor-Authentifizierung zu deaktivieren. Es wird danach kein
        Bestätigungscode mehr benötigt, um dich einzuloggen.
      </Text>
      <InputField
        value={password}
        onChange={setPassword}
        label='Kennwort bestätigen'
        type='password'
        hasError={isPasswordIncorrect}
        fullWidth
        helperText={
          isPasswordIncorrect ? 'Das Kennwort ist falsch.' : undefined
        }
      />
      {generalError && (
        <FeedbackBanner variant='error'>{generalError.message}</FeedbackBanner>
      )}
    </Dialog>
  )
}

export default observer(TwoFactorDisableDialog)
