import {
  ApplicationFrame,
  Flex,
  NavigationItem,
} from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import React from 'react'
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom'
import { AnimatedSwitch } from 'react-router-transition'
import ApplicationSubpageSwitch from '../../components/layout/ApplicationSubpageSwitch'
import Container from '../../components/layout/Container'
import RouteTransitions from '../../routes/RouteTransitions'
import { useRootStore } from '../../stores/RootStoreContext'
import NotFoundPage from '../NotFoundPage'
import './SettingsPage.scss'
import { SettingsHomeTransition } from './SettingsTransitions'
import SettingsAccount from './account/SettingsAccount'
import SettingsAppearance from './appearance/SettingsAppearance'
import SettingsBilling from './billing/SettingsBilling'
import SettingsContactImport from './contacts/import/SettingsContactImport'
import SettingsHome from './home/SettingsHome'
import SettingsOrganisation from './organisation/SettingsOrganisation'
import Settings2FASetup from './security/2fa-setup/Settings2FASetup'
import SettingsSecurity from './security/SettingsSecurity'
import SettingsTeam from './team/SettingsTeam'

const SettingsPage = () => {
  const { authenticationStore } = useRootStore()
  const location = useLocation()
  const history = useHistory()

  return (
    <div className='settings-page'>
      <ApplicationFrame
        appTitle=''
        history={history}
        location={location}
        identifier='settings'
        userMenu={authenticationStore.userMenu}
        primaryNavigation={[
          {
            path: '/dashboard',
            description: 'Start',
          },
          {
            path: '/marketplace',
            description: 'Marketplace',
          },
          {
            path: '/settings',
            description: 'Einstellungen',
          },
        ]}
      >
        <AnimatedSwitch
          {...RouteTransitions.Subpage}
          className='settings-switch'
        >
          <Route path='/settings' exact>
            <NavigationItem />
            <Container>
              <SettingsHomeTransition>
                <SettingsHome />
              </SettingsHomeTransition>
            </Container>
          </Route>
          <Route path='/settings'>
            <SettingsSubpage>
              <ApplicationSubpageSwitch>
                <Route path='/settings/account' exact>
                  <NavigationItem
                    backButton={{ path: '/settings', text: 'Zurück' }}
                    title='Kontodetails'
                  />
                  <SettingsAccount />
                </Route>
                <Route path='/settings/security' exact>
                  <NavigationItem
                    backButton={{ path: '/settings', text: 'Zurück' }}
                    title='Sicherheit'
                  />
                  <SettingsSecurity />
                </Route>
                <Route path='/settings/security/setup-2fa' exact>
                  <NavigationItem
                    backButton={{
                      path: '/settings/security',
                      text: 'Sicherheit',
                    }}
                    title='Zwei-Faktor-Authentifizierung'
                  />
                  <Settings2FASetup />
                </Route>
                <Route path='/settings/appearance' exact>
                  <NavigationItem
                    backButton={{ path: '/settings', text: 'Zurück' }}
                    title='Look & Feel'
                  />
                  <SettingsAppearance />
                </Route>
                <Route path='/settings/organisation' exact>
                  <NavigationItem
                    backButton={{ path: '/settings', text: 'Zurück' }}
                    title='Firmendaten'
                  />
                  <SettingsOrganisation />
                </Route>
                <Route path='/settings/contacts/import' exact>
                  <NavigationItem
                    backButton={{ path: '/settings', text: 'Zurück' }}
                    title='Kontakte importieren'
                  />
                  <SettingsContactImport />
                </Route>
                <Route path='/settings/team' exact>
                  <NavigationItem
                    backButton={{ path: '/settings', text: 'Zurück' }}
                    title='Teammitglieder'
                  />
                  <SettingsTeam />
                </Route>
                <Route path='/settings/billing' exact>
                  <NavigationItem
                    backButton={{ path: '/settings', text: 'Zurück' }}
                    title='Abrechnung'
                  />
                  <SettingsBilling />
                </Route>
                <Route path='/settings/billing/confirm' exact>
                  {/*
                   * Remove hard-coded application identifier once backend accepts
                   * custom redirect URLs after billing rewrite.
                   */}
                  <Redirect to='/marketplace/apps/ch.nextbusiness.infinity.finance?upgrade=1' />
                </Route>
                <Route path='/settings'>
                  <NotFoundPage
                    inline
                    returnLink='/settings'
                    returnText='Zurück zu Einstellungen'
                  />
                </Route>
              </ApplicationSubpageSwitch>
            </SettingsSubpage>
          </Route>
        </AnimatedSwitch>
      </ApplicationFrame>
    </div>
  )
}

const SettingsSubpage = (props: { children: React.ReactNode }) => (
  <Flex
    className='settings-subpage-wrapper'
    fillContainer
    verticalAlignment='stretch'
  >
    <div className='settings-page-inner'>{props.children}</div>
  </Flex>
)

export default observer(SettingsPage)
