import {
  Button,
  Card,
  FeedbackBanner,
  Flex,
  Spacer,
} from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import InlineEditList from '../../../components/input/InlineEditList'
import Authentication from '../../../networking/Authentication'
import { ErrorType } from '../../../networking/Errors'
import { useRootStore } from '../../../stores/RootStoreContext'
import './SettingsAccount.scss'
import DeleteAccountModal from './modals/DeleteAccount'

const SettingsAccount = () => {
  const { authenticationStore } = useRootStore()

  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] =
    useState<boolean>(false)

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  )

  const changeInformationOfUser = async (changes: {
    firstname?: string
    lastname?: string
    email?: string
  }) => {
    try {
      authenticationStore.currentUser =
        await Authentication.changeUserInformation(changes)
      authenticationStore.loadUserData()
      setErrorMessage(undefined)
    } catch (error: any) {
      switch (error.type) {
        case ErrorType.Unauthorised:
          return setErrorMessage(
            'Deine Sitzung ist abgelaufen. Bitte logge dich erneut ein.'
          )
        default:
          setErrorMessage(
            error.message ??
              'Ein unbekannter Fehler ist aufgetreten. Bitte versuche es erneut.'
          )
      }
    }
  }

  return (
    <div className='settings-subpage page-account'>
      <Helmet>
        <title>Kontodetails bearbeiten - Infinity</title>
      </Helmet>
      <Flex>
        <Card style={{ flexGrow: 1 }} className='user-info'>
          {errorMessage && (
            <FeedbackBanner variant='error'>{errorMessage}</FeedbackBanner>
          )}
          <InlineEditList
            appearance='aligned'
            onCommit={changeInformationOfUser}
            fields={[
              {
                type: 'input',
                key: 'firstname',
                title: 'Vorname',
                initialValue: authenticationStore.currentUser?.firstname,
              },
              {
                type: 'input',
                key: 'lastname',
                title: 'Nachname',
                initialValue: authenticationStore.currentUser?.lastname,
              },
              {
                type: 'input',
                key: 'email',
                title: 'E-Mail-Adresse',
                initialValue: authenticationStore.currentUser?.email,
                helperText:
                  'Diese E-Mail-Adresse wird beim Anmelden, für das Zurücksetzen des Kennworts und für Benachrichtigungen verwendet.',
              },
            ]}
          />
          <Spacer size='small' />
          <Flex gap='tiny'>
            <Button
              variant='destructive'
              iconLeft='delete'
              onClick={() => setIsDeleteAccountModalOpen(true)}
            >
              Konto löschen
            </Button>
          </Flex>
        </Card>
      </Flex>
      <DeleteAccountModal
        isOpen={isDeleteAccountModalOpen}
        setIsOpen={setIsDeleteAccountModalOpen}
      />
    </div>
  )
}

export default observer(SettingsAccount)
