import { Button, Text } from '@nextbusiness/infinity-ui'
import { useState } from 'react'
import SettingsGroup from '../../../../components/settings/SettingsGroup'
import ChangePasswordModal from '../../account/modals/ChangePassword'

const PasswordSettingsGroup = () => {
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState<boolean>(false)

  return (
    <SettingsGroup title='Anmeldekennwort'>
      <Text>
        Mit deinem Anmeldekennwort loggst du dich auf der Infinity-Plattform
        ein. Hier kannst du dieses Kennwort bei Bedarf ändern.
      </Text>
      <Button
        iconLeft='access'
        onClick={() => setIsChangePasswordModalOpen(true)}
      >
        Kennwort ändern
      </Button>
      <ChangePasswordModal
        isOpen={isChangePasswordModalOpen}
        setIsOpen={setIsChangePasswordModalOpen}
      />
    </SettingsGroup>
  )
}

export default PasswordSettingsGroup
