import { Flex, Text } from '@nextbusiness/infinity-ui'
import QRCode from 'react-qr-code'
import SettingsGroup from '../../../../components/settings/SettingsGroup'
import { TwoFactorSetupDetails } from '../../../../model/User'

interface SetupPartQRCodeProps {
  setupDetails: TwoFactorSetupDetails
}

const SetupPartQRCode = (props: SetupPartQRCodeProps) => (
  <SettingsGroup title='1. Einrichten'>
    <Text type='paragraph'>
      Beginne, indem du den folgenden QR-Code in einer Zwei-Faktor-App scannst.
      Du kannst beispielsweise eine der folgenden Apps verwenden:
    </Text>
    <ul className='two-factor-app-recommendations'>
      <li>
        <a
          href='https://www.microsoft.com/de-ch/security/mobile-authenticator-app'
          target='_blank'
          rel='noopener noreferrer'
        >
          Microsoft Authenticator (Unsere Empfehlung)
        </a>
      </li>
      <li>
        <a
          href='https://support.google.com/accounts/answer/1066447?hl=de'
          target='_blank'
          rel='noopener noreferrer'
        >
          Google Authenticator
        </a>
      </li>
      <li>
        <a
          href='https://authy.com/download/'
          target='_blank'
          rel='noopener noreferrer'
        >
          Authy
        </a>
      </li>
    </ul>
    <Flex
      className='setup-qr-code-container'
      horizontalAlignment='center'
      verticalAlignment='center'
      direction='vertical'
    >
      <Text>Scanne diesen QR-Code in deiner Authenticator-App.</Text>
      <QRCode
        className='setup-qr-code'
        value={props.setupDetails.setupUri}
        size={172}
      />
    </Flex>
  </SettingsGroup>
)

export default SetupPartQRCode
