import { Flex, NonIdealState, Text } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useRootStore } from '../stores/RootStoreContext'

const NotAuthenticatedPage = () => {
  const { authenticationStore } = useRootStore()
  const history = useHistory()

  useEffect(() => {
    if (authenticationStore.isHydrated && !authenticationStore.isAuthenticated)
      history.push('/login')
  }, [authenticationStore.isHydrated])

  return (
    <div className='utility-page page-unauthenticated'>
      <Flex
        fillContainer
        horizontalAlignment='center'
        verticalAlignment='center'
      >
        <NonIdealState
          icon='lock'
          title='Du bist nicht angemeldet.'
          actions={[
            {
              variant: 'primary',
              children: 'Anmelden',
              onClick: () => history.push('/login'),
            },
          ]}
        >
          <Flex direction='vertical'>
            <Text type='paragraph'>
              Diese Seite ist nur für angemeldete Benutzer sichtbar.
            </Text>
            <Text type='paragraph'>
              Wenn du vorhin angemeldet warst, ist deine Anmeldung
              möglicherweise nach einer bestimmten Zeit abgelaufen.
            </Text>
          </Flex>
        </NonIdealState>
      </Flex>
    </div>
  )
}

export default observer(NotAuthenticatedPage)
