import { Card, Flex } from '@nextbusiness/infinity-ui'

interface SettingsSubpageProps {
  className?: string
  children?: React.ReactNode
}

const SettingsSubpage = (props: SettingsSubpageProps) => (
  <div className={`settings-subpage ${props.className}`}>
    <Flex>
      <Card style={{ flexGrow: 1 }}>
        <div className='settings-content-container'>{props.children}</div>
      </Card>
    </Flex>
  </div>
)

export default SettingsSubpage
