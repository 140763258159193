import { Flex, LoadingIndicator } from '@nextbusiness/infinity-ui'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import SettingsSubpage from '../../../../components/settings/SettingsSubpage'
import { TwoFactorSetupDetails } from '../../../../model/User'
import Authentication from '../../../../networking/Authentication'
import Settings2FALoadingError from './Settings2FALoadingError'
import './Settings2FASetup.scss'
import SetupPartConfirmAndActivate from './SetupPartConfirmAndActivate'
import SetupPartQRCode from './SetupPartQRCode'
import SetupPartRecoveryCodes from './SetupPartRecoveryCodes'

const Settings2FASetup = () => {
  const [setupDetails, setSetupDetails] = useState<TwoFactorSetupDetails>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [loadingError, setLoadingError] = useState<Error>()

  const loadSetupDetails = async () => {
    setIsLoading(true)
    try {
      const setupDetails = await Authentication.setup2FA()
      setSetupDetails(setupDetails)
    } catch (error) {
      setLoadingError(error as Error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    loadSetupDetails()
  }, [])

  return (
    <SettingsSubpage className='page-2fa-setup'>
      <Helmet>
        <title>Zwei-Faktor-Authentifizierung - Infinity</title>
      </Helmet>
      {isLoading ? (
        <Flex horizontalAlignment='center'>
          <LoadingIndicator />
        </Flex>
      ) : loadingError || !setupDetails ? (
        <Settings2FALoadingError retry={loadSetupDetails} />
      ) : (
        <>
          <SetupPartQRCode setupDetails={setupDetails} />
          <SetupPartRecoveryCodes setupDetails={setupDetails} />
          <SetupPartConfirmAndActivate />
        </>
      )}
    </SettingsSubpage>
  )
}

export default Settings2FASetup
