import classNames from 'classnames'

export enum SignupStep {
  NameAndEmail = 'Name and E-Mail',
  Password = 'Kennwort wählen',
}

interface SignupStepperProps {
  currentStep: SignupStep
}

const SignupStepper = (props: SignupStepperProps) => (
  <div className='signup-stepper'>
    {Object.values(SignupStep).map((step) => (
      <div
        key={step}
        className={classNames('signup-step', {
          active: props.currentStep === step,
        })}
      >
        {step}
      </div>
    ))}
  </div>
)

export default SignupStepper
