import { Contact } from '../../../../model/Contact'
import ContactCSVMapper, { MappableContactKey } from './ContactCSVMapper'

class BexioContactCSVMapper extends ContactCSVMapper {
  private readonly fixedHeaderMapping: { [key: string]: MappableContactKey } = {
    'Name 2': 'firstname',
    Adresse: 'address',
    Land: 'country',
    'E-Mail': 'email',
    Telefon: 'phone',
    Mobile: 'mobile',
    Website: 'website',
    Bemerkungen: 'note',
    'MWST-Nummer': 'vatNumber',
  }

  protected mapKeyToContactFields(
    key: string,
    value: string,
    contactData: Record<string, string>
  ): Partial<Contact> {
    switch (key) {
      case 'Name 1':
        return this.transformName(contactData['Kontaktart'] ?? 'Privat', value)
      case 'Kategorie':
        return this.transformCategory(value)
      case 'Anrede':
        return this.transformFormOfAddress(value, contactData['Titel'])
      case 'PLZ':
        return this.transformPostCode(value, contactData['Ort'])
      default:
        return super.mapKeyToContactFields(key, value, contactData)
    }
  }

  protected mapHeader(header: string): MappableContactKey | null {
    return this.fixedHeaderMapping[header] || null
  }

  private transformName(type: string, name1: string): Partial<Contact> {
    if (type === 'Firma') {
      return { companyName: name1 }
    } else {
      return { lastname: name1 }
    }
  }

  private transformCategory(category: string): Partial<Contact> {
    switch (category) {
      case 'Kunden':
        return { category: 'clients' }
      case 'Lieferanten':
        return { category: 'suppliers' }
      case 'Partner':
        return { category: 'partners' }
      case 'Geschäftsleitungsmitglieder':
        return { category: 'team' }
      default:
        return {}
    }
  }

  private transformFormOfAddress(
    formOfAddress: string,
    academicTitle: string | undefined
  ): Partial<Contact> {
    return {
      formOfAddress: academicTitle
        ? `${formOfAddress} ${academicTitle}`.trim()
        : formOfAddress,
    }
  }

  private transformPostCode(
    postCode: string,
    city: string | undefined
  ): Partial<Contact> {
    return {
      postCode: city ? `${postCode} ${city}`.trim() : postCode,
    }
  }
}

export default BexioContactCSVMapper
