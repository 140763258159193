import { Permission } from '../../model/App'

export const displayNameForPermission = (
  permission: Permission
): string | null => {
  switch (permission) {
    case Permission.ReadContacts:
    case Permission.WriteContacts:
      return 'Kontakte'
    case Permission.ReadTasks:
    case Permission.WriteTasks:
      return 'Tasks'
    case Permission.ReadProjects:
    case Permission.WriteProjects:
      return 'Projekte/Tasks'
    default:
      return null
  }
}

export const displayNameForPermissions = (permissions: Permission[]) => {
  const displayNames: string[] = []

  permissions.forEach((permission) => {
    const name = displayNameForPermission(permission)
    if (name && !displayNames.includes(name)) {
      displayNames.push(name)
    }
  })

  if (displayNames.length === 0) {
    return 'bestimmte Funktionen'
  } else if (displayNames.length <= 2) {
    return `die ${displayNames.join(' und ')}`
  } else {
    return `die ${displayNames
      .slice(0, -1)
      .join(', ')} und ${displayNames.pop()}`
  }
}

export const longDisplayNameForPermission = (permission: Permission) => {
  switch (permission) {
    case Permission.ReadContacts:
      return 'Kontakte auslesen'
    case Permission.WriteContacts:
      return 'Kontakte erstellen, ändern und löschen'
    case Permission.ReadTasks:
      return 'Tasks auslesen'
    case Permission.WriteTasks:
      return 'Tasks erstellen, ändern und löschen'
    case Permission.ReadProjects:
      return 'Projekte und Tasks auslesen'
    case Permission.WriteProjects:
      return 'Projekte und Tasks erstellen, ändern und löschen'
  }
  return `Weiteres (${permission})`
}
