import { Dialog, Text } from '@nextbusiness/infinity-ui'
import { useHistory } from 'react-router-dom'
import MarketplaceApp from '../MarketplaceApp'

interface AppActivatedConfirmationProps {
  app: MarketplaceApp
  successAction: 'started-trial' | 'subscribed'
  isOpen: boolean
  onClose: () => void
}

const AppActivatedConfirmation = (props: AppActivatedConfirmationProps) => {
  const history = useHistory()
  return (
    <Dialog
      icon='approval'
      title={
        props.successAction === 'started-trial'
          ? 'Testversion gestartet'
          : 'App erfolgreich abonniert'
      }
      isOpen={props.isOpen}
      actions={[
        {
          children: 'Schliessen',
          onClick: () => props.onClose(),
        },
        {
          children: 'App starten',
          variant: 'primary',
          onClick: () => {
            props.onClose()
            history.push('/app/' + props.app.identifier)
          },
        },
      ]}
    >
      <div style={{ maxWidth: '35rem' }}>
        {props.successAction === 'started-trial' && (
          <Text type='paragraph'>
            Eine Testversion für {props.app?.title ?? 'die ausgewählte App'} ist
            für deine Organisation aktiviert.
          </Text>
        )}
        {props.successAction === 'subscribed' && (
          <Text type='paragraph'>
            Du hast ein Upgrade auf die Vollversion für{' '}
            {props.app?.title ?? 'die ausgewählte App'} vorgenommen.
          </Text>
        )}
      </div>
    </Dialog>
  )
}

export default AppActivatedConfirmation
