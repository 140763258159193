import { observer } from 'mobx-react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import ILoginIntent, { isAppLauncherLoginIntent } from '../model/LoginIntent'
import { useRootStore } from '../stores/RootStoreContext'

export const recallLoginIntent = (intent: ILoginIntent): string => {
  if (isAppLauncherLoginIntent(intent) && intent.app) {
    let launchURL = `/app/${intent.app}`

    if (intent.launcher) launchURL += `?launcher=${intent.launcher}`
    if (intent.modifier)
      launchURL += `${intent.launcher ? '&' : '?'}modifier=${intent.modifier}`
    if (intent.organisation)
      launchURL += `${launchURL.includes('?') ? '&' : '?'}organisation=${
        intent.organisation
      }`
    if (intent.appLaunchHost)
      launchURL += `${launchURL.includes('?') ? '&' : '?'}expo-host=${
        intent.appLaunchHost
      }`

    return launchURL
  }
  return '/'
}

const UnauthenticatedRoute = (routeProps: RouteProps) => {
  const { authenticationStore } = useRootStore()
  const { children, ...rest } = routeProps

  return (
    <Route {...rest}>
      {!authenticationStore.isAuthenticated ? (
        children
      ) : authenticationStore.rememberedLoginIntent ? (
        <Redirect
          to={recallLoginIntent(authenticationStore.rememberedLoginIntent)}
        />
      ) : (
        <Redirect to='/' />
      )}
    </Route>
  )
}

export default observer(UnauthenticatedRoute)
