import { Header } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory, useParams } from 'react-router'
import { Permission } from '../../model/App'
import { IAppLauncherLoginIntent } from '../../model/LoginIntent'
import Apps from '../../networking/Apps'
import { ErrorType } from '../../networking/Errors'
import { useRootStore } from '../../stores/RootStoreContext'
import { useAsyncEffect, useQuery } from '../../utility/hooks'
import MarketplaceApps from '../marketplace/MarketplaceApps'
import { remainingTrialDays } from '../marketplace/app-installation/AppStatusAreaTrialing'
import './AppLauncher.scss'
import AppNotActivated from './launcher-states/AppNotActivated'
import AppStartFailed from './launcher-states/AppStartFailed'
import AskForConfirmation from './launcher-states/AskForConfirmation'
import StartingApp from './launcher-states/StartingApp'
import SubscriptionCanceled from './launcher-states/SubscriptionCanceled'
import SubscriptionExpired from './launcher-states/SubscriptionExpired'
import SubscriptionFrozen from './launcher-states/SubscriptionFrozen'

const AppLauncher = () => {
  const history = useHistory()
  const { organisationStore, authenticationStore, billingStore } =
    useRootStore()
  const { applicationIdentifier } = useParams<{
    applicationIdentifier: string
  }>()
  const query = useQuery()
  const modifier = query.get('modifier')
  const launcher = query.get('launcher')
  const organisation = query.get('organisation')
  const expoHost = query.get('expo-host')

  const organisationId = organisation ?? organisationStore.currentIdentifier

  const [hasErrorOccured, setHasErrorOccured] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [isSubscribed, setIsSubscribed] = useState<boolean>(true)

  const [hasSubscriptionExpired, setHasSubscriptionExpired] =
    useState<boolean>(false)
  const [isSubscriptionFrozen, setIsSubscriptionFrozen] =
    useState<boolean>(false)
  const [isSubscriptionCanceled, setIsSubscriptionCanceled] =
    useState<boolean>(false)

  const askForConfirmationBeforeLaunch =
    launcher === 'app' || launcher === 'app-expo-dev'

  const appDetails = MarketplaceApps.find(
    (app) => applicationIdentifier === app.identifier
  )

  const memoriseLoginIntent = () => {
    const intent: IAppLauncherLoginIntent = {
      type: 'applauncher',
      app: applicationIdentifier,
      modifier: modifier ?? undefined,
      launcher: launcher ?? undefined,
      organisation: organisation ?? undefined,
      appLaunchHost: expoHost ?? undefined,
    }
    authenticationStore.rememberedLoginIntent = intent
  }

  useAsyncEffect(
    async () => {
      if (!authenticationStore.isHydrated) return
      if (!authenticationStore.isAuthenticated) {
        memoriseLoginIntent()
        history.push('/login')
        return
      }
      if (!billingStore.isHydrated) return
      const hasOrganisationAccess = await checkOrganisationAccess(
        organisationId
      )
      if (hasOrganisationAccess && checkSubscription()) {
        await setLaunchPermission(organisationId!)
        if (!askForConfirmationBeforeLaunch)
          await launchApplication(organisationId!)
      }
    },
    undefined,
    [
      authenticationStore.isHydrated,
      authenticationStore.isAuthenticated,
      billingStore.isHydrated,
    ]
  )

  const setLaunchPermission = async (organisationId: string) => {
    try {
      /**
       * This call to set permission currently would prevent any non-admin user
       * from launching any app, which is why it lives in a separate
       * try-catch-block for now (as not to throw an exception).
       *
       * This will be removed in a future update anyway, as soon as the backend
       * removes the constraint that only apps with existant permissions can be
       * authorised.
       */
      await Apps.setPermission(
        organisationId,
        applicationIdentifier,
        Permission.Launch,
        'allow'
      )
    } catch {}
  }

  const launchApplication = async (organisationId: string) => {
    try {
      let [token, redirectUrl] = await Apps.authenticateApp(
        organisationId,
        applicationIdentifier
      )
      if (modifier === 'development-mode') {
        redirectUrl = 'http://localhost:3000/'
      } else if (launcher === 'app' && appDetails?.mobileAppLinkingScheme) {
        redirectUrl = `${appDetails.mobileAppLinkingScheme}://login`
      } else if (launcher === 'app-expo-dev' && expoHost) {
        redirectUrl = `exp://${expoHost}/--/login`
      }
      if (appDetails?.withholdAuthentication) {
        document.location.href = `${redirectUrl}`
      } else {
        document.location.href = `${redirectUrl}?token=${token}&organisationIdentifier=${organisationId}`
      }
    } catch (error: any) {
      switch (error.type) {
        case ErrorType.Unauthorised:
          authenticationStore.logout()
          history.push('/login')
          return
        case ErrorType.InsufficientSubscription:
          setHasErrorOccured(true)
          return setIsSubscribed(false)
        case ErrorType.NotFound:
          setHasErrorOccured(true)
          if (error.message === 'This organisation does not exist') {
            return setErrorMessage(
              'Die Organisation konnte nicht gefunden werden.'
            )
          } else return setErrorMessage('Die App konnte nicht gefunden werden.')
        default:
          setHasErrorOccured(true)
          setErrorMessage(
            error.message ??
              'Ein unbekannter Fehler ist aufgetreten. Bitte versuche es erneut.'
          )
      }
    }
  }

  const checkOrganisationAccess = async (
    organisationId: string | null
  ): Promise<boolean> => {
    if (
      !organisationId ||
      !authenticationStore.currentUser?.organisations.some(
        (org) => org.IDForAPI === organisationId
      )
    ) {
      setHasErrorOccured(true)
      setErrorMessage(
        'Du hast keinen Zugriff auf diese Organisation. Stelle sicher, dass du mit dem korrekten Konto abgemeldet bist.'
      )
      return false
    }
    if (organisationId !== organisationStore.currentIdentifier) {
      organisationStore.selectOrganisation(organisationId)
      await billingStore.loadApps(organisationId)
      return false
    }
    return true
  }

  const checkSubscription = () => {
    const subscription = billingStore.subscriptions.find((sub) =>
      sub.apps.includes(applicationIdentifier)
    )
    const status = billingStore.statusForApplication(applicationIdentifier)
    if (subscription?.isFrozen) {
      setIsSubscriptionFrozen(true)
      return false
    }
    if (
      !subscription ||
      status === 'inactive' ||
      (status === 'trial' && remainingTrialDays(subscription) < 0)
    ) {
      setHasSubscriptionExpired(true)
      return false
    }
    if (subscription.status === 'canceled') {
      setIsSubscriptionCanceled(true)
      return false
    }
    return true
  }

  if (!authenticationStore.isHydrated) return null
  if (!authenticationStore.isAuthenticated) return null

  return (
    <>
      <Helmet>
        <title>{appDetails?.title ?? 'App starten - Infinity'}</title>
      </Helmet>
      <Header
        appTitle={appDetails?.launcherTitle ?? appDetails?.title}
        onHomeClick={() => history.push('/')}
        appearance='transparent'
      />
      {hasSubscriptionExpired ? (
        <SubscriptionExpired
          appDetails={appDetails}
          applicationIdentifier={applicationIdentifier}
          onLaunchAnyway={() => void launchApplication(organisationId!)}
        />
      ) : isSubscriptionFrozen ? (
        <SubscriptionFrozen
          appDetails={appDetails}
          applicationIdentifier={applicationIdentifier}
        />
      ) : isSubscriptionCanceled ? (
        <SubscriptionCanceled
          appDetails={appDetails}
          applicationIdentifier={applicationIdentifier}
        />
      ) : hasErrorOccured ? (
        isSubscribed ? (
          <AppStartFailed errorMessage={errorMessage} />
        ) : (
          <AppNotActivated />
        )
      ) : askForConfirmationBeforeLaunch ? (
        <AskForConfirmation
          appDetails={appDetails}
          memoriseLoginIntent={memoriseLoginIntent}
          onLaunch={() => void launchApplication(organisationId!)}
          organisationId={organisationId}
        />
      ) : (
        <StartingApp appDetails={appDetails} />
      )}
    </>
  )
}

export default observer(AppLauncher)
