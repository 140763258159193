import { NonIdealState } from '@nextbusiness/infinity-ui'
import { IconSize } from '@nextbusiness/infinity-ui-icons'

interface Settings2FALoadingErrorProps {
  retry: () => void
}

const Settings2FALoadingError = (props: Settings2FALoadingErrorProps) => (
  <NonIdealState
    icon='error'
    iconSize={IconSize.Medium}
    actions={[{ children: 'Erneut versuchen', onClick: () => props.retry() }]}
  >
    Zwei-Faktor-Einrichtungsangaben konnten nicht geladen werden.
  </NonIdealState>
)

export default Settings2FALoadingError
