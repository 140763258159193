export const changeColorLightness = (
  hexColor: string,
  lightness: number
): string => {
  const color = parseInt(hexColor.replace('#', ''), 16)

  const r = (color & 0xff0000) / 0x10 ** 4
  const g = (color & 0x00ff00) / 0x10 ** 2
  const b = color & 0x0000ff

  const changedR = Math.max(0, Math.min(r + lightness, 0xff))
  const changedG = Math.max(0, Math.min(g + lightness, 0xff))
  const changedB = Math.max(0, Math.min(b + lightness, 0xff))

  return (
    '#' + (changedR * 0x10 ** 4 + changedG * 0x10 ** 2 + changedB).toString(16)
  )
}
