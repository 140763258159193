export const centsToCHF = (
  amount: number | undefined,
  shouldDisplayNegative?: boolean
) => {
  if (!amount) return '0.00'
  const chf = new Intl.NumberFormat('en-EN', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
    .format((shouldDisplayNegative ? -amount : amount) / 100)
    .replaceAll(',', "'")

  return chf
}
