import { Text } from '@nextbusiness/infinity-ui'
import React from 'react'
import { Link } from 'react-router-dom'
import MarketplaceApp from './MarketplaceApp'
import './MarketplaceDiscoverAppCard.scss'

interface MarketplaceDiscoverAppCardProps {
  app: MarketplaceApp
}

const MarketplaceDiscoverAppCard = (props: MarketplaceDiscoverAppCardProps) => (
  <Link
    className='marketplace-app-card'
    to={'/marketplace/apps/' + props.app.identifier}
  >
    <div className='app-card-header'>
      <div className='app-icon'>
        <img src={'/assets/apps/icons/' + props.app.icon} alt='' />
      </div>
      <div className='app-depiction'>
        <Text className='app-title'>{props.app.title}</Text>
        <Text className='app-subtitle' variant='subtle'>
          {props.app.subtitle}
        </Text>
      </div>
    </div>
    <div
      className='app-card-preview'
      style={{
        backgroundImage: props.app.screenshotURLs?.length
          ? "url('/assets/apps/app-previews/" +
            props.app.screenshotURLs[0] +
            "')"
          : undefined,
      }}
    />
  </Link>
)

export default MarketplaceDiscoverAppCard
