import { Button } from '@nextbusiness/infinity-ui'
import { useState } from 'react'
import { PaymentInterval } from './MarketplaceApp'
import MarketplaceAppOfferCodeModal from './MarketplaceAppOfferCodeModal'

interface MarketplaceAppOfferAreaProps {
  onOfferCodeEntered: (
    offerCode: string,
    availableIntervals: PaymentInterval[]
  ) => void
  appIdentifier: string
}

const MarketplaceAppOfferArea = (props: MarketplaceAppOfferAreaProps) => {
  const [isOfferCodeDialogOpen, setIsOfferCodeDialogOpen] =
    useState<boolean>(false)

  return (
    <div className='marketplace-app-offer-area'>
      <Button
        variant='quaternary'
        className='enter-offer-action'
        iconLeft='voucher'
        onClick={() => setIsOfferCodeDialogOpen(true)}
      >
        Angebotscode eingeben
      </Button>
      <MarketplaceAppOfferCodeModal
        appIdentifier={props.appIdentifier}
        isOpen={isOfferCodeDialogOpen}
        onDismiss={() => setIsOfferCodeDialogOpen(false)}
        onOfferCodeEntered={props.onOfferCodeEntered}
      />
    </div>
  )
}

export default MarketplaceAppOfferArea
