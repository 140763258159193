import { Text } from '@nextbusiness/infinity-ui'
import { Icon } from '@nextbusiness/infinity-ui-icons'
import { Helmet } from 'react-helmet'
import AuthPage from './AuthPage'
import RegistrationStep from './registration/RegistrationStep'

const WelcomePage = () => (
  <AuthPage>
    <Helmet>
      <title>Willkommen bei Infinity - Infinity</title>
    </Helmet>
    <RegistrationStep
      icon={<Icon icon='firework-explosion' />}
      title='Willkommen bei Infinity.'
    >
      <Text>
        Du kannst dich bei Infinity ab nun jederzeit unter{' '}
        <a
          href='https://app.infinity.swiss'
          target='_blank'
          rel='noopener noreferrer'
          className='link'
        >
          app.infinity.swiss
        </a>{' '}
        einloggen.
      </Text>
    </RegistrationStep>
  </AuthPage>
)

export default WelcomePage
