import { Button, Flex, Text } from '@nextbusiness/infinity-ui'
import { Icon } from '@nextbusiness/infinity-ui-icons'

interface AppStatusAreaCanceledProps {
  reactivate: () => void
}

const AppStatusAreaCanceled = (props: AppStatusAreaCanceledProps) => (
  <Flex verticalAlignment='center' gap='regular' direction='horizontal-reflow'>
    <Flex gap='tiny' verticalAlignment='center' style={{ flexGrow: 1 }}>
      <Icon icon='not-allowed' size={20} />
      <Text type='inline'>Storniert</Text>
    </Flex>
    <Button variant='primary' onClick={props.reactivate}>
      Reaktivieren
    </Button>
  </Flex>
)

export default AppStatusAreaCanceled
