import { Button, Card, Text } from '@nextbusiness/infinity-ui'
import { IconItem } from '@nextbusiness/infinity-ui-icons'
import React from 'react'
import './Widget.scss'

interface WidgetProps {
  title: string
  identifier: string
  children?: React.ReactNode
  onClick?: () => void
  icon?: IconItem
}

const Widget = (props: WidgetProps) => (
  <Card className={`dashboard-widget widget-${props.identifier}`}>
    <Text type='paragraph' className='dashboard-widget-title'>
      {props.onClick ? (
        <Button
          variant='quaternary'
          onClick={props.onClick}
          iconLeft={props.icon}
        >
          {props.title}
        </Button>
      ) : (
        props.title
      )}
    </Text>
    {props.children}
  </Card>
)

export default Widget
