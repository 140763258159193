import { Card, Flex, Heading } from '@nextbusiness/infinity-ui'
import { ReactComponent as MarketplaceLogo } from '../../assets/marketplace-logo.svg'
import MarketplaceApps from './MarketplaceApps'
import MarketplaceDiscoverAppCard from './MarketplaceDiscoverAppCard'
import './MarketplaceDiscoverPage.scss'

const MarketplaceDiscoverPage = () => (
  <div className='marketplace-subpage marketplace-discover-page'>
    <Flex horizontalAlignment='center' className='marketplace-header'>
      <MarketplaceLogo className='marketplace-logo' aria-label='Marketplace' />
    </Flex>
    <Card className='marketplace-sheet' elevation='highest'>
      <Heading type='h2' divider bareTop>
        Apps entdecken
      </Heading>
      <Flex wrap className='marketplace-discovery-apps'>
        {MarketplaceApps.map((app) => (
          <MarketplaceDiscoverAppCard app={app} key={app.identifier} />
        ))}
      </Flex>
    </Card>
  </div>
)

export default MarketplaceDiscoverPage
