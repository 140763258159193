import React from 'react'
import { motion } from 'framer-motion'

const transitionTiles = {
  hidden: { x: 0, scale: 0.5, opacity: 0 },
  visible: { x: 0, scale: 1, opacity: 1 },
}

const AppTileTransition = (props: { children: React.ReactNode }) => (
  <motion.div
    variants={transitionTiles}
    transition={{
      type: 'spring',
      stiffness: 40,
      mass: 0.2,
      damping: 5,
    }}
  >
    {props.children}
  </motion.div>
)

export default AppTileTransition
