import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { AnimatedRoute, AnimatedSwitch } from 'react-router-transition'
import NotFoundPage from './pages/NotFoundPage'
import AppLauncher from './pages/app/AppLauncher'
import LogoutPage from './pages/authentication/LogoutPage'
import RegistrationPage from './pages/authentication/RegistrationPage'
import ResetPasswordPage from './pages/authentication/ResetPasswordPage'
import SetupOrganisationPage from './pages/authentication/SetupOrganisationPage'
import VerificationPromptPage from './pages/authentication/VerificationPromptPage'
import VerifyEmailPage from './pages/authentication/VerifyEmailPage'
import WelcomePage from './pages/authentication/WelcomePage'
import AppRegistrationPage from './pages/authentication/app-signup-flow/AppRegistrationPage'
import LoginAsPage from './pages/authentication/login/LoginAsPage'
import LoginPage from './pages/authentication/login/LoginPage'
import DashboardPage from './pages/dashboard/DashboardPage'
import MarketplacePage from './pages/marketplace/MarketplacePage'
import SettingsPage from './pages/settings/SettingsPage'
import GoodbyePage from './pages/settings/account/GoodbyePage'
import AskForPermissionPage from './pages/system/AskForPermissionPage'
import MaintenancePage from './pages/system/MaintenancePage'
import AuthenticatedRoute from './routes/AuthenticatedRoute'
import RouteTransitions from './routes/RouteTransitions'
import UnauthenticatedRoute from './routes/UnauthenticatedRoute'

const IS_MAINTENANCE_MODE = import.meta.env.VITE_MAINTENANCE_MODE === 'true'

const ApplicationSwitch = () => {
  const location = useLocation()
  if (IS_MAINTENANCE_MODE) {
    return (
      <Switch>
        <Route path='*'>
          <MaintenancePage />
        </Route>
      </Switch>
    )
  }
  return (
    <AnimatedSwitch
      atActive={{ opacity: 1 }}
      atEnter={{ opacity: 0 }}
      atLeave={{ opacity: 0 }}
      className='application-switch'
    >
      <AuthenticatedRoute path='/' exact redirectToIfUnauthenticated='/login'>
        <Redirect to='/dashboard' />
      </AuthenticatedRoute>
      <UnauthenticatedRoute path='/login' exact>
        <LoginPage />
      </UnauthenticatedRoute>
      <Route path='/login/using/:secretId' exact>
        <LoginAsPage />
      </Route>
      <UnauthenticatedRoute path='/changePassword/:userId/:secretId'>
        <ResetPasswordPage />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path='/register' exact>
        <RegistrationPage />
      </UnauthenticatedRoute>
      <Route path='/register/app/:appId'>
        <AppRegistrationPage />
      </Route>
      <Route path='/verification-prompt' exact>
        <VerificationPromptPage />
      </Route>
      <Route path='/verify-email' exact>
        <VerifyEmailPage />
      </Route>
      <Route path='/welcome' exact>
        <WelcomePage />
      </Route>
      <Route path='/logout' exact>
        <LogoutPage />
      </Route>
      <AuthenticatedRoute path='/setup-organisation' exact>
        <SetupOrganisationPage />
      </AuthenticatedRoute>
      <AuthenticatedRoute path='/system/ask-permission' exact>
        <AskForPermissionPage />
      </AuthenticatedRoute>
      <AuthenticatedRoute
        path='/dashboard'
        exact
        animation={RouteTransitions.Dashboard}
      >
        <DashboardPage />
      </AuthenticatedRoute>
      <AuthenticatedRoute
        path='/marketplace'
        animation={{
          ...RouteTransitions.App,
          runOnMount: location.pathname === '/marketplace',
        }}
      >
        <MarketplacePage />
      </AuthenticatedRoute>
      <AuthenticatedRoute
        path='/settings'
        animation={{
          ...RouteTransitions.App,
          runOnMount: location.pathname === '/settings',
        }}
      >
        <SettingsPage />
      </AuthenticatedRoute>
      <AnimatedRoute
        path='/app/:applicationIdentifier'
        {...RouteTransitions.App}
        runOnMount={location.pathname.startsWith('/app')}
      >
        <AppLauncher />
      </AnimatedRoute>
      <Route path='/goodbye' exact>
        <GoodbyePage />
      </Route>
      <Route>
        <NotFoundPage />
      </Route>
    </AnimatedSwitch>
  )
}

export default ApplicationSwitch
