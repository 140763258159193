import {
  Button,
  FeedbackBanner,
  Heading,
  Text,
} from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { Contact } from '../../../../model/Contact'
import Contacts from '../../../../networking/Contacts'
import { useRootStore } from '../../../../stores/RootStoreContext'
import perceptionalDelay from '../../../../utility/delays'
import ContactCSVImportCount from './ContactCSVImportCount'
import './ContactCSVImportList.scss'
import { ImportResult, ImportResultState } from './ContactCSVImportResults'
import ContactCSVImportTable from './ContactCSVImportTable'

interface ContactCSVImportListProps {
  contacts: Partial<Contact>[]
  isImporting: boolean
  setIsImporting: (isImporting: boolean) => void
}

const ContactCSVImportList = (props: ContactCSVImportListProps) => {
  const { organisationStore } = useRootStore()

  const [results, setResults] = useState<ImportResult[]>()
  const [isCompleted, setIsCompleted] = useState<boolean>(false)

  const successCount =
    results?.filter((result) => result.state === 'success').length ?? 0
  const errorCount =
    results?.filter((result) => result.state === 'error').length ?? 0

  const updateImportResult = (
    contactIndex: number,
    state: ImportResultState | undefined,
    error?: Error
  ) =>
    setResults((existingResults) =>
      existingResults?.map((result) => {
        if (result.contactIndex === contactIndex)
          return { ...result, state, error }
        return result
      })
    )

  const importContact = async (atIndex: number, contact: Partial<Contact>) => {
    try {
      await Contacts.createContact(
        organisationStore.currentIdentifier!,
        contact
      )
      updateImportResult(atIndex, 'success')
    } catch (error) {
      updateImportResult(atIndex, 'error', error as Error)
    }
  }

  const importContacts = async () => {
    setIsCompleted(false)
    props.setIsImporting(true)
    for (let index = 0; index < props.contacts.length; index++) {
      const contact = props.contacts[index]
      const result = results?.[index]

      if (result?.state === 'success') continue

      updateImportResult(index, undefined)

      await perceptionalDelay(100)
      await importContact(index, contact)
    }
    props.setIsImporting(false)
    setIsCompleted(true)
  }

  useEffect(() => {
    setIsCompleted(false)
    setResults(
      props.contacts.map((contact, index) => ({
        contactIndex: index,
      }))
    )
  }, [props.contacts])

  return (
    <div className='contact-import-list'>
      <Heading type='h3'>Zu importierende Kontakte</Heading>
      <ContactCSVImportCount contacts={props.contacts} />
      <ContactCSVImportTable contacts={props.contacts} results={results} />
      {!!props.contacts.length && !isCompleted && (
        <Button
          variant='primary'
          onClick={() => importContacts()}
          isLoading={props.isImporting}
        >
          Kontakte importieren
        </Button>
      )}
      {isCompleted &&
        (errorCount === 0 ? (
          <FeedbackBanner variant='success'>
            {successCount} Kontakte wurden erfolgreich importiert.
          </FeedbackBanner>
        ) : (
          <>
            <FeedbackBanner variant='warning'>
              {successCount} Kontakte wurden erfolgreich importiert und bei{' '}
              {errorCount} Kontakten ist ein Fehler aufgetreten.
            </FeedbackBanner>
            <Button
              variant='primary'
              onClick={() => importContacts()}
              isLoading={props.isImporting}
            >
              Fehlgeschlagene erneut versuchen
            </Button>
          </>
        ))}
      {props.isImporting && (
        <Text variant='subtle'>
          {successCount + errorCount} / {props.contacts.length} Kontakte werden
          importiert... {errorCount > 0 ? `(${errorCount} fehlgeschlagen)` : ''}
        </Text>
      )}
    </div>
  )
}

export default observer(ContactCSVImportList)
