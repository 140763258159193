import {
  Button,
  Dialog,
  FeedbackBanner,
  Flex,
  InputField,
  Select,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import React, { MutableRefObject, useEffect, useState } from 'react'
import { Level } from '../../../model/OrganisationMember'
import { ErrorType } from '../../../networking/Errors'
import Organisations from '../../../networking/Organisations'
import { useRootStore } from '../../../stores/RootStoreContext'

const MEMBER_ACCESS_LEVELS: { label: string; value: Level }[] = [
  { label: 'Administrator:in', value: 'admin' },
  { label: 'Mitglied', value: 'user' },
]

type Member = { email: string; level: Level }

interface InviteMemberDialogProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const InviteMemberDialog = React.forwardRef<
  HTMLButtonElement,
  InviteMemberDialogProps
>((props: InviteMemberDialogProps, ref) => {
  const notificationCenter = useNotificationCenter()
  const { organisationStore } = useRootStore()

  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const [isInviting, setIsInviting] = useState<boolean>(false)
  const [membersToBeInvited, setMembersToBeInvited] = useState<Member[]>([])

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  )
  const hasIncompleteFields = membersToBeInvited.some(
    (member) => member.email === '' || !member.level
  )

  useEffect(() => {
    if (props.isOpen) {
      setMembersToBeInvited([{ email: '', level: 'user' }])
      setErrorMessage(undefined)
      setShouldValidate(false)
    }
  }, [props.isOpen])

  const addInviteMemberOption = () => {
    setMembersToBeInvited([...membersToBeInvited, { email: '', level: 'user' }])
  }

  const removeInviteMemberOption = (indexOfOption: number) => {
    const updatedMembersToBeInvited = [...membersToBeInvited]
    updatedMembersToBeInvited.splice(indexOfOption, 1)
    setMembersToBeInvited(updatedMembersToBeInvited)
  }

  const inviteMembers = async (members: Member[]) => {
    if (!organisationStore.currentOrganisation) return
    setShouldValidate(true)
    if (hasIncompleteFields) return
    try {
      setIsInviting(true)
      setErrorMessage(undefined)
      await Promise.all(
        members.map((member) =>
          Organisations.inviteMemberToOrganisation(
            organisationStore.currentOrganisation!.id,
            { email: member.email, desired_level: member.level }
          )
        )
      )
      if (membersToBeInvited.length === 1) {
        notificationCenter.addNotification({
          variant: 'success',
          children: `${membersToBeInvited[0].email} wurde erfolgreich eingeladen.`,
        })
      } else {
        notificationCenter.addNotification({
          variant: 'success',
          children: `${membersToBeInvited.length} Einladungen wurden erfolgreich verschickt.`,
        })
      }
    } catch (error: any) {
      switch (error.type) {
        case ErrorType.Unauthorised:
          return setErrorMessage(
            'Nur Administrator:innen können weitere Teammitglieder einladen.'
          )
        case ErrorType.MissingRequiredFields:
          return setErrorMessage('Bitte fülle alle Pflichtfelder aus.')
        case ErrorType.NotFound:
          return setErrorMessage(
            'Die Organisation, in die eingeladen wird, existiert nicht.'
          )
        case ErrorType.Conflict:
          return setErrorMessage('Dieses Mitglied wurde bereits eingeladen.')
        default:
          setErrorMessage(
            'Ein unbekannter Fehler ist aufgetreten. Bitte versuche es erneut.'
          )
      }
    } finally {
      setIsInviting(false)
      organisationStore.loadOrganisationInformation()
    }
  }

  return (
    <Dialog
      isOpen={props.isOpen}
      title='Neues Mitglied einladen'
      initialFocusIndex={1}
      dismissable
      onDismiss={() => {
        props.setIsOpen(false)
        ;(ref as MutableRefObject<HTMLButtonElement>)?.current?.focus()
      }}
      actions={[
        {
          variant: 'primary',
          children: 'Einladung verschicken',
          onClick: () => void inviteMembers(membersToBeInvited),
          isLoading: isInviting,
        },
      ]}
    >
      <Flex direction='vertical'>
        {membersToBeInvited.map((member, index) => {
          const isEmailIncomplete = shouldValidate && member.email.trim() === ''
          return (
            <Flex key={index} verticalAlignment='center' gap='small'>
              <InputField
                type='email'
                placeholder='E-Mail-Adresse'
                value={member.email}
                onChange={(value) => {
                  const updatedMembersToBeInvited = [...membersToBeInvited]
                  updatedMembersToBeInvited[index].email = value
                  setMembersToBeInvited(updatedMembersToBeInvited)
                }}
                hasError={isEmailIncomplete}
                helperText={
                  isEmailIncomplete ? 'Bitte gib eine E-Mail ein.' : undefined
                }
              />
              <div style={{ width: '20rem' }}>
                <Select
                  placeholder='Level'
                  options={MEMBER_ACCESS_LEVELS}
                  value={MEMBER_ACCESS_LEVELS.find(
                    (option) => option.value === member.level
                  )}
                  onChange={(option) => {
                    const updatedMembersToBeInvited = [...membersToBeInvited]
                    updatedMembersToBeInvited[index].level =
                      option.value as Level
                    setMembersToBeInvited(updatedMembersToBeInvited)
                  }}
                />
              </div>
              {membersToBeInvited.length > 1 ? (
                <Button
                  iconOnly='delete'
                  variant='quaternary'
                  onClick={() => removeInviteMemberOption(index)}
                />
              ) : null}
            </Flex>
          )
        })}
      </Flex>
      <Flex horizontalAlignment='center'>
        <Button
          iconOnly='add'
          variant='quaternary'
          onClick={() => addInviteMemberOption()}
        />
      </Flex>
      {errorMessage && (
        <FeedbackBanner variant='error'>{errorMessage}</FeedbackBanner>
      )}
    </Dialog>
  )
})

export default observer(InviteMemberDialog)
