import { Button, Flex, Heading, Text } from '@nextbusiness/infinity-ui'
import { isLocalEnvironment, isStagingEnvironment } from '../../../model/Apps'
import MarketplaceApp from '../../marketplace/MarketplaceApp'
import AppLauncherHelpText from '../AppLauncherHelpText'
import AppLauncherUpgradeAction from '../AppLauncherUpgradeAction'

interface SubscriptionExpiredProps {
  applicationIdentifier: string
  appDetails: MarketplaceApp | undefined
  onLaunchAnyway?: () => void
}

const SubscriptionExpired = (props: SubscriptionExpiredProps) => (
  <Flex
    className='subscription-expired'
    horizontalAlignment='center'
    verticalAlignment='center'
    direction='vertical'
    gap='small'
    fillContainer
    style={{
      height: '100vh',
      backgroundColor: '#FCFDFE',
    }}
  >
    <img
      src={'/assets/apps/icons/' + props.appDetails?.icon}
      alt=''
      className='app-icon'
      width={48}
    />
    <Heading type='h3' bare>
      Aktiviere dein Abonnement, um {props.appDetails?.title ?? 'diese App'}{' '}
      weiterzunutzen
    </Heading>
    <Text className='explanation'>
      Die Testversion ist abgelaufen. Aktiviere dein Abonnement, um die App
      weiternutzen zu können.
    </Text>
    <AppLauncherUpgradeAction
      applicationIdentifier={props.applicationIdentifier}
      appDetails={props.appDetails}
      additionalActions={
        isLocalEnvironment || isStagingEnvironment ? (
          <Button onClick={() => props.onLaunchAnyway?.()}>
            Trotzdem öffnen (Entwickler)
          </Button>
        ) : null
      }
    />
    <AppLauncherHelpText>
      Noch Fragen zum Abonnement oder du benötigst Hilfe?
    </AppLauncherHelpText>
  </Flex>
)

export default SubscriptionExpired
