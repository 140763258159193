import { FeedbackBanner } from '@nextbusiness/infinity-ui'
import { Contact } from '../../../../model/Contact'

interface ContactCSVImportCountProps {
  contacts: Partial<Contact>[]
}

const ContactCSVImportCount = (props: ContactCSVImportCountProps) =>
  !props.contacts.length ? (
    <FeedbackBanner>
      Keine Kontakte gefunden. Stelle sicher, dass die Kontaktdatei im richtigen
      Format ist und aus einer unterstützten Software stammt.
    </FeedbackBanner>
  ) : (
    <FeedbackBanner>
      {props.contacts.length} Kontakt{props.contacts.length > 1 && 'e'}{' '}
      gefunden.
    </FeedbackBanner>
  )

export default ContactCSVImportCount
