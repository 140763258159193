import {
  Button,
  Checkbox,
  FeedbackBanner,
  Flex,
  InputField,
  Text,
} from '@nextbusiness/infinity-ui'
import { useEnter } from '../../../utility/hooks'
import { PASSWORD_REQUIREMENTS } from '../RegistrationPage'
import RegistrationStep from './RegistrationStep'

interface StepCredentialsProps {
  firstName?: string
  email: string
  password: string
  setEmail: (email: string) => void
  setPassword: (password: string) => void
  emailErrorMessage?: string
  passwordErrorMessage?: string
  readTOS: boolean
  setReadTOS: (readTOS: boolean) => void
  generalErrorMessage?: string
  onSubmit: () => void
  isLoading?: boolean
}

const StepCredentials = (props: StepCredentialsProps) => {
  const isPasswordLongEnough = props.password.length >= 10
  const isPasswordContainingUppercase = /[A-Z]/.test(props.password)
  const isPasswordContainingLowercase = /[a-z]/.test(props.password)
  const isPasswordContainingNumber = /\d/.test(props.password)
  const isPasswordValid =
    isPasswordLongEnough &&
    isPasswordContainingUppercase &&
    isPasswordContainingLowercase &&
    isPasswordContainingNumber

  useEnter(() => props.onSubmit())

  return (
    <RegistrationStep
      title={
        props.firstName ? `Willkommen, ${props.firstName}.` : 'Willkommen.'
      }
    >
      <Text>
        Mit welcher E-Mail-Adresse und Kennwort möchtest du dich anmelden?
      </Text>
      <Flex direction='vertical' fillContainer>
        <InputField
          placeholder='E-Mail-Adresse'
          type='email'
          helperText={props.emailErrorMessage}
          hasError={!!props.emailErrorMessage}
          value={props.email}
          onChange={props.setEmail}
          fullWidth
          icon='email'
        />
        <InputField
          placeholder='Kennwort'
          helperText={props.passwordErrorMessage}
          hasError={!!props.passwordErrorMessage}
          type='password'
          value={props.password}
          onChange={props.setPassword}
          fullWidth
          icon='access'
        />
      </Flex>
      {!isPasswordValid && (
        <FeedbackBanner
          variant={
            props.passwordErrorMessage === PASSWORD_REQUIREMENTS
              ? 'error'
              : 'info'
          }
        >
          {PASSWORD_REQUIREMENTS}
        </FeedbackBanner>
      )}
      <Checkbox
        markAsRequired={true}
        isChecked={props.readTOS}
        onChange={(isChecked) => props.setReadTOS(isChecked)}
        // Remove typecasting as soon as infinity ui is updated
        label={
          (
            <>
              Ich stimme den{` `}
              <a
                className='tos-link'
                target='_blank'
                rel='noopener noreferrer'
                href='https://www.infinity.swiss/terms-of-service'
              >
                Nutzungsbedingungen
              </a>
              {` `}
              zu.
            </>
          ) as unknown as string
        }
      />
      <div>
        {props.generalErrorMessage && (
          <FeedbackBanner variant='error'>
            {props.generalErrorMessage}
          </FeedbackBanner>
        )}
        <Button
          variant='primary'
          isLoading={props.isLoading}
          onClick={props.onSubmit}
        >
          Registrieren
        </Button>
      </div>
    </RegistrationStep>
  )
}

export default StepCredentials
