import { Dialog, InputField, Text } from '@nextbusiness/infinity-ui'
import { useState } from 'react'
import {
  PerceptionalDelays,
  perceptionalDelayWithState,
} from '../../utility/delays'
import { useEnter, useResetOnOpen } from '../../utility/hooks'
import { PaymentInterval } from './MarketplaceApp'
import MarketplaceAppOffers from './MarketplaceAppOffers'

interface MarketplaceAppOfferCodeModalProps {
  isOpen: boolean
  onDismiss: () => void
  onOfferCodeEntered: (
    offerCode: string,
    availableIntervals: PaymentInterval[]
  ) => void
  appIdentifier: string
}

const MarketplaceAppOfferCodeModal = (
  props: MarketplaceAppOfferCodeModalProps
) => {
  const [offerCode, setOfferCode] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [showInvalidOfferCodeError, setShowInvalidOfferCodeError] =
    useState<boolean>(false)

  const validateOfferCode = async () => {
    if (!offerCode) return
    const normalisedCode = offerCode.trim().toUpperCase()
    await perceptionalDelayWithState(setIsLoading, PerceptionalDelays.Lookup)
    const offers = MarketplaceAppOffers.findByCode(
      normalisedCode,
      props.appIdentifier
    )
    if (offers) {
      const availableIntervals = Array.from(
        new Set(
          offers
            .map((offer) => {
              const intervals: PaymentInterval[] = []
              if (offer.identifier) intervals.push('monthly')
              if (offer.yearlyIdentifier) intervals.push('yearly')
              return intervals
            })
            .flat()
        )
      )
      props.onOfferCodeEntered(normalisedCode, availableIntervals)
      props.onDismiss()
    } else {
      setShowInvalidOfferCodeError(true)
    }
  }

  useResetOnOpen(() => {
    setOfferCode('')
    setShowInvalidOfferCodeError(false)
  }, props.isOpen)

  useEnter(() => validateOfferCode())

  return (
    <Dialog
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      title='Angebotscode eingeben'
      icon='voucher'
      style={{ maxWidth: '54rem' }}
      actions={[
        {
          children: 'Abbrechen',
          onClick: () => props.onDismiss(),
        },
        {
          children: 'Code verwenden',
          disabled: offerCode.length === 0,
          variant: 'primary',
          onClick: () => validateOfferCode(),
          isLoading,
        },
      ]}
    >
      <Text style={{ marginTop: 0 }}>
        Wenn du einen Angebotscode hast, kannst du diesen hier eingeben, um ein
        spezifisches Angebot freizuschalten.
      </Text>
      <InputField
        value={offerCode}
        onChange={(value) => setOfferCode(value)}
        fullWidth
        label='Angebotscode'
        hasError={showInvalidOfferCodeError}
        helperText={
          showInvalidOfferCodeError
            ? 'Kein Angebot mit diesem Code gefunden. Möglicherweise ist er nicht mehr gültig oder enthält einen Tippfehler.'
            : undefined
        }
      />
    </Dialog>
  )
}

export default MarketplaceAppOfferCodeModal
