import { loadStripe, Stripe } from '@stripe/stripe-js'
import BillingCard from '../model/BillingCard'
import BillingDetails from '../model/BillingDetails'
import Invoice from '../model/Invoice'
import Organisation from '../model/Organisation'
import Subscription from '../model/Subscription'
import { CancellationReason } from '../pages/settings/billing/CancelSubscriptionModal'
import { currentToken } from './Authentication'
import { BackendError, ErrorType } from './Errors'
import { BASE_URL, DEFAULT_ERROR } from './networkingDefaults'

const STRIPE_PUBLISHABLE_KEY = import.meta.env.VITE_STRIPE_KEY
let stripe: Stripe | null

/**
 * Returns an existing stripe instance or initializes a new one if necessary
 */
const currentStripe = async () => {
  if (!stripe) {
    stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY)
  }
  return stripe!
}

/**
 * Creates a stripe checkout session that can be used to setup payments
 * or get current billing details
 * @param organisationIdentifier The session will be tied to this organisation
 * @throws {BackendError} Possibly Unauthorised
 * @throws {Error} Can throw a generic error with a message
 */
const createStripeCheckoutSession = async (
  organisationIdentifier: string,
  stripeCustomerId?: string,
  returnURL?: string
) => {
  const response = await fetch(
    BASE_URL + `/organisation/${organisationIdentifier}/billing/setup`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
      body: JSON.stringify({
        organisationId: organisationIdentifier,
        customerId: stripeCustomerId,
        returnURL,
      }),
    }
  )
  const body = await response.json()
  if (!response.ok || !body.sessionId) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }
  return body.sessionId as string
}

/**
 * Open stripe customer portal that allows updating payment details
 * @param organisationIdentifier The session will be tied to this organisation
 * @throws {BackendError} Possibly Unauthorised
 * @throws {Error} Can throw a generic error with a message
 */
const openCustomerPortal = async (
  organisationIdentifier: string,
  stripeCustomerId: string,
  replaceCurrentTab?: boolean
) => {
  const session = await createStripeCheckoutSession(
    organisationIdentifier,
    stripeCustomerId
  )
  const response = await fetch(
    BASE_URL + `/organisation/${organisationIdentifier}/billing/portal`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
      body: JSON.stringify({
        sessionId: session,
      }),
    }
  )
  const body = await response.json()
  if (!response.ok || !body.url) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }
  if (replaceCurrentTab) {
    window.location.href = body.url
  } else {
    window.open(body.url)
  }
}

/**
 * Subscribe to an application
 * @param organisationIdentifier The session will be tied to this organisation
 * @throws {BackendError} Possibly Unauthorised
 * @throws {Error} Can throw a generic error with a message
 */
const subscribeToApplication = async (
  priceId: string,
  applicationIdentifier: string,
  organisationIdentifier: string
) => {
  const response = await fetch(
    BASE_URL + `/organisation/${organisationIdentifier}/subscription`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
      body: JSON.stringify({
        priceId,
        applicationIdentifier,
        organisationId: organisationIdentifier,
      }),
    }
  )
  const body = await response.json()
  if (!response.ok) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }
}

/**
 * Redirects the user to the stripe billing page where they can setup their
 * payment information for the first time
 * @param organisationIdentifier The stripe customer will be tied to this organisation
 * @param applicationIdentifier Application that has triggered the setup process
 * @param checkoutSessionIdentifier If a checkout session already exists, it can be used instead of requesting a new one
 * @throws {BackendError} Possibly Unauthorised
 * @throws {Error} Can throw a generic error with a message
 */
const setupPaymentDetails = async (
  organisationIdentifier: string,
  applicationIdentifier?: string,
  stripeCustomerId?: string,
  checkoutSessionIdentifier?: string
) => {
  let stripe: Stripe
  let sessionId: string

  if (checkoutSessionIdentifier) {
    stripe = await currentStripe()
    sessionId = checkoutSessionIdentifier
  } else {
    const baseURL = `https://${document.location.hostname}`
    const returnURL = applicationIdentifier
      ? `${baseURL}/marketplace/apps/${applicationIdentifier}`
      : `${baseURL}/settings/billing`

    const [identifier, stripeInstance] = await Promise.all([
      createStripeCheckoutSession(
        organisationIdentifier,
        stripeCustomerId,
        returnURL
      ),
      currentStripe(),
    ])
    sessionId = identifier
    stripe = stripeInstance
  }

  await stripe.redirectToCheckout({
    sessionId,
  })
}

/**
 * Requests a trial for a given application
 * @param organisation Organisation for which the trial should be started
 * @param application Application from the discovery list to which the trial pertains to
 * @throws {BackendError} Possibly Unauthorised
 * @throws {Error} Can throw a generic error with a message
 */
const startTrialForApplication = async (
  organisation: Organisation,
  applicationIdentifier: string,
  priceId: string
): Promise<void> => {
  const response = await fetch(
    BASE_URL + `/organisation/${organisation.id}/trial`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
      body: JSON.stringify({
        organisationId: organisation.id,
        applicationIdentifier: applicationIdentifier,
        priceId,
      }),
    }
  )
  const body = await response.json()

  if (!response.ok) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }
}

/**
 * Requests a trial for a given application
 * @param subscription Subscription that should be cancelled
 * @param organisation Organisation for which the trial should be started
 * @throws {BackendError} Possibly Unauthorised
 * @throws {Error} Can throw a generic error with a message
 */
const cancelSubscription = async (
  subscription: Subscription,
  organisation: Organisation,
  reason: CancellationReason,
  explanation: string
): Promise<void> => {
  const response = await fetch(
    BASE_URL +
      `/organisation/${organisation.id}/subscription/${subscription.id}/cancel`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
      body: JSON.stringify({
        should_subscription_get_cancelled: true,
        reason,
        explanation,
      }),
    }
  )
  const body = await response.json()

  if (!response.ok) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }
}

/**
 * Returns the currently subscribed (or trialing) applications for a given organisation
 * @param organisationIdentifier Identifier of the organisation for which the apps should be returned
 * @throws {BackendError} Possibly Unauthorised
 * @throws {Error} Can throw a generic error with a message
 */
const subscriptions = async (
  organisationIdentifier: string
): Promise<Subscription[]> => {
  const response = await fetch(
    BASE_URL + `/organisation/${organisationIdentifier}/subscriptions`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    }
  )
  const body = await response.json()

  if (!response.ok || !body.subscriptions) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }

  return body.subscriptions as Subscription[]
}

/**
 * Returns the billing information for the current organisation
 * @param organisationIdentifier Identifier of the organisation for which
 * @throws {BackendError} Possibly Unauthorised
 * @throws {Error} Can throw a generic error with a message
 */
const currentBillingDetails = async (
  organisationIdentifier: string
): Promise<[BillingDetails, BillingCard]> => {
  const response = await fetch(
    BASE_URL + `/organisation/${organisationIdentifier}/billing`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    }
  )
  const body = await response.json()

  if (!response.ok || !body.customer) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }

  return [body.customer as BillingDetails, body.paymentDetails as BillingCard]
}

/**
 * Returns the billing information for the current organisation
 * @param invoiceIdentifier Identifier of the invoice as generated by striped (starts with "in_")
 * @param organisationIdentifier Identifier of the organisation for which
 * @throws {BackendError} Possibly Unauthorised
 * @throws {Error} Can throw a generic error with a message
 */
const invoiceWithIdentifier = async (
  invoiceIdentifier: string,
  organisationIdentifier: string
): Promise<Invoice> => {
  const response = await fetch(
    BASE_URL +
      `/organisation/${organisationIdentifier}/invoice/${invoiceIdentifier}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    }
  )
  const body = await response.json()

  if (!response.ok || !body.invoice) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }

  return body.invoice as Invoice
}

const Billing = {
  currentStripe,
  setupPaymentDetails,
  openCustomerPortal,
  subscribeToApplication,
  startTrialForApplication,
  createStripeCheckoutSession,
  currentBillingDetails,
  subscriptions,
  cancelSubscription,
  invoiceWithIdentifier,
}

export default Billing
