import { Avatar, Flex, Text } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useRootStore } from '../../stores/RootStoreContext'
import './DashboardTeamMembers.scss'
import DashboardTeamMembersLoading from './DashboardTeamMembersLoading'

const DashboardTeamMembers = () => {
  const { organisationStore } = useRootStore()

  const hasLoadedMembers =
    organisationStore.hasLoadedMembers && organisationStore.members?.length
  const acceptedMembers =
    organisationStore.members?.filter(
      (member) => member.invitationStatus === 'accepted'
    ) ?? []

  return (
    <>
      <div className='dashboard-team'>
        {!hasLoadedMembers ? (
          <DashboardTeamMembersLoading />
        ) : (
          <>
            {(acceptedMembers.length > 5
              ? acceptedMembers.slice(0, 4)
              : acceptedMembers
            ).map((member) => (
              <MemberCard
                key={member.id}
                fullName={member.firstname + ' ' + member.lastname}
              />
            ))}
            {acceptedMembers.length > 5 && (
              <MemberCard
                fullName={acceptedMembers.length - 4 + ' weitere'}
                avatarText={'+' + (acceptedMembers.length - 4)}
                avatarClassName='others'
              />
            )}
          </>
        )}
      </div>
    </>
  )
}

interface MemberCardProps {
  fullName: string
  avatarText?: string
  avatarClassName?: string
}

const MemberCard = (props: MemberCardProps) => (
  <Flex
    direction='horizontal'
    gap='tiny'
    verticalAlignment='center'
    className='member-card'
  >
    <Avatar
      name={props.avatarText ?? props.fullName}
      className={props.avatarClassName}
    />
    <Text type='inline' className='member-name'>
      {props.fullName}
    </Text>
  </Flex>
)

export default observer(DashboardTeamMembers)
