import {
  Button,
  Card,
  Dialog,
  Flex,
  Heading,
  LoadingIndicator,
  Spacer,
  Text,
} from '@nextbusiness/infinity-ui'
import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import { observer } from 'mobx-react'
import { useState } from 'react'
import MasterCardIcon from '../../../assets/billing-card-mastercard.svg'
import OtherCardIcon from '../../../assets/billing-card-other.svg'
import VisaIcon from '../../../assets/billing-card-visa.svg'
import Billing from '../../../networking/Billing'
import { useRootStore } from '../../../stores/RootStoreContext'

const iconForCreditCardBrand = (brand?: string) => {
  switch (brand) {
    case 'visa':
      return VisaIcon
    case 'mastercard':
      return MasterCardIcon
    default:
      return OtherCardIcon
  }
}

const PaymentDetailsCard = () => {
  const { organisationStore, billingStore } = useRootStore()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  if (!billingStore.details) return null

  const openBillingPortal = async () => {
    setIsLoading(true)
    await Billing.openCustomerPortal(
      organisationStore.currentIdentifier!,
      organisationStore.currentOrganisation!.stripeCustomerId!
    )
    setIsLoading(false)
  }

  return (
    <>
      <Heading type='h3' bareTop>
        Zahlungsmethode
      </Heading>
      <Flex verticalAlignment='center' gap='small'>
        <Icon icon='identity' size={IconSize.Small} />
        <Flex direction='vertical'>
          <Text type='inline'>{billingStore.details.name}</Text>
          <Text type='inline'>{billingStore.details.email}</Text>
        </Flex>
      </Flex>
      <Spacer size='small' />
      {billingStore.card && (
        <CreditCardVisualisation card={billingStore.card} />
      )}
      <Spacer size='small' />
      <Button
        iconLeft='edit'
        variant='primary'
        onClick={() => {
          openBillingPortal()
        }}
      >
        Zahlungsdetails bearbeiten
      </Button>
      <Dialog isOpen={isLoading}>
        <LoadingIndicator loadingText='Einen Moment bitte.' />
      </Dialog>
    </>
  )
}

interface CreditCardVisualisationProps {
  card: {
    lastFourDigits: string
    brand: string
    expirationMonth: number
    expirationYear: number
  }
}

const CreditCardVisualisation = ({ card }: CreditCardVisualisationProps) => (
  <Card elevation='none' className='billing-credit-card'>
    <Spacer size='regular' />
    <Text type='paragraph' className='billing-card-digits'>
      •••• •••• •••• {card.lastFourDigits}
    </Text>
    <Spacer size='small' />
    <Flex verticalAlignment='center' gap='small'>
      <img src={iconForCreditCardBrand(card.brand)} alt='' />
      <div style={{ flexGrow: 1 }} />
      <Text type='inline'>
        {card.expirationMonth} / {card.expirationYear}
      </Text>
    </Flex>
  </Card>
)

export default observer(PaymentDetailsCard)
