import { Button, InputField } from '@nextbusiness/infinity-ui'
import { useCallback, useState } from 'react'
import { useEnter } from '../../../utility/hooks'
import MarketplaceApp from '../../marketplace/MarketplaceApp'
import RegistrationStep from '../registration/RegistrationStep'
import SignupStepper, { SignupStep } from './SignupStepper'
import PasswordRequirementCheck from './PasswordRequirementCheck'

interface AppSignupStepPasswordProps {
  password: string
  setPassword: (password: string) => void

  app: MarketplaceApp
  onComplete: () => void
  isLoading: boolean
}

const AppSignupStepPassword = (props: AppSignupStepPasswordProps) => {
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)

  const isPasswordLongEnough = props.password.length >= 10
  const isPasswordContainingUppercase = /[A-Z]/.test(props.password)
  const isPasswordContainingLowercase = /[a-z]/.test(props.password)
  const isPasswordContainingNumber = /\d/.test(props.password)
  const isPasswordValid =
    isPasswordLongEnough &&
    isPasswordContainingUppercase &&
    isPasswordContainingLowercase &&
    isPasswordContainingNumber

  const hasErrors = !isPasswordValid

  const onSubmit = useCallback(() => {
    setShouldValidate(true)
    if (!hasErrors) props.onComplete()
  }, [props.onComplete, setShouldValidate, hasErrors])

  useEnter(() => onSubmit())

  return (
    <RegistrationStep
      stepper={<SignupStepper currentStep={SignupStep.Password} />}
      contentClassName='signup-step-content'
    >
      <p className='instructions'>
        {props.app.signupFlowAppearance?.customSecurityPrompt ??
          'Damit deine Unternehmensdaten gut geschützt sind, bitten wir dich, ein sicheres Kennwort für dein Infinity-Login zu wählen.'}
      </p>
      <div className='signup-fields'>
        <div className='signup-field'>
          <label>Wähle ein sicheres Kennwort</label>
          <InputField
            value={props.password}
            onChange={props.setPassword}
            placeholder='Kennwort eingeben'
            type='password'
            icon='access'
            fullWidth
            hasError={shouldValidate && !isPasswordValid}
            helperText={
              shouldValidate && !isPasswordValid
                ? 'Dein Kennwort erfüllt die Anforderungen noch nicht.'
                : ''
            }
          />
        </div>
        <div className='password-requirements'>
          <PasswordRequirementCheck
            fulfilled={isPasswordLongEnough}
            label='Mindestens 10 Zeichen'
          />
          <PasswordRequirementCheck
            fulfilled={
              isPasswordContainingUppercase && isPasswordContainingLowercase
            }
            label='Mindestens 1 Gross- und Kleinbuchstabe'
          />
          <PasswordRequirementCheck
            fulfilled={isPasswordContainingNumber}
            label='Mindestens eine Zahl'
          />
        </div>
      </div>
      <Button variant='primary' onClick={onSubmit} isLoading={props.isLoading}>
        Konto erstellen
      </Button>
      <div className='tos-note'>
        <small>
          Wenn du auf “Konto erstellen“ klickst, stimmst du unseren{' '}
          <a
            href='https://www.infinity.swiss/terms-of-service'
            target='_blank'
            rel='noreferrer noopener'
            style={{ color: props.app.keyColor }}
          >
            Nutzungsbedingungen
          </a>{' '}
          zu.
        </small>
      </div>
    </RegistrationStep>
  )
}

export default AppSignupStepPassword
