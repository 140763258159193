import { showProductionApps } from '../../../model/Apps'
import MarketplaceApp from '../MarketplaceApp'

const InfinityFinancePrice = showProductionApps
  ? 'price_1LyZrhJbwTSfij8e91czc2Bn'
  : 'price_1LyZpnJbwTSfij8e1lBUlW8i'
const InfinityFinanceProPrice = showProductionApps
  ? 'price_1LyZsZJbwTSfij8eLB0CShUG'
  : 'price_1LyZq0JbwTSfij8ewib78YaF'

const InfinityFinanceYearlyPrice = showProductionApps
  ? 'price_1NWLc3JbwTSfij8ekhnwqqXo'
  : 'price_1NWLeGJbwTSfij8e3BECtdsB'
const InfinityFinanceYearlyProPrice = showProductionApps
  ? 'price_1NWLd0JbwTSfij8eXPar4Gv6'
  : 'price_1NWLeiJbwTSfij8egHIMrWIk'

const InfinityFinance: MarketplaceApp = {
  title: 'Infinity Finance',
  launcherTitle: 'Finance',
  icon: 'app-icon-finance.png',
  subtitle: 'Buchhaltung für Normalsterbliche',
  developer: 'NextBusiness',
  identifier: 'ch.nextbusiness.infinity.finance',
  pricingPageUrl: 'https://www.infinity.swiss/finance/preise',
  mobileAppLinkingScheme: 'infinity-finance',
  subscriptionPriceId: InfinityFinancePrice,
  keyColor: '#098C70',
  primaryTrustedDomain: showProductionApps
    ? 'https://finance.infinity.swiss'
    : 'https://finance.infinity.nextbusiness.ch',
  screenshotURLs: [
    'finance-1.png',
    'finance-2.png',
    'finance-3.png',
    'finance-4.png',
  ],
  shortAppDescription:
    'Die moderne, einsteigerfreundliche Schweizer Buchhaltung – gemacht für Startups, Selbstständige und Kleinunternehmer:innen.',
  launcherAppearance: {
    gradientStartColor: '#186448',
    gradientEndColor: '#00cb78',
  },
  signupFlowAppearance: {
    backdropImage: 'signup-backdrop-finance.png',
    customSecurityPrompt:
      'Damit deine Finanzdaten auch gut geschützt sind, bitten wir dich, ein sicheres Kennwort für dein Infinity-Login zu wählen.',
    defaultTrialDays: 14,
    tint: 'teal',
  },
  pricing: {
    kind: 'tiered',
    tiers: [
      {
        identifier: InfinityFinancePrice,
        yearlyIdentifier: InfinityFinanceYearlyPrice,
        monthlyPrice: 25,
        yearlyPricePerMonth: 20,
        title: 'Finance Start',
        description: 'Ideal für Solo-Founder und Freelancer',
        features: [
          '2 Benutzer inbegriffen',
          'Unlimitiertes Buchen',
          'Nur Saldosteuersatz',
          'QR-Rechnungen',
        ],
        offers: [
          {
            code: 'MACHDISDING',
            identifier: 'price_1M7ecGJbwTSfij8e8x4KEnLi',
            monthlyPrice: 19,
          },
        ],
      },
      {
        identifier: InfinityFinanceProPrice,
        yearlyIdentifier: InfinityFinanceYearlyProPrice,
        monthlyPrice: 40,
        yearlyPricePerMonth: 32,
        title: 'Finance Pro',
        description: 'Für wachsende Startups und Kleinunternehmen',
        features: [
          '2 Benutzer inbegriffen',
          'Unlimitiertes Buchen',
          'Vereinfachte + effektive MWST',
          'QR-Rechnungen',
          'Produkte',
          'Erweiterte Auswertungen',
          'Erweiterte Filteroptionen',
        ],
        offers: [
          {
            code: 'STARTUPNIGHTS',
            identifier: 'price_1M2tYxJbwTSfij8e8gyxJbge',
            yearlyIdentifier: 'price_1NWLh5JbwTSfij8e5BFl0vSn',
            monthlyPrice: 29,
            yearlyPricePerMonth: 29,
          },
          {
            code: 'JULI40',
            identifier: 'price_1PfKs7JbwTSfij8eOy5HS0Ny',
            yearlyIdentifier: 'price_1PfKscJbwTSfij8eqcpgbha7',
            monthlyPrice: 24,
            yearlyPricePerMonth: 19.2,
          },
          {
            code: 'MAKER2023',
            identifier: 'price_1O8VdXJbwTSfij8eIblhfbp0',
            monthlyPrice: 32,
          },
          {
            code: 'NEUJAHR2024',
            yearlyIdentifier: 'price_1OT4h4JbwTSfij8exQ3E72yl',
            yearlyPricePerMonth: 28,
            description: '30% Rabatt für Finance Pro bei einem Jahresabo',
          },
        ],
      },
    ],
    numberOfTrialDays: 14,
  },
  showOffersOption: true,
}

export default InfinityFinance
