import { Button, Flex, Text } from '@nextbusiness/infinity-ui'
import { Icon } from '@nextbusiness/infinity-ui-icons'
import { observer } from 'mobx-react'
import Subscription from '../../../model/Subscription'
import { useRootStore } from '../../../stores/RootStoreContext'
import MarketplaceApp from '../MarketplaceApp'

export const remainingTrialDays = (subscription: Subscription) => {
  const expiration = new Date(subscription.billingCycleEnd)
  const now = new Date()
  const remaining = expiration.getTime() - now.getTime()

  return Math.ceil(remaining / (1000 * 3600 * 24))
}

interface AppStatusAreaTrialingProps {
  app: MarketplaceApp
  subscription: Subscription
  upgrade: () => void
  setupPayments: () => void
}

const AppStatusAreaTrialing = (props: AppStatusAreaTrialingProps) => {
  const { organisationStore, billingStore } = useRootStore()
  const hasProvidedPaymentDetails =
    organisationStore.currentOrganisation?.stripeCustomerId !== undefined &&
    billingStore.details?.invoice_settings.default_payment_method != null

  return (
    <Flex verticalAlignment='center' direction='horizontal-reflow' gap='small'>
      <Flex gap='tiny' verticalAlignment='center' style={{ flexGrow: 1 }}>
        {remainingTrialDays(props.subscription) > 0 ? (
          <>
            <Icon icon='waiting' size={20} />
            <Flex direction='vertical'>
              <Text type='inline'>Testversion aktiv</Text>
              <Text type='inline' variant='subtle'>
                Noch {remainingTrialDays(props.subscription)} Tage
              </Text>
            </Flex>
          </>
        ) : (
          <>
            <Icon icon='error' size={20} />
            <Flex direction='vertical'>
              <Text type='inline'>Testversion abgelaufen</Text>
            </Flex>
          </>
        )}
      </Flex>
      {hasProvidedPaymentDetails || props.app.pricing.kind === 'tiered' ? (
        <Button variant='primary' onClick={props.upgrade}>
          {props.app.pricing.kind === 'free'
            ? 'Kostenfreies Upgrade'
            : props.app.pricing.kind === 'tiered'
            ? 'Paket auswählen'
            : 'Für ' + props.app.pricing.monthlyPrice + ' Fr./Mo abonnieren'}
        </Button>
      ) : (
        <Button variant='primary' onClick={props.setupPayments}>
          Zahlungsdetails hinzufügen
        </Button>
      )}
    </Flex>
  )
}

export default observer(AppStatusAreaTrialing)
