import { Dialog, LoadingIndicator } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { isPermission } from '../../model/App'
import Apps from '../../networking/Apps'
import { ErrorType } from '../../networking/Errors'
import { useRootStore } from '../../stores/RootStoreContext'
import { useQuery } from '../../utility/hooks'
import MarketplaceApps from '../marketplace/MarketplaceApps'
import AskForPermissionDialog from './AskForPermissionDialog'
import './AskForPermissionPage.scss'
import SystemPage from './SystemPage'

const AskForPermissionPage = () => {
  const query = useQuery()

  const [isOpen, setIsOpen] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>()

  const { organisationStore } = useRootStore()

  const applicationIdentifier = query.get('app')
  const organisationIdentifier = query.get('organisation')
  const permissions = query.get('permissions')

  const [isTrustedContext, setIsTrustedContext] = useState<boolean>(false)

  const app = MarketplaceApps.find(
    (app) => app.identifier === applicationIdentifier
  )

  const messageListener = (event: MessageEvent<any>) => {
    try {
      const data = JSON.parse(event.data)
      if (
        data.type === 'infinity.request.start' &&
        event.origin === app?.primaryTrustedDomain
      )
        setIsTrustedContext(true)
    } catch (error) {
      // Ignore garbled messages
    }
  }

  useEffect(() => {
    window.addEventListener('message', messageListener, false)
    return () => window.removeEventListener('message', messageListener, false)
  })

  useEffect(() => {
    if (organisationIdentifier) {
      organisationStore.selectOrganisation(organisationIdentifier)

      if (app?.primaryTrustedDomain)
        window.top?.postMessage(
          JSON.stringify({
            type: 'infinity.request.ready',
          }),
          app.primaryTrustedDomain
        )
    }
  }, [organisationIdentifier])

  if (!applicationIdentifier || !permissions) return null

  const permissionList = permissions
    .split(',')
    .flatMap((permission) => (isPermission(permission) ? permission : []))

  const grantPermissions = async () => {
    if (isLoading || !organisationIdentifier) return

    setIsLoading(true)
    try {
      await Promise.all(
        permissionList.map((permission) =>
          Apps.setPermission(
            organisationStore.currentIdentifier!,
            applicationIdentifier,
            permission,
            'allow'
          )
        )
      )
      const [updatedAppToken] = await Apps.authenticateApp(
        organisationIdentifier,
        applicationIdentifier
      )
      if (isTrustedContext && app?.primaryTrustedDomain) {
        window.top?.postMessage(
          JSON.stringify({
            type: 'infinity.response.trusted.success',
            permissions: permissionList,
            shouldDismissFrame: true,
            token: updatedAppToken,
          }),
          app.primaryTrustedDomain
        )
      } else {
        window.top?.postMessage(
          JSON.stringify({
            type: 'infinity.response.success',
            permissions: permissionList,
            shouldDismissFrame: true,
          }),
          '*'
        )
      }
      setIsOpen(false)
    } catch (error: any) {
      switch (error.type) {
        case ErrorType.NotFound:
          return error.additionalData === 'organisation'
            ? setErrorMessage('Die Organisation konnte nicht gefunden werden')
            : setErrorMessage('Die Applikation konnte nicht gefunden werden')
        case ErrorType.Unauthorised:
          return setErrorMessage(
            'Du benötigst Administratorrechte, um diese Berechtigung zu erteilen'
          )
        default:
          setErrorMessage(
            error.message ??
              'Die Zugriffsrechte konnten nicht vollständig erteilt werden. Versuche es erneut.'
          )
      }
    } finally {
      setIsLoading(false)
    }
  }

  const denyPermissions = () => {
    setIsOpen(false)
    window.top?.postMessage(
      JSON.stringify({
        type: 'infinity.response.cancel',
        shouldDismissFrame: true,
      }),
      '*'
    )
  }

  return (
    <SystemPage>
      <Helmet>
        <title>Zugriffsrechte angefragt - Infinity</title>
      </Helmet>
      {organisationStore.currentOrganisation !== null && (
        <AskForPermissionDialog
          isOpen={isOpen}
          onDeny={() => denyPermissions()}
          onConfirm={() => void grantPermissions()}
          errorMessage={errorMessage}
          permissions={permissionList}
          appName={app?.title ?? applicationIdentifier}
          organisationName={
            organisationStore.currentOrganisation?.name ?? 'Organisation'
          }
          userHasSufficientPrivileges={
            organisationStore.currentUserLevel === 'admin'
          }
        />
      )}
      <Dialog isOpen={isLoading || !organisationStore.currentOrganisation}>
        <LoadingIndicator loadingText='Einen Moment bitte.' />
      </Dialog>
    </SystemPage>
  )
}

export default observer(AskForPermissionPage)
