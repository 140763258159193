import { Button, Text } from '@nextbusiness/infinity-ui'
import { useHistory } from 'react-router-dom'
import InfinityFinanceLogo from '../../../assets/infinity-finance-logo.png'
import './AppSetupWidget.scss'
import Widget from './Widget'

interface AppSetupWidgetProps {}

const AppSetupWidget = (props: AppSetupWidgetProps) => {
  const history = useHistory()

  return (
    <Widget
      title='Apps einrichten'
      identifier='noApps'
      icon='apps'
      onClick={() => {
        history.push('/marketplace')
      }}
    >
      <div className='app-setup-widget-information'>
        <img src={InfinityFinanceLogo} alt='Infinity Finance' height={30} />
        <Text>
          Infinity Finance – die Buchhaltung für Normalsterbliche. Aktiviere die
          Finance App, um diese Funktion zu verwenden.
        </Text>
        <div className='view-in-marketplace'>
          <Button
            variant='primary'
            onClick={() => {
              history.push('/marketplace/apps/ch.nextbusiness.infinity.finance')
            }}
          >
            Infinity Finance aktivieren
          </Button>
        </div>
      </div>
    </Widget>
  )
}

export default AppSetupWidget
