import { Button, Flex, Spacer } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useRootStore } from '../../../stores/RootStoreContext'
import ResetPasswordModal from '../ResetPasswordModal'
import LoginUsingRecoveryCodeModal from './LoginUsingRecoveryCodeModal'

interface LoginActionsProps {
  email: string
  password: string
  isLoggingIn: boolean
  isLoginRemembered: boolean
  isTwoFactorRequired: boolean
  onLoginClicked: () => void
}

const LoginActions = (props: LoginActionsProps) => {
  const { authenticationStore } = useRootStore()
  const history = useHistory()

  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] =
    useState<boolean>(false)
  const [isRecoveryCodeModalOpen, setIsRecoveryCodeModalOpen] =
    useState<boolean>(false)

  if (props.isTwoFactorRequired)
    return (
      <>
        <Spacer size='regular' />
        <Flex horizontalAlignment='space-between'>
          <Button
            isLoading={props.isLoggingIn}
            variant='primary'
            onClick={() => props.onLoginClicked()}
          >
            Bestätigen
          </Button>
        </Flex>
        <Spacer size='regular' />
        <Flex style={{ marginLeft: '-1.2rem' }}>
          <Button
            variant='quaternary'
            iconLeft='forgot-password'
            onClick={() => setIsRecoveryCodeModalOpen(true)}
          >
            Mit Recovery Code anmelden
          </Button>
          <LoginUsingRecoveryCodeModal
            email={props.email}
            password={props.password}
            isOpen={isRecoveryCodeModalOpen}
            onClose={() => setIsRecoveryCodeModalOpen(false)}
          />
        </Flex>
      </>
    )

  return (
    <>
      <Spacer size='regular' />
      <Flex horizontalAlignment='space-between'>
        <Button
          id='login-button'
          isLoading={props.isLoggingIn}
          variant='primary'
          onClick={() => props.onLoginClicked()}
        >
          Anmelden
        </Button>
        {props.isLoginRemembered ? (
          <Button onClick={() => authenticationStore.forgetLogin()}>
            Anderes Konto
          </Button>
        ) : (
          <Button onClick={() => history.push('/register')}>
            Konto erstellen
          </Button>
        )}
      </Flex>
      <Spacer size='regular' />
      <Flex style={{ marginLeft: '-1.2rem' }}>
        <Button
          variant='quaternary'
          iconLeft='help'
          onClick={() => setIsResetPasswordModalOpen(true)}
        >
          Kennwort vergessen
        </Button>
        <ResetPasswordModal
          isOpen={isResetPasswordModalOpen}
          closeModal={setIsResetPasswordModalOpen}
        />
      </Flex>
    </>
  )
}

export default observer(LoginActions)
