import { Heading, Text } from '@nextbusiness/infinity-ui'
import AuthCodeInput from '../../../components/input/AuthCodeInput'

interface LoginTwoFactorPromptProps {
  setTwoFactorCode: (code: string) => void
  isTwoFactorCodeIncorrect: boolean
  isTwoFactorCodeExpired: boolean
}

const LoginTwoFactorPrompt = (props: LoginTwoFactorPromptProps) => (
  <>
    <Heading type='h1' bareTop>
      Zwei-Faktor-Authentifizierung
    </Heading>
    <Text>
      Bitte gib den aktuellen Bestätigungscode deiner Zwei-Faktor-App ein.
    </Text>
    <AuthCodeInput
      onChange={props.setTwoFactorCode}
      autoFocus
      hasError={props.isTwoFactorCodeIncorrect || props.isTwoFactorCodeExpired}
      helperText={
        props.isTwoFactorCodeIncorrect
          ? 'Bestätigungscode ist falsch.'
          : props.isTwoFactorCodeExpired
          ? 'Bestätigungscode ist abgelaufen, versuche es mit einem neuen.'
          : undefined
      }
    />
  </>
)

export default LoginTwoFactorPrompt
