import {
  Button,
  Dialog,
  FeedbackBanner,
  Flex,
  Heading,
  LoadingIndicator,
  NonIdealState,
  Spacer,
  Text,
} from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useMemo, useState } from 'react'
import { Transaction } from '../../../model/Subscription'
import Billing from '../../../networking/Billing'
import { useRootStore } from '../../../stores/RootStoreContext'
import { centsToCHF } from '../../../utility/currency'

const PaymentTransactions = () => {
  const { organisationStore, billingStore } = useRootStore()
  const [errorMessage, setErrorMessage] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const openInvoice = async (invoiceId: string) => {
    try {
      setIsLoading(true)
      const invoice = await Billing.invoiceWithIdentifier(
        invoiceId,
        organisationStore.currentIdentifier!
      )
      window.open(invoice.hosted_invoice_url)
      setErrorMessage(undefined)
    } catch (error: any) {
      setErrorMessage(
        error.message ??
          'Ein unbekannter Fehler ist aufgetreten. Bitte später erneut versuchen.'
      )
    } finally {
      setIsLoading(false)
    }
  }

  const transactions = useMemo(
    () =>
      billingStore.subscriptions
        .flatMap((subscription) =>
          subscription.pastTransactions.map((transaction) => ({
            ...transaction,
            subscription,
          }))
        )
        .sort((transactionA, transactionB) => {
          const dateB = new Date(transactionB.date).getTime()
          const dateA = new Date(transactionA.date).getTime()

          return dateB - dateA
        }),
    [billingStore.subscriptions]
  )

  return (
    <>
      <Heading type='h3' style={{ margin: 0 }}>
        Vergangene Transaktionen
      </Heading>
      <Spacer size='small' />
      <Flex direction='vertical' gap='small'>
        {transactions.length === 0 && (
          <NonIdealState>Noch keine Transaktionen</NonIdealState>
        )}
        {transactions.map((transaction) => (
          <TransactionRow
            key={transaction._id}
            transaction={transaction}
            openInvoice={openInvoice}
          />
        ))}
      </Flex>
      {errorMessage && (
        <FeedbackBanner variant='error'>{errorMessage}</FeedbackBanner>
      )}
      <Dialog isOpen={isLoading}>
        <LoadingIndicator loadingText='Einen Moment bitte.' />
      </Dialog>
    </>
  )
}

interface TransactionRowProps {
  transaction: Transaction
  openInvoice: (invoiceId: string) => void
}

const TransactionRow = ({ transaction, openInvoice }: TransactionRowProps) => (
  <Flex gap='small' verticalAlignment='center'>
    <Text type='inline'>{new Date(transaction.date).toLocaleDateString()}</Text>
    <Text type='inline' style={{ flexGrow: 1 }}>
      Transaktion
    </Text>
    <Text type='inline' variant='subtle'>
      {centsToCHF(transaction.amount)} Fr.
    </Text>
    {transaction.stripeInvoiceId && (
      <Button
        variant='secondary'
        iconLeft='document'
        onClick={() => openInvoice(transaction.stripeInvoiceId!)}
      >
        Rechnung
      </Button>
    )}
  </Flex>
)

export default observer(PaymentTransactions)
