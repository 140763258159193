import { Dialog, FeedbackBanner } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import Organisation from '../../../../model/Organisation'
import Authentication from '../../../../networking/Authentication'
import { ErrorType } from '../../../../networking/Errors'
import { useRootStore } from '../../../../stores/RootStoreContext'

interface ConfirmDeleteAccountModalProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  setIsCanceled: (isCanceled: boolean) => void
}

const ConfirmDeleteAccountModal = (props: ConfirmDeleteAccountModalProps) => {
  const { authenticationStore } = useRootStore()

  const [errorMessage, setErrorMessage] = useState<string>()
  const history = useHistory()

  const deleteAccount = async () => {
    try {
      await Authentication.deleteUser()
      authenticationStore.logout()
      history.push('/goodbye')
      setErrorMessage(undefined)
    } catch (error: any) {
      switch (error.type) {
        case ErrorType.Unauthorised:
          return setErrorMessage(
            'Du wurdest entweder ausgeloggt oder dein Account wurde bereits gelöscht.'
          )
        case ErrorType.InvalidCredentials:
          const organisations = error.additionalData as Organisation[]
          const affectedOrganisations = organisations
            .map((organisation) => organisation.name)
            .join(', ')
          return setErrorMessage(
            `Der Account kann noch nicht gelöscht werden, da du die einzige Administrator:in in folgenden Organisationen bist: ${affectedOrganisations}. Nimm via Live Chat Kontakt mit uns auf, um diese Organisationen inkl. deren Geschäftsdaten unwiderruflich zu löschen. Auf help.infinity.swiss kannst du unten rechts im Messenger mit uns Kontakt aufnehmen.`
          )
        default:
          setErrorMessage(
            error.message ??
              'Ein unbekannter Fehler ist aufgetreten. Bitte versuche es erneut.'
          )
      }
    }
  }

  useEffect(() => {
    if (props.isOpen) {
      setErrorMessage(undefined)
    }
  }, [props.isOpen])

  return (
    <Dialog
      title='Bist du sicher?'
      icon='warning'
      initialFocusIndex={1}
      isOpen={props.isOpen}
      dismissable
      onDismiss={() => {
        props.setIsOpen(false)
        props.setIsCanceled(false)
      }}
      actions={[
        {
          children: 'Abbrechen',
          variant: 'tertiary',
          onClick: () => {
            props.setIsOpen(false)
            props.setIsCanceled(false)
          },
        },
        {
          children: 'Konto wirklich löschen',
          variant: 'destructive',
          onClick: () => {
            deleteAccount()
          },
        },
      ]}
    >
      {errorMessage && (
        <FeedbackBanner variant='error'>{errorMessage}</FeedbackBanner>
      )}
      Wenn du dein Konto löschst, werden alle deine Daten aus der Datenbank
      entfernt, und wirst dein Konto nicht mehr wiederherstellen können.
    </Dialog>
  )
}

export default observer(ConfirmDeleteAccountModal)
