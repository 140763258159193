import { Button, Flex } from '@nextbusiness/infinity-ui'
import MarketplaceApp from '../MarketplaceApp'

interface AppStatusAreaInactiveProps {
  app: MarketplaceApp
  startTrial: () => void
}

const AppStatusAreaInactive = (props: AppStatusAreaInactiveProps) => (
  <Flex direction='vertical' verticalAlignment='flex-end'>
    {props.app.subscriptionPriceId === '' ? (
      <Button onClick={props.startTrial} variant='primary' disabled>
        Aktuell nicht verfügbar
      </Button>
    ) : (
      <Button onClick={props.startTrial} variant='primary'>
        {props.app.pricing.kind === 'free'
          ? 'Kostenfrei installieren'
          : props.app.pricing.numberOfTrialDays + ' Tage kostenfrei testen'}
      </Button>
    )}
  </Flex>
)

export default AppStatusAreaInactive
