import { motion } from 'framer-motion'
import React from 'react'

const transitionSimple = {
  hidden: { y: 200, opacity: 0 },
  visible: { y: 0, opacity: 1 },
}

const DashboardTransition = (props: {
  children: React.ReactNode
  delay?: number
}) => (
  <motion.div
    initial='hidden'
    animate='visible'
    variants={transitionSimple}
    transition={{
      duration: 0.6,
      type: 'spring',
      stiffness: 55,
      mass: 0.08,
      delay: props.delay,
      delayChildren: 0.1,
      staggerChildren: 0.07,
    }}
  >
    {props.children}
  </motion.div>
)

export default DashboardTransition
