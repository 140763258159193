import { Contact } from '../../../../model/Contact'
import { ImportResult } from './ContactCSVImportResults'
import ContactCSVImportTableRow from './ContactCSVImportTableRow'

interface ContactCSVImportTableProps {
  contacts: Partial<Contact>[]
  results?: ImportResult[]
}

const ContactCSVImportTable = (props: ContactCSVImportTableProps) => {
  if (!props.contacts.length) return null
  return (
    <table>
      <thead>
        <td>Name/Firma</td>
        <td>Adresse</td>
        <td>PLZ & Ort</td>
        <td>Status</td>
      </thead>
      <tbody>
        {props.contacts.map((contact, index) => (
          <ContactCSVImportTableRow
            key={`${index}${contact.companyName ?? contact.lastname}`}
            contact={contact}
            result={props.results?.[index]}
          />
        ))}
      </tbody>
    </table>
  )
}

export default ContactCSVImportTable
