import {
  Dialog,
  LoadingIndicator,
  NonIdealState,
  Text,
} from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import Billing from '../../../networking/Billing'
import { useRootStore } from '../../../stores/RootStoreContext'

const PaymentSetupCard = () => {
  const { organisationStore } = useRootStore()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const setupPayment = async () => {
    if (!organisationStore.currentIdentifier) {
      return
    }
    setIsLoading(true)
    await Billing.setupPaymentDetails(
      organisationStore.currentIdentifier,
      undefined,
      organisationStore.currentOrganisation?.stripeCustomerId
    )
    setIsLoading(false)
  }

  return (
    <>
      <NonIdealState
        style={{ padding: '4rem 0' }}
        icon='payment'
        actions={[
          {
            children: 'Zahlungsmethode hinzufügen',
            onClick: () => setupPayment(),
            variant: 'primary',
          },
        ]}
      >
        <Text type='paragraph' style={{ maxWidth: '60rem' }}>
          Für diese Organisation ist noch keine Abrechnungsmethode eingerichtet.
          Füge eine Zahlungsmethode hinzu, um Infinity-Produkte zu abonnieren.
        </Text>
      </NonIdealState>
      <Dialog isOpen={isLoading}>
        <LoadingIndicator loadingText='Einen Moment bitte.' />
      </Dialog>
    </>
  )
}

export default observer(PaymentSetupCard)
