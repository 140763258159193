import { useNotificationCenter } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import Authentication from '../../../networking/Authentication'
import { ErrorType } from '../../../networking/Errors'
import { useRootStore } from '../../../stores/RootStoreContext'
import { useQuery } from '../../../utility/hooks'
import MarketplaceApps from '../../marketplace/MarketplaceApps'
import { decodeSource } from '../RegistrationPage'
import AppSignupFlowPage from './AppSignupFlowPage'
import AppSignupStepName from './AppSignupStepName'
import AppSignupStepPassword from './AppSignupStepPassword'
import { SignupStep } from './SignupStepper'

const extractAppIdFromParams = (params: { appId: string }) => {
  if (params.appId === 'finance') return 'ch.nextbusiness.infinity.finance'
  return params.appId
}

const AppRegistrationPage = () => {
  const { authenticationStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const history = useHistory()
  const query = useQuery()

  const [currentStep, setCurrentStep] = useState<SignupStep>(
    SignupStep.NameAndEmail
  )

  const source = query.get('from')

  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [emailErrorMessage, setEmailErrorMessage] = useState<string | null>(
    null
  )

  const params = useParams<{ appId: string }>()
  const appId = extractAppIdFromParams(params)
  const app = MarketplaceApps.find((app) => app.identifier === appId)!

  useEffect(() => {
    setEmailErrorMessage(null)
  }, [email])

  useEffect(() => {
    decodeSource(source)
  }, [source])

  if (!app) return <Redirect to='/register' />

  const registerUser = async () => {
    if (!firstName || !lastName || !email || !password) return
    try {
      setIsLoading(true)
      const [user, pollingId] = await Authentication.registerNewAccount({
        firstname: firstName,
        lastname: lastName,
        email,
        password,
        source: window.localStorage.getItem('signup-source'),
        flow: app.identifier,
      })
      authenticationStore.currentUser = user
      authenticationStore.signupIntent = {
        userId: user.id,
        verificationId: pollingId,
      }
      authenticationStore.loginEmail = email
      authenticationStore.loginName = user.firstname + ' ' + user.lastname
      history.push('/verification-prompt?from=app-signup')
    } catch (error: any) {
      setCurrentStep(SignupStep.NameAndEmail)
      switch (error.type) {
        case ErrorType.Conflict:
          return setEmailErrorMessage(
            'Diese E-Mail-Adresse wurde bereits registriert.'
          )
        case ErrorType.MissingRequiredFields:
          return notificationCenter.addNotification({
            children: 'Bitte fülle alle Pflichtfelder aus',
            variant: 'error',
          })
        default:
          return notificationCenter.addNotification({
            children:
              error.message ??
              'Ein technischer Fehler ist aufgetreten. Bitte versuche es erneut.',
            variant: 'error',
          })
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <AppSignupFlowPage app={app}>
      <Helmet>
        <title>
          {app.pricing.kind === 'free'
            ? `${app.title} einrichten - Infinity`
            : `${app.title} kostenlos testen - Infinity`}
        </title>
      </Helmet>
      {currentStep === SignupStep.NameAndEmail && (
        <AppSignupStepName
          app={app}
          firstName={firstName}
          lastName={lastName}
          email={email}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setEmail={setEmail}
          emailErrorMessage={emailErrorMessage}
          onContinue={() => setCurrentStep(SignupStep.Password)}
        />
      )}
      {currentStep === SignupStep.Password && (
        <AppSignupStepPassword
          app={app}
          password={password}
          setPassword={setPassword}
          isLoading={isLoading}
          onComplete={() => void registerUser()}
        />
      )}
    </AppSignupFlowPage>
  )
}

export default observer(AppRegistrationPage)
