import { Dialog, FeedbackBanner } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import React, { MutableRefObject, useState } from 'react'
import OrganisationMember from '../../../model/OrganisationMember'
import { ErrorType } from '../../../networking/Errors'
import Organisations from '../../../networking/Organisations'
import { useRootStore } from '../../../stores/RootStoreContext'

interface RemoveMemberDialogProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  member: OrganisationMember
  fullName: string
}

const RemoveMemberDialog = React.forwardRef<
  HTMLButtonElement,
  RemoveMemberDialogProps
>((props: RemoveMemberDialogProps, ref) => {
  const { organisationStore } = useRootStore()

  const [isRemoving, setIsRemoving] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  )

  const resetErrorMessages = () => {
    setErrorMessage(undefined)
  }

  const removeMember = async () => {
    if (!organisationStore.currentOrganisation || !props.member.id) return
    try {
      setIsRemoving(true)
      await Organisations.removeMemberFromOrganisation(
        organisationStore.currentOrganisation.id,
        props.member.id
      )
      organisationStore.loadOrganisationInformation()
      resetErrorMessages()
      props.setIsOpen(false)
    } catch (error: any) {
      resetErrorMessages()
      switch (error.type) {
        case ErrorType.Unauthorised:
          return setErrorMessage(
            'Nur Administrator:innen können Teammitglieder entfernen.'
          )
        case ErrorType.NotFound:
          if (error.message === 'This user is not a member')
            return setErrorMessage('Dieser Benutzer ist kein Mitglied.')
          else
            return setErrorMessage(
              'Die Organisation, aus der entfernt wird, existiert nicht.'
            )
        default:
          setErrorMessage(
            'Ein unbekannter Fehler ist aufgetreten. Bitte versuche es erneut.'
          )
      }
    } finally {
      setIsRemoving(false)
    }
  }

  return (
    <Dialog
      isOpen={props.isOpen}
      title='Teammitglied entfernen'
      initialFocusIndex={2}
      dismissable
      onDismiss={() => {
        props.setIsOpen(false)
        ;(ref as MutableRefObject<HTMLButtonElement>)?.current?.focus()
        resetErrorMessages()
      }}
      actions={[
        {
          children: 'Entfernen',
          variant: 'destructive',
          onClick: () => removeMember(),
          isLoading: isRemoving,
        },
        {
          children: 'Abbrechen',
          variant: 'tertiary',
          onClick: () => {
            props.setIsOpen(false)
            ;(ref as MutableRefObject<HTMLButtonElement>)?.current?.focus()
            resetErrorMessages()
          },
        },
      ]}
    >
      {props.member.invitationStatus === 'pending'
        ? `${props.fullName} wird die Einladung nicht mehr annehmen können.`
        : `${props.fullName} wird kein Zugriff mehr auf die Organisation und
      verbundene Apps haben.`}

      {errorMessage && (
        <FeedbackBanner variant='error'>{errorMessage}</FeedbackBanner>
      )}
    </Dialog>
  )
})

export default observer(RemoveMemberDialog)
