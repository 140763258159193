import React from 'react'
import { useLocation } from 'react-router-dom'

export const useQuery = () => new URLSearchParams(useLocation().search)

export const useAsyncEffect = (
  effect: (isMounted: () => boolean) => any | Promise<any>,
  destroy?: (result?: any) => void,
  dependencies?: React.DependencyList | undefined
) => {
  const hasDestroy = typeof destroy === 'function'

  React.useEffect(function () {
    var result: any
    var mounted = true
    var maybePromise = effect(function () {
      return mounted
    })

    Promise.resolve(maybePromise).then(function (value) {
      result = value
    })

    return function () {
      mounted = false

      if (hasDestroy && destroy) {
        destroy(result)
      }
    }
  }, dependencies)
}

export const useEnter = (callback: () => void) => {
  React.useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') callback()
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [callback])
}

export const useResetOnOpen = (reset: () => void, isOpen: boolean) => {
  React.useEffect(() => {
    if (isOpen) reset()
  }, [isOpen])
}
