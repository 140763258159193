import { AnimatedRouteProps, spring } from 'react-router-transition'

const mapStyles = (styles: any) => ({
  opacity: styles.opacity,
  transform: `scale(${styles.scale})`,
  transformOrigin: 'center center',
})

export const glide = (val: number) =>
  spring(val, {
    stiffness: 174,
    damping: 24,
  })

export const Dashboard: AnimatedRouteProps = {
  atEnter: { opacity: 0, scale: 1.25 },
  atActive: { opacity: 1, scale: 1 },
  atLeave: { opacity: 0 },
  runOnMount: true,
  mapStyles,
}

export const App: AnimatedRouteProps = {
  atEnter: { opacity: 0, scale: 0.8 },
  atActive: { opacity: 1, scale: 1 },
  atLeave: { opacity: 0, scale: 0.4 },
  mapStyles,
}

export const SettingsHome: AnimatedRouteProps = {
  atEnter: { opacity: 0, scale: 1.5 },
  atActive: {
    opacity: 1,
    scale: spring(1, {
      stiffness: 400,
      damping: 60,
    }),
  },
  atLeave: { opacity: 0 },
  mapStyles,
}

export const Subpage: AnimatedRouteProps = {
  atEnter: { opacity: 0, scale: 0.8 },
  atActive: { opacity: 1, scale: 1 },
  atLeave: { opacity: 0, scale: 1 },
  mapStyles,
}

const RouteTransitions = {
  Dashboard,
  App,
  SettingsHome,
  Subpage,
}

export default RouteTransitions
