import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useRootStore } from '../../stores/RootStoreContext'

const LogoutPage = () => {
  const { authenticationStore } = useRootStore()
  useEffect(() => {
    authenticationStore.logout()
    document.location.href = '/'
  })
  return (
    <div>
      <Helmet>
        <title>Abmelden - Infinity</title>
      </Helmet>
    </div>
  )
}

export default observer(LogoutPage)
