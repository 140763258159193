import React from 'react'
import { AnimatedSwitch } from 'react-router-transition'
import { glide } from '../../routes/RouteTransitions'

const ApplicationSubpageSwitch = (props: { children: React.ReactNode }) => (
  <AnimatedSwitch
    atEnter={{ opacity: 0, offset: 7.5 }}
    atActive={{ opacity: 1, offset: glide(0) }}
    atLeave={{}}
    runOnMount
    mapStyles={(styles) => ({
      transform: `translateY(${styles.offset}%)`,
      opacity: styles.opacity,
    })}
  >
    {props.children}
  </AnimatedSwitch>
)

export default ApplicationSubpageSwitch
