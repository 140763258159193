import {
  Button,
  Flex,
  InputField,
  Spacer,
  Text,
} from '@nextbusiness/infinity-ui'
import { useCallback, useState } from 'react'
import { useEnter } from '../../../utility/hooks'
import RegistrationStep from './RegistrationStep'

interface StepNameProps {
  firstName: string
  lastName: string
  setFirstName: (firstName: string) => void
  setLastName: (lastName: string) => void
  onContinue: () => void
}

const StepName = (props: StepNameProps) => {
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const hasErrors = !props.firstName || !props.lastName

  const onContinue = useCallback(() => {
    setShouldValidate(true)
    if (!hasErrors) props.onContinue()
  }, [props.onContinue, setShouldValidate, hasErrors])

  useEnter(() => onContinue())

  return (
    <RegistrationStep
      title={
        <>
          Hallo.
          <br />
          Wie heisst du?
        </>
      }
    >
      <Text>
        Es ist ganz einfach, loszulegen. Als erstes benötigen wir ein paar
        Angaben für dein neues Infinity-Login.
      </Text>
      <Flex gap='tiny' direction='horizontal-reflow' fillContainer>
        <InputField
          className='input-fields'
          placeholder='Vorname'
          value={props.firstName}
          onChange={props.setFirstName}
          fullWidth
          hasError={shouldValidate && !props.firstName}
          helperText={
            shouldValidate && !props.firstName ? 'Vorname ist erforderlich' : ''
          }
        />
        <InputField
          className='input-fields'
          placeholder='Nachname'
          value={props.lastName}
          onChange={props.setLastName}
          fullWidth
          hasError={shouldValidate && !props.lastName}
          helperText={
            shouldValidate && !props.lastName ? 'Nachname ist erforderlich' : ''
          }
        />
      </Flex>
      <Spacer size='small' />
      <Button variant='primary' onClick={onContinue}>
        Weiter
      </Button>
    </RegistrationStep>
  )
}

export default StepName
