import { Button, Flex, Text } from '@nextbusiness/infinity-ui'
import { Icon } from '@nextbusiness/infinity-ui-icons'
import { useHistory } from 'react-router-dom'
import MarketplaceApp from '../MarketplaceApp'

interface AppStatusAreaActiveProps {
  app: MarketplaceApp
}

const AppStatusAreaActive = (props: AppStatusAreaActiveProps) => {
  const history = useHistory()
  return (
    <Flex verticalAlignment='center' gap='regular'>
      <Flex gap='tiny' verticalAlignment='center' style={{ flexGrow: 1 }}>
        <Icon icon='check' size={20} />
        <Text type='inline'>Abonniert</Text>
      </Flex>
      <Flex gap='tiny'>
        <Button onClick={() => history.push('/settings/billing/')}>
          Verwalten
        </Button>
        <Button
          variant='primary'
          onClick={() => history.push('/app/' + props.app.identifier)}
        >
          App starten
        </Button>
      </Flex>
    </Flex>
  )
}

export default AppStatusAreaActive
