import { Contact } from '../model/Contact'
import { currentToken } from './Authentication'
import { BackendError, ErrorType } from './Errors'
import { BASE_URL } from './networkingDefaults'

const getAllContacts = async (
  organisationIdentifier: string
): Promise<Contact[]> => {
  const response = await fetch(
    BASE_URL + `/organisation/${organisationIdentifier}/datalake/contacts`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    }
  )
  const body = await response.json()

  if (!response.ok || !body.contacts) {
    switch (body.status) {
      case 401:
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new BackendError(ErrorType.ServerError, body?.message)
    }
  }
  return body.contacts as Contact[]
}

const getContact = async (
  organisationIdentifier: string,
  contactIdentifier: string
): Promise<Contact> => {
  const response = await fetch(
    BASE_URL +
      `/organisation/${organisationIdentifier}/datalake/contact/${contactIdentifier}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    }
  )
  const body = await response.json()

  if (!response.ok || !body.contacts || body.contacts.length < 1) {
    switch (body.message) {
      case 'Contact not found or insufficient access rights':
        throw new BackendError(ErrorType.NotFound, body.message)
      default:
        throw new BackendError(ErrorType.ServerError, body?.message)
    }
  }
  return body.contacts[0] as Contact
}

const createContact = async (
  organisationIdentifier: string,
  contact: Partial<Contact>
): Promise<Contact> => {
  const response = await fetch(
    BASE_URL + `/organisation/${organisationIdentifier}/datalake/contact`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
      body: JSON.stringify({ ...contact }),
    }
  )
  const body = await response.json()

  if (!response.ok || !body.contacts) {
    throw new BackendError(ErrorType.ServerError, body?.message)
  }
  return body.contacts[0] as Contact
}

const editContact = async (
  organisationIdentifier: string,
  contactIdentifier: string,
  changes: Partial<Contact>
): Promise<Contact> => {
  const response = await fetch(
    BASE_URL +
      `/organisation/${organisationIdentifier}/datalake/contact/${contactIdentifier}`,
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
      body: JSON.stringify(changes),
    }
  )
  const body = await response.json()

  if (!response.ok || !body.contacts) {
    switch (body.message) {
      case 'Contact not found or insufficient access rights':
        throw new BackendError(ErrorType.NotFound, body.message)
      default:
        throw new BackendError(ErrorType.ServerError, body?.message)
    }
  }
  return body.contacts[0] as Contact
}

const deleteContact = async (
  organisationIdentifier: string,
  contactIdentifier: string
): Promise<Contact> => {
  const response = await fetch(
    BASE_URL +
      `/organisation/${organisationIdentifier}/datalake/contact/${contactIdentifier}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    }
  )
  const body = await response.json()

  if (!response.ok || !body.contacts) {
    switch (body.message) {
      case 'Missing required permissions':
        throw new BackendError(ErrorType.InsufficientPermissions, body.message)
      case 'Contact not found':
        throw new BackendError(ErrorType.NotFound, body.message)
      default:
        throw new BackendError(ErrorType.ServerError, body?.message)
    }
  }
  return body.contacts[0] as Contact
}

const Contacts = {
  getAllContacts,
  getContact,
  createContact,
  editContact,
  deleteContact,
}

export default Contacts
