import { Avatar, Dialog, FeedbackBanner, Text } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { Permission } from '../../model/App'
import { useRootStore } from '../../stores/RootStoreContext'
import {
  displayNameForPermissions,
  longDisplayNameForPermission,
} from './PermissionDisplay'

interface AskForPermissionDialogProps {
  isOpen: boolean
  errorMessage?: string
  appName: string
  organisationName: string
  permissions: Permission[]
  onConfirm: () => void
  onDeny: () => void
  userHasSufficientPrivileges: boolean
}

const AskForPermissionDialog = (props: AskForPermissionDialogProps) => {
  const { organisationStore } = useRootStore()

  return (
    <Dialog
      title={`${props.appName} möchte auf ${displayNameForPermissions(
        props.permissions
      )} von ${props.organisationName} zugreifen`}
      appearance='system'
      className='ask-for-permission-dialog'
      initialFocusIndex={1}
      isOpen={props.isOpen}
      actions={
        props.userHasSufficientPrivileges
          ? [
              {
                children: 'Ablehnen',
                variant: 'tertiary',
                onClick: () => props.onDeny(),
              },
              {
                children: 'Zugriff erlauben',
                variant: 'secondary',
                onClick: () => props.onConfirm(),
              },
            ]
          : [
              {
                children: 'Zurück zur App',
                variant: 'primary',
                onClick: () => props.onDeny(),
              },
            ]
      }
    >
      <Text type='paragraph'>
        Wenn du der App diesen Zugriff erlaubst, kann sie Folgendes tun:
      </Text>
      <ul className='permission-list'>
        {props.permissions.map((permission) => (
          <li className='permission-list-item' key={permission}>
            {longDisplayNameForPermission(permission)}
          </li>
        ))}
      </ul>
      {props.userHasSufficientPrivileges ? (
        <FeedbackBanner variant='info'>
          Diese Berechtigung gilt für die gesamte Organisation{' '}
          {props.organisationName}.
        </FeedbackBanner>
      ) : (
        <>
          <Text type='paragraph' variant='help'>
            Du kannst diese Berechtigung nicht erteilen, da du in der
            Organisation {props.organisationName} keine Administrator:in bist.
          </Text>
          <Text type='paragraph'>
            Bitte eine Administrator:in in deinem Team, diese Berechtigung zu
            erteilen:
          </Text>
          {organisationStore.members
            ?.filter((member) => member.level === 'admin')
            .map((member) => (
              <Avatar
                key={member.id}
                name={organisationStore.fullNameOfMember(member.id!)}
              />
            ))}
        </>
      )}
      {props.errorMessage && (
        <FeedbackBanner variant='error'>{props.errorMessage}</FeedbackBanner>
      )}
    </Dialog>
  )
}

export default observer(AskForPermissionDialog)
