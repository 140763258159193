import Organisation, { OrganisationAppSettings } from '../model/Organisation'
import OrganisationMember from '../model/OrganisationMember'
import { currentToken } from './Authentication'
import { BackendError, ErrorType } from './Errors'
import { BASE_URL, DEFAULT_ERROR } from './networkingDefaults'

/**
 * Attempts to create a new organisation using the provided organisation details
 * @param organisation Payload containing new organisation information such as organisation name and industry
 * @throws {BackendError} Possibly Unauthorised or MissingRequiredFields
 * @throws {Error} Can throw a generic error with a message
 */
const createNewOrganisation = async (organisation: {
  organisation_name: string
  industry: string
}): Promise<Organisation> => {
  const response = await fetch(BASE_URL + '/organisation', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${currentToken}`,
    },
    body: JSON.stringify({ ...organisation }),
  })
  const body = await response.json()

  if (!response.ok || !body.organisation) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      case 'Missing required fields':
        throw new BackendError(ErrorType.MissingRequiredFields, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }
  return body.organisation as Organisation
}

/**
 * Returns the organisation details for a given organisation
 * @param organisationIdentifier Identifier for which the informations should be returned
 */
const organisationInformation = async (
  organisationIdentifier: string
): Promise<Organisation> => {
  const response = await fetch(
    BASE_URL + '/organisation/' + organisationIdentifier,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    }
  )
  const body = await response.json()

  if (!response.ok || !body.organisation) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }
  return body.organisation as Organisation
}

/**
 * Returns a list of app settings for the currently activated apps in the given organisation
 * @param organisationIdentifier ID of the organisation containing the apps
 */
const activeAppsForOrganisation = async (
  organisationIdentifier: string
): Promise<OrganisationAppSettings[]> => {
  const response = await fetch(
    BASE_URL + '/organisation/' + organisationIdentifier + '/applications',
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    }
  )
  const body = await response.json()

  if (!response.ok || !body.applications) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }
  return body.applications as OrganisationAppSettings[]
}

/**
 * Returns a list of members within a given organisation
 * @param organisationIdentifier ID of the organisation containing the members
 */
const membersForOrganisation = async (
  organisationIdentifier: string
): Promise<OrganisationMember[]> => {
  const response = await fetch(
    BASE_URL + '/organisation/' + organisationIdentifier + '/members',
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    }
  )
  const body = await response.json()

  if (!response.ok || !body.members) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }
  return body.members as OrganisationMember[]
}

/**
 * Attempts to invite a new member using the provided details
 * @param organisationIdentifier ID of the organisation to which the new member should be invited
 * @param member Payload containing new member information such as email and desired level
 * @throws {BackendError} Possibly Unauthorised, MissingRequiredFields, NotFound or Conflict
 * @throws {Error} Can throw a generic error with a message
 */
const inviteMemberToOrganisation = async (
  organisationIdentifier: string,
  member: {
    email: string
    desired_level: string
  }
): Promise<OrganisationMember> => {
  const response = await fetch(
    BASE_URL + `/organisation/${organisationIdentifier}/member`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
      body: JSON.stringify({ ...member }),
    }
  )
  const body = await response.json()

  if (!response.ok || !body.user) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      case 'Missing required fields':
        throw new BackendError(ErrorType.MissingRequiredFields, body.message)
      case 'This organisation does not exist':
        throw new BackendError(ErrorType.NotFound, body.message)
      case 'This member has already been invited':
        throw new BackendError(ErrorType.Conflict, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }
  return body.user as OrganisationMember
}

/**
 * Attempts to remove a member from an organisation using the provided details
 * @param organisationIdentifier ID of the organisation from which the member should be removed
 * @param memberIdentifier ID of the member which should be removed (email in case the member isn't verified yet)
 * @throws {BackendError} Possibly Unauthorised or NotFound
 * @throws {Error} Can throw a generic error with a message
 */
const removeMemberFromOrganisation = async (
  organisationIdentifier: string,
  memberIdentifier: string
): Promise<void> => {
  const response = await fetch(
    BASE_URL +
      `/organisation/${organisationIdentifier}/member/${encodeURIComponent(
        memberIdentifier
      )}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    }
  )
  const body = await response.json()

  if (!response.ok) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      case 'This organisation does not exist':
        throw new BackendError(ErrorType.NotFound, body.message)
      case 'This user is not a member':
        throw new BackendError(ErrorType.NotFound, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }
}

/**
 * Attempts to change organisation information using the provided details
 * @param organisationIdentifier ID of the organisation of which the information should be changed
 * @param organisationInformation Payload containing changed organisation information such as name and industry
 * @throws {BackendError} Possibly Unauthorised
 * @throws {Error} Can throw a generic error with a message
 */
const changeOrganisationInformation = async (
  organisationIdentifier: string,
  organisationInformation: {
    name?: string
    industry?: string
  }
): Promise<Organisation> => {
  const response = await fetch(
    BASE_URL + `/organisation/${organisationIdentifier}`,
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
      body: JSON.stringify({ ...organisationInformation }),
    }
  )
  const body = await response.json()

  if (!response.ok || !body.organisation) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }
  return body.organisation as Organisation
}

const Organisations = {
  createNewOrganisation,
  organisationInformation,
  activeAppsForOrganisation,
  membersForOrganisation,
  inviteMemberToOrganisation,
  removeMemberFromOrganisation,
  changeOrganisationInformation,
}

export default Organisations
