import {
  Dialog,
  FeedbackBanner,
  Flex,
  InputField,
} from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import Authentication from '../../../../networking/Authentication'
import { ErrorType } from '../../../../networking/Errors'
import { useRootStore } from '../../../../stores/RootStoreContext'
import { PASSWORD_REQUIREMENTS } from '../../../authentication/RegistrationPage'
import './ChangePassword.scss'

interface ChangePasswordModalProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const ChangePasswordModal = (props: ChangePasswordModalProps) => {
  const { authenticationStore } = useRootStore()

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  )
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const [currentPassword, setCurrentPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('')

  const resetFields = () => {
    setCurrentPassword('')
    setNewPassword('')
    setConfirmNewPassword('')
    setIsSaving(false)
  }

  const changePasswordOfUser = async (
    old_password: string,
    new_password: string
  ) => {
    setIsSaving(true)
    try {
      authenticationStore.currentUser = await Authentication.changeUserPassword(
        { old_password, new_password }
      )
      setErrorMessage(undefined)
      props.setIsOpen(false)
    } catch (error: any) {
      switch (error.type) {
        case ErrorType.Unauthorised:
          return setErrorMessage('Das eingegebene Kennwort ist falsch.')
        case ErrorType.MissingRequiredFields:
          return setErrorMessage('Bitte fülle alle Felder aus.')
        case ErrorType.InvalidCredentials:
          return setErrorMessage(PASSWORD_REQUIREMENTS)
        default:
          setErrorMessage(
            error.message ??
              'Ein unbekannter Fehler ist aufgetreten. Bitte versuche es erneut.'
          )
      }
    } finally {
      setIsSaving(false)
    }
  }

  useEffect(() => {
    if (props.isOpen) {
      setErrorMessage(undefined)
      resetFields()
    }
  }, [props.isOpen])

  return (
    <Dialog
      title='Kennwort ändern'
      className='change-password'
      initialFocusIndex={0}
      isOpen={props.isOpen}
      icon='access'
      dismissable
      onDismiss={() => {
        props.setIsOpen(false)
      }}
      actions={[
        {
          children: 'Abbrechen',
          onClick: () => {
            props.setIsOpen(false)
          },
        },
        {
          children: 'Kennwort ändern',
          variant: 'primary',
          isLoading: isSaving,
          onClick: () => {
            if (newPassword !== confirmNewPassword) {
              setErrorMessage('Die neuen Passwörter stimmen nicht überein.')
            } else {
              changePasswordOfUser(currentPassword, newPassword)
            }
          },
        },
      ]}
    >
      <Flex direction='vertical'>
        {errorMessage && (
          <FeedbackBanner variant='error'>{errorMessage}</FeedbackBanner>
        )}
        <InputField
          type='password'
          label='Aktuelles Kennwort'
          value={currentPassword}
          onChange={setCurrentPassword}
        />
        <InputField
          type='password'
          label='Neues Kennwort'
          value={newPassword}
          onChange={setNewPassword}
        />
        <InputField
          type='password'
          label='Neues Kennwort bestätigen'
          value={confirmNewPassword}
          onChange={setConfirmNewPassword}
        />
      </Flex>
    </Dialog>
  )
}

export default observer(ChangePasswordModal)
