import { Button, Flex, Text } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import './Tabs.scss'

export type Tab<ID = string> = {
  identifier: ID
  title: string
  onClick?: () => void
}

interface TabsProps<ID> {
  className?: string
  tabs: Tab<ID>[]
  currentTabId: ID
  onTabSelected?: (tab: Tab<ID>) => void
}

const Tabs = <ID,>(props: TabsProps<ID>) => {
  return (
    <Flex className={classNames('tabs', props.className)}>
      {props.tabs.map((tab) => (
        <Button
          key={tab.title}
          variant='shell'
          onClick={() => {
            if (props.onTabSelected) props.onTabSelected(tab)
            if (tab.onClick) tab.onClick()
          }}
          className={`tab ${
            tab.identifier === props.currentTabId ? 'current' : ''
          }`.trim()}
        >
          <Flex
            direction='vertical'
            className='content'
            horizontalAlignment='center'
          >
            <Text type='inline' className='title'>
              {tab.title}
            </Text>
            <div className='indicator' />
          </Flex>
        </Button>
      ))}
    </Flex>
  )
}

export default Tabs
