import { Flex, NonIdealState, Spacer } from '@nextbusiness/infinity-ui'
import { useHistory } from 'react-router-dom'
import AppLauncherHelpText from '../AppLauncherHelpText'

interface AppStartFailedProps {
  errorMessage?: string
}

const AppStartFailed = (props: AppStartFailedProps) => {
  const history = useHistory()
  return (
    <NonIdealState
      title='Die App konnte nicht gestartet werden'
      icon='alert'
      actions={[
        {
          children: 'Zurück zum Dashboard',
          onClick: () => history.push('/dashboard'),
        },
      ]}
      style={{ height: 'calc(100vh - 5.4rem)' }}
    >
      <Flex direction='vertical'>
        {props.errorMessage}
        <Spacer size='tiny' />
      </Flex>
      <AppLauncherHelpText />
    </NonIdealState>
  )
}

export default AppStartFailed
