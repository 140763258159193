import {
  Dialog,
  FeedbackBanner,
  InputField,
  Text,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import User from '../../../model/User'
import Authentication from '../../../networking/Authentication'
import { ErrorType } from '../../../networking/Errors'
import { useRootStore } from '../../../stores/RootStoreContext'
import { useEnter } from '../../../utility/hooks'

interface LoginUsingRecoveryCodeModalProps {
  email: string
  password: string
  isOpen: boolean
  onClose: () => void
}

const LoginUsingRecoveryCodeModal = (
  props: LoginUsingRecoveryCodeModalProps
) => {
  const { authenticationStore } = useRootStore()
  const notificationCenter = useNotificationCenter()
  const history = useHistory()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [recoveryCode, setRecoveryCode] = useState<string>('')

  const [isCodeIncorrect, setIsCodeIncorrect] = useState<boolean>(false)

  const loginUsingCode = async () => {
    if (!recoveryCode) return setIsCodeIncorrect(true)
    try {
      setIsLoading(true)
      const [user, token] = await Authentication.loginUsingCredentials(
        props.email,
        props.password,
        undefined,
        recoveryCode
      )
      authenticationStore.handleSignIn(user, token, props.email)
    } catch (error: any) {
      switch (error.type) {
        case ErrorType.MissingRequiredFields:
        case ErrorType.TwoFactorIncorrect:
          return setIsCodeIncorrect(true)
        case ErrorType.VerificationPending:
          if (error.additionalData) {
            authenticationStore.currentUser = error.additionalData as User
          }
          return history.push('/verification-prompt')
        default:
          notificationCenter.addNotification({
            title: 'Anmeldung mit Recovery Code fehlgeschlagen',
            children:
              error.message ??
              'Ein unbekannter Fehler ist aufgetreten. Bitte erneut versuchen.',
            variant: 'error',
          })
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setIsCodeIncorrect(false)
  }, [recoveryCode])

  useEnter(() => loginUsingCode())

  const errorText = () => {
    if (recoveryCode.trim() === '') {
      return 'Du musst einen Recovery-Code eingeben.'
    } else if (recoveryCode.includes(' ')) {
      return 'Bitte verwende nur einen Recovery-Code aufs Mal.'
    } else {
      return 'Der eingegebene Recovery-Code ist falsch oder wurde bereits verwendet.'
    }
  }

  return (
    <Dialog
      title='Mit Recovery Code anmelden'
      icon='forgot-password'
      style={{ maxWidth: '54rem' }}
      {...props}
      actions={[
        { children: 'Abbrechen', onClick: props.onClose },
        {
          children: 'Anmelden',
          variant: 'primary',
          onClick: loginUsingCode,
          isLoading,
        },
      ]}
    >
      <Text type='paragraph'>
        Wenn du keinen Zugriff auf deine Zwei-Faktor-App hast, kannst du dich
        stattdessen mit einem deiner Recovery-Codes anmelden.
      </Text>
      <InputField
        value={recoveryCode}
        onChange={setRecoveryCode}
        fullWidth
        placeholder='Recovery-Code eingeben'
        hasError={isCodeIncorrect}
        helperText={isCodeIncorrect ? errorText() : undefined}
      />
      <FeedbackBanner variant='help' title='Wo finde ich meinen Recovery Code?'>
        Die Recovery-Codes sind diejenigen Codes, die du während der Einrichtung
        der Zwei-Faktor-Authentifizierung gespeichert hast. Jeder Code kann nur
        einmal verwendet werden.
      </FeedbackBanner>
    </Dialog>
  )
}

export default observer(LoginUsingRecoveryCodeModal)
