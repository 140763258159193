import { Button, FeedbackBanner, Text } from '@nextbusiness/infinity-ui'
import SettingsGroup from '../../../../components/settings/SettingsGroup'
import { TwoFactorSetupDetails } from '../../../../model/User'

interface SetupPartRecoveryCodesProps {
  setupDetails: TwoFactorSetupDetails
}

const SetupPartRecoveryCodes = (props: SetupPartRecoveryCodesProps) => {
  const downloadRecoveryCodes = () => {
    const recoveryCodes = props.setupDetails.recoveryCodes.join('\n')
    const element = document.createElement('a')
    const file = new Blob([recoveryCodes], { type: 'text/plain' })

    element.href = URL.createObjectURL(file)
    element.download = 'recovery-codes.txt'

    document.body.appendChild(element)
    element.click()
  }

  return (
    <SettingsGroup title='2. Recovery Codes speichern'>
      <Text type='paragraph'>
        Wenn du Zugriff auf deine Zwei-Faktor-App verlierst, kannst du dich mit
        einem der folgenden Codes wieder anmelden, um die
        Zwei-Faktor-Authentifizierung neu einzurichten. Speichere diese Codes
        deshalb an einem sicheren Ort.
      </Text>
      <FeedbackBanner
        title='Diese Codes werden dir nur einmal angezeigt.'
        variant='info'
      >
        Stelle sicher, dass du sie gespeichert hast, bevor du fortfährst, damit
        du den Zugriff auf dein Konto nicht verlierst.
      </FeedbackBanner>
      <pre className='recovery-codes'>
        {props.setupDetails.recoveryCodes.map((code) => (
          <div key={code}>{code}</div>
        ))}
      </pre>
      <Button
        variant='primary'
        iconLeft='file-download'
        onClick={() => downloadRecoveryCodes()}
      >
        Recovery Codes herunterladen
      </Button>
    </SettingsGroup>
  )
}

export default SetupPartRecoveryCodes
