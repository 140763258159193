import { Helmet } from 'react-helmet'
import SettingsSubpage from '../../../components/settings/SettingsSubpage'
import PasswordSettingsGroup from './groups/PasswordSettingsGroup'
import TwoFactorSettingsGroup from './groups/TwoFactorSettingsGroup'

const SettingsSecurity = () => {
  return (
    <SettingsSubpage className='page-security'>
      <Helmet>
        <title>Sicherheit - Infinity</title>
      </Helmet>
      <PasswordSettingsGroup />
      <TwoFactorSettingsGroup />
    </SettingsSubpage>
  )
}

export default SettingsSecurity
