import { action, makeObservable, observable, runInAction } from 'mobx'
import BillingCard from '../model/BillingCard'
import BillingDetails from '../model/BillingDetails'
import Subscription from '../model/Subscription'
import Billing from '../networking/Billing'
import RootStore from './RootStore'
import Store from './Store'

export default class BillingStore extends Store {
  @observable isHydrated: boolean = false
  @observable subscriptions: Subscription[] = []
  @observable details: BillingDetails | null = null
  @observable card: BillingCard | null = null
  @observable checkoutSessionIdentifier: string | null = null

  constructor(root: RootStore) {
    super(root)
    makeObservable(this)
  }

  @action
  reset() {
    this.isHydrated = false
    this.subscriptions = []
    this.details = null
    this.card = null
    this.checkoutSessionIdentifier = null
  }

  @action
  async loadBillingDetails() {
    if (!this.rootStore.organisationStore.currentOrganisation?.stripeCustomerId)
      return

    const [details, card] = await Billing.currentBillingDetails(
      this.rootStore.organisationStore.currentOrganisation?.id
    )

    runInAction(() => {
      this.details = details
      this.card = card
    })
  }

  @action
  async loadApps(organisationIdentifier: string) {
    const apps = await Billing.subscriptions(organisationIdentifier)
    runInAction(() => {
      this.subscriptions = apps
      this.isHydrated = true
    })
  }

  subscriptionForApplication(
    applicationIdentifier: string
  ): Subscription | null {
    const subscriptions = this.subscriptions.filter((subscription) =>
      subscription.apps.find((app) => app === applicationIdentifier)
    )
    const fullVersion = subscriptions.filter(
      (subscription) => subscription.type === 'full'
    )
    const trials = subscriptions.filter(
      (subscription) => subscription.type === 'trialing'
    )
    if (fullVersion.length > 0) {
      return fullVersion[0]
    } else if (trials.length > 0) {
      return trials[0]
    }
    return null
  }

  statusForApplication = (applicationIdentifier: string) => {
    const subscription = this.subscriptionForApplication(applicationIdentifier)
    if (subscription?.type === 'full') {
      return 'active'
    } else if (subscription?.type === 'trialing') {
      return 'trial'
    } else {
      return 'inactive'
    }
  }
}
