import { FeedbackBanner } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import User from '../../../model/User'
import Authentication from '../../../networking/Authentication'
import { ErrorType } from '../../../networking/Errors'
import { useRootStore } from '../../../stores/RootStoreContext'
import AuthPage from '../AuthPage'
import LoginActions from './LoginActions'
import LoginDefaultFields from './LoginDefaultFields'
import LoginTwoFactorPrompt from './LoginTwoFactorPrompt'
import LoginWelcomeBackFields from './LoginWelcomeBackFields'

const LoginPage = () => {
  const { authenticationStore } = useRootStore()
  const history = useHistory()

  const [enteredEmail, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [twoFactorCode, setTwoFactorCode] = useState<string>('')

  const isLoginRemembered =
    !!authenticationStore.loginEmail && !!authenticationStore.loginName
  const email = isLoginRemembered
    ? authenticationStore.loginEmail ?? enteredEmail
    : enteredEmail

  const [isTwoFactorRequired, setIsTwoFactorRequired] = useState<boolean>(false)
  const [isTwoFactorCodeIncorrect, setIsTwoFactorCodeIncorrect] =
    useState<boolean>(false)
  const [isTwoFactorCodeExpired, setIsTwoFactorCodeExpired] =
    useState<boolean>(false)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  )

  const loginUser = async () => {
    try {
      setIsLoading(true)
      const [user, token] = await Authentication.loginUsingCredentials(
        email,
        password,
        twoFactorCode
      )
      authenticationStore.handleSignIn(user, token, email)
    } catch (error: any) {
      setErrorMessage(undefined)
      switch (error.type) {
        case ErrorType.InvalidCredentials:
          return setErrorMessage('E-Mail-Adresse oder Kennwort sind inkorrekt.')
        case ErrorType.MissingRequiredFields:
          return setErrorMessage('Bitte fülle alle Felder aus.')
        case ErrorType.TwoFactorRequired:
          return setIsTwoFactorRequired(true)
        case ErrorType.TwoFactorIncorrect:
          return setIsTwoFactorCodeIncorrect(true)
        case ErrorType.TwoFactorExpired:
          return setIsTwoFactorCodeExpired(true)
        case ErrorType.VerificationPending:
          if (error.additionalData) {
            authenticationStore.currentUser = error.additionalData as User
          }
          return history.push('/verification-prompt')
        default:
          setErrorMessage(
            error.message ??
              'Ein unbekannter Fehler ist aufgetreten. Bitte erneut versuchen.'
          )
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setIsTwoFactorCodeIncorrect(false)
    setIsTwoFactorCodeExpired(false)
    if (twoFactorCode.length === 6) {
      loginUser()
    }
  }, [twoFactorCode])

  return (
    <AuthPage className='login-page'>
      <Helmet>
        <title>
          Infinity Login - Anmelden, um auf das Dashboard zuzugreifen
        </title>
      </Helmet>
      {isTwoFactorRequired ? (
        <LoginTwoFactorPrompt
          setTwoFactorCode={setTwoFactorCode}
          isTwoFactorCodeIncorrect={isTwoFactorCodeIncorrect}
          isTwoFactorCodeExpired={isTwoFactorCodeExpired}
        />
      ) : isLoginRemembered ? (
        <LoginWelcomeBackFields
          password={password}
          setPassword={setPassword}
          onEnterPressed={loginUser}
        />
      ) : (
        <LoginDefaultFields
          email={email}
          password={password}
          setEmail={setEmail}
          setPassword={setPassword}
          onEnterPressed={loginUser}
        />
      )}
      {errorMessage && (
        <FeedbackBanner variant='error'>{errorMessage}</FeedbackBanner>
      )}
      <LoginActions
        email={email}
        password={password}
        isLoggingIn={isLoading}
        isLoginRemembered={isLoginRemembered}
        isTwoFactorRequired={isTwoFactorRequired}
        onLoginClicked={loginUser}
      />
    </AuthPage>
  )
}

export default observer(LoginPage)
