export enum PerceptionalDelays {
  Lookup = 500,
  Normal = 800,
  IntensiveCalculation = 1750,
}

const perceptionalDelay = async (
  delayInMilliseconds: number = PerceptionalDelays.Normal
) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delayInMilliseconds)
  })
}

export const perceptionalDelayWithState = async (
  setIsLoading: (isLoading: boolean) => void,
  delayInMilliseconds: number = PerceptionalDelays.Normal
) => {
  setIsLoading(true)
  await perceptionalDelay(delayInMilliseconds)
  setIsLoading(false)
}

export default perceptionalDelay
