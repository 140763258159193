import { Permission, PermissionStatus } from '../model/App'
import { currentToken } from './Authentication'
import { BackendError, ErrorType } from './Errors'
import { BASE_URL, DEFAULT_ERROR } from './networkingDefaults'

/**
 * Attempts to get one-time-access-token for an app using the provided details
 * @param organisationIdentifier ID of the organisation from which the app is started
 * @param applicationIdentifier ID of the app for which access token is being requested
 * @throws {BackendError} Possibly Unauthorised, NotFound, InsufficientSubscription or InsufficientPermissions
 * @throws {Error} Can throw a generic error with a message
 */
const authenticateApp = async (
  organisationIdentifier: string,
  applicationIdentifier: string
): Promise<[string, string]> => {
  const response = await fetch(
    BASE_URL +
      `/organisation/${organisationIdentifier}/application/${encodeURIComponent(
        applicationIdentifier
      )}/authToken`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    }
  )
  const body = await response.json()

  if (!response.ok || !body.token || !body.redirectUrl) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      case 'This application does not exist':
        throw new BackendError(ErrorType.NotFound, body.message)
      case 'This organisation does not exist':
        throw new BackendError(ErrorType.NotFound, body.message)
      case 'No active subscription':
        throw new BackendError(ErrorType.InsufficientSubscription, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }
  return [body.token, body.redirectUrl]
}

/**
 * Updates the specified permission for a given application
 * @param organisationIdentifier ID of the organisation for which the app permissions are set
 * @param applicationIdentifier ID of the app for which permissions are set
 * @throws {BackendError} Possibly Unauthorised, NotFound or MissingRequiredFields
 * @throws {Error} Can throw a generic error with a message
 */
const setPermission = async (
  organisationIdentifier: string,
  applicationIdentifier: string,
  permission: Permission,
  status: PermissionStatus
): Promise<void> => {
  const response = await fetch(
    BASE_URL +
      `/organisation/${organisationIdentifier}/application/${encodeURIComponent(
        applicationIdentifier
      )}/permissions`,
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
      body: JSON.stringify({
        chosenPermissionStatus: status,
        permissionToChange: permission,
      }),
    }
  )
  const body = await response.json()

  if (!response.ok || !body.applications) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      case 'Organisation is not registered':
        throw new BackendError(ErrorType.NotFound, body.message, 'organisation')
      case 'This application does not exist':
        throw new BackendError(ErrorType.NotFound, body.message, 'appliaction')
      case 'Required fields are missing':
        throw new BackendError(ErrorType.MissingRequiredFields, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }
}

const Apps = {
  authenticateApp,
  setPermission,
}

export default Apps
