import {
  Button,
  Dialog,
  FeedbackBanner,
  Flex,
  Heading,
  InputField,
  LoadingIndicator,
  RichSelect,
  Spacer,
  Text,
} from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import { Industries } from '../../model/Industries'
import Organisation from '../../model/Organisation'
import Billing from '../../networking/Billing'
import { ErrorType } from '../../networking/Errors'
import Organisations from '../../networking/Organisations'
import { useRootStore } from '../../stores/RootStoreContext'
import '../../styles/AuthPages.scss'
import MarketplaceApps from '../marketplace/MarketplaceApps'
import AuthPage from './AuthPage'
import './SetupOrganisationPage.scss'

const isRunningInFrame = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

const SetupOrganisationPage = () => {
  const { authenticationStore, organisationStore, billingStore } =
    useRootStore()
  const history = useHistory()

  const [organisationName, setOrganisationNameValue] = useState<string>('')
  const [industry, setIndustry] = useState<string>()

  const [loadingText, setLoadingText] = useState<string>()
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)

  const canGoBackToDashboard =
    (authenticationStore.currentUser?.organisations.length || 0) > 0

  const shouldAutoSetupApp =
    !canGoBackToDashboard && !!authenticationStore.currentUser?.signupFromFlow
  const appFromFlow = MarketplaceApps.find(
    (app) => app.identifier === authenticationStore.currentUser?.signupFromFlow
  )

  const [errorMessage, setErrorMessage] = useState<string>()

  const resetErrorMessages = () => {
    setErrorMessage(undefined)
  }

  const autoSetupApp = async (organisation: Organisation): Promise<boolean> => {
    if (!shouldAutoSetupApp || !appFromFlow) return true

    setLoadingText(`App ${appFromFlow.title} wird eingerichtet.`)
    try {
      await Billing.startTrialForApplication(
        organisation,
        appFromFlow.identifier,
        appFromFlow.subscriptionPriceId
      )
      await organisationStore.loadActiveApps()
      await billingStore.loadApps(organisation.id)
      if (isRunningInFrame()) {
        window.open(`/app/${appFromFlow.identifier}`, '_blank')
        history.push(`/welcome`)
      } else {
        history.push(`/app/${appFromFlow.identifier}`)
      }
      return false
    } catch (error) {
      history.push(`/marketplace/apps/${appFromFlow.identifier}`)
      return false
    }
  }

  const createOrganisation = async (
    organisation_name: string,
    industry: string
  ) => {
    setShouldValidate(true)
    if (!organisationName) return
    try {
      setLoadingText('Organisation wird erstellt.')
      const organisation = await Organisations.createNewOrganisation({
        organisation_name,
        industry: industry || Industries.other,
      })
      await authenticationStore.loadUserData()
      organisationStore.selectOrganisation(organisation.id)
      const continueToDashboard = await autoSetupApp(organisation)
      if (continueToDashboard) history.push('/dashboard')
    } catch (error: any) {
      resetErrorMessages()
      switch (error.type) {
        case ErrorType.Unauthorised:
          return setErrorMessage(
            'Deine Sitzung ist abgelaufen. Bitte logge dich erneut ein.'
          )
        case ErrorType.MissingRequiredFields:
          return setErrorMessage('Bitte fülle alle Pflichtfelder aus.')
        default:
          setErrorMessage(
            'Ein unbekannter Fehler ist aufgetreten. Bitte versuche es erneut.'
          )
      }
    } finally {
      setLoadingText(undefined)
    }
  }
  return (
    <AuthPage className='organisation-setup-page'>
      <Helmet>
        <title>Neue Organisation erstellen - Infinity</title>
      </Helmet>
      <Heading type='h1' bareTop divider>
        Organisation erstellen
      </Heading>
      <Flex gap='tiny' direction='vertical' className='fields'>
        <div className='field'>
          <Text>Wie möchtest du deine Organisation benennen?</Text>
          <InputField
            className='input-fields'
            placeholder='Name der Organisation'
            value={organisationName}
            onChange={setOrganisationNameValue}
            icon='organization'
            hasError={shouldValidate && !organisationName}
            helperText={
              shouldValidate && !organisationName
                ? 'Bitte gib einen Namen ein.'
                : undefined
            }
            fullWidth
          />
          <Text type='inline' variant='subtle'>
            Wenn du keinen Firmennamen hast, kannst du z.B. deinen eigenen Namen
            eingeben.
          </Text>
        </div>
        <div className='field'>
          <Text>In welcher Branche bist du tätig?</Text>
          <RichSelect
            placeholder='Branche auswählen'
            options={Object.keys(Industries).map((industryKey) => ({
              label: Industries[industryKey],
              value: industryKey,
            }))}
            value={industry}
            onChange={(value) => setIndustry(value as string)}
          />
        </div>
      </Flex>
      <Spacer size='small' />
      {errorMessage && (
        <FeedbackBanner variant='error'>{errorMessage}</FeedbackBanner>
      )}
      <Spacer size='small' />
      <Flex horizontalAlignment='space-between'>
        <Button
          variant='primary'
          onClick={() => createOrganisation(organisationName, industry!)}
        >
          {shouldAutoSetupApp && appFromFlow
            ? `Weiter zu ${appFromFlow.launcherTitle ?? appFromFlow.title}`
            : 'Erstellen'}
        </Button>
        <div style={{ flexGrow: 1 }} />
        {canGoBackToDashboard && !loadingText && (
          <Button onClick={() => history.push('/')}>Abbrechen</Button>
        )}
        <Dialog isOpen={loadingText !== undefined} className='loading-dialog'>
          <LoadingIndicator loadingText={loadingText} />
        </Dialog>
      </Flex>
    </AuthPage>
  )
}
export default observer(SetupOrganisationPage)
