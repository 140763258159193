import React, { useEffect } from 'react'
import './SystemPage.scss'

interface SystemPageProps {
  children: React.ReactNode
}

const SystemPage = (props: SystemPageProps) => {
  useEffect(() => {
    document.body.classList.add('system-page-present')
    return () => document.body.classList.remove('system-page-present')
  })

  return <div className='iy-system-page'>{props.children}</div>
}

export default SystemPage
