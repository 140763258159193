import classNames from 'classnames'
import AuthCode from 'react-auth-code-input'
import { useEnter } from '../../utility/hooks'
import './AuthCodeInput.scss'

interface AuthCodeInputProps {
  onChange: (value: string) => void
  onPressEnter?: () => void
  autoFocus?: boolean
  hasError?: boolean
  helperText?: string
}

const AuthCodeInput = (props: AuthCodeInputProps) => {
  const { onPressEnter } = props

  useEnter(() => {
    if (onPressEnter) {
      onPressEnter()
    }
  })

  return (
    <div
      className={classNames('ui-auth-code ui-input-field-wrapper', {
        'has-error': props.hasError,
      })}
    >
      <AuthCode
        onChange={props.onChange}
        allowedCharacters='numeric'
        autoFocus={props.autoFocus ?? false}
        containerClassName='ui-auth-code-input'
        inputClassName='ui-input-field'
        ariaLabel='Zwei-Faktor-Bestätigungscode'
      />
      {props.helperText && (
        <span className='ui-input-field-helper-text'>{props.helperText}</span>
      )}
    </div>
  )
}

export default AuthCodeInput
