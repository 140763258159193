type LoginIntentType = 'default' | 'applauncher'

interface ILoginIntent {
  type: LoginIntentType
}

export interface IAppLauncherLoginIntent extends ILoginIntent {
  type: 'applauncher'
  app?: string
  modifier?: string
  launcher?: string
  organisation?: string
  appLaunchHost?: string
}

export const isAppLauncherLoginIntent = (
  intent: ILoginIntent
): intent is IAppLauncherLoginIntent => intent.type === 'applauncher'

export default ILoginIntent
