import { Flex, NonIdealState, Text } from '@nextbusiness/infinity-ui'
import { useHistory } from 'react-router-dom'
import AppLauncherHelpText from '../AppLauncherHelpText'

const AppNotActivated = () => {
  const history = useHistory()
  return (
    <NonIdealState
      title='App nicht aktiviert'
      icon='apps'
      actions={[
        {
          children: 'Zurück zum Dashboard',
          variant: 'tertiary',
          onClick: () => history.push('/dashboard'),
        },
        {
          children: 'Apps verwalten',
          onClick: () => history.push('/marketplace'),
        },
      ]}
      style={{ height: 'calc(100vh - 5.4rem)' }}
    >
      <Flex direction='vertical'>
        <Text type='paragraph' style={{ width: '40rem' }}>
          Deine Organisation hat kein Abonnement für diese App eingerichtet. Im
          Marketplace kannst du dir die möglichen Optionen anschauen.
        </Text>
      </Flex>
      <AppLauncherHelpText />
    </NonIdealState>
  )
}

export default AppNotActivated
