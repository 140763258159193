import { Button, Flex, Text } from '@nextbusiness/infinity-ui'
import { Icon, IconItem, IconSize } from '@nextbusiness/infinity-ui-icons'
import { LauncherAppearance } from '../../pages/marketplace/MarketplaceApp'
import { changeColorLightness } from '../../utility/colors'
import './AppTile.scss'
import AppTileTransition from './AppTileTransition'

interface AppTileProps {
  identifier: string
  displayName: string
  onClick?: () => void
  icon?: IconItem
  appIcon?: string
  className?: string
  themeColor?: string
  customAppearance?: LauncherAppearance
  animated?: boolean
}

const AppTile = (props: AppTileProps) => {
  const appTileButton = () => (
    <Button
      variant='shell'
      className={(
        'app-tile ' +
        props.identifier.replaceAll('.', '-') +
        (props.className ? ' ' + props.className : '')
      ).trim()}
      onClick={props.onClick}
      style={
        props.customAppearance
          ? colorStyleWithCustomAppearance(props.customAppearance)
          : props.themeColor
          ? colorStyleForAppTile(props.themeColor)
          : undefined
      }
    >
      <Flex
        fillContainer
        horizontalAlignment='flex-end'
        direction='vertical'
        verticalAlignment='flex-start'
      >
        {props.icon && <Icon size={IconSize.Medium} icon={props.icon} />}
        {props.appIcon && (
          <img
            src={'/assets/apps/icons/' + props.appIcon}
            alt=''
            className='app-icon'
          />
        )}
        <Text
          type='paragraph'
          className='app-title'
          style={{
            color: props.customAppearance?.useDarkText ? '#000' : undefined,
          }}
        >
          {props.displayName}
        </Text>
      </Flex>
    </Button>
  )

  if (props.animated) {
    return <AppTileTransition>{appTileButton()}</AppTileTransition>
  }
  return appTileButton()
}

const colorStyleForAppTile = (themeColor: string) => ({
  background: themeColor,
  backgroundImage: `linear-gradient(133deg, ${changeColorLightness(
    themeColor,
    -20
  )} 0%, ${changeColorLightness(themeColor, 60)} 100%)`,
})

const colorStyleWithCustomAppearance = (appearance: LauncherAppearance) => ({
  backgroundImage: `linear-gradient(133deg, ${appearance.gradientStartColor} 0%, ${appearance.gradientEndColor} 100%)`,
})

export default AppTile
