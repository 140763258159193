import { Heading } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import React from 'react'

const transitionSimple = {
  hidden: { x: 50, opacity: 0 },
  visible: { x: 0, opacity: 1 },
}

interface RegistrationStepProps {
  stepper?: React.ReactNode
  title?: React.ReactNode
  icon?: React.ReactNode
  children?: React.ReactNode
  className?: string
  contentClassName?: string
}

const RegistrationStep = (props: RegistrationStepProps) => (
  <motion.div
    className={classNames('registration-step', props.className)}
    initial='hidden'
    animate='visible'
    variants={transitionSimple}
    transition={{
      duration: 0.4,
      type: 'spring',
      stiffness: 55,
      mass: 0.08,
      delay: 0.25,
    }}
  >
    {props.title && (
      <>
        {props.icon}
        <Heading type='h1' bareTop>
          {props.title}
        </Heading>
      </>
    )}
    {props.stepper}
    <motion.div
      initial='hidden'
      animate='visible'
      variants={transitionSimple}
      className={props.contentClassName}
      transition={{
        duration: 0.4,
        type: 'spring',
        stiffness: 55,
        mass: 0.08,
        delay: 0.35,
      }}
      style={
        !props.contentClassName
          ? {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '0.4rem',
            }
          : undefined
      }
    >
      {props.children}
    </motion.div>
  </motion.div>
)

export default RegistrationStep
