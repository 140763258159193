import User, {
  EmailVerificationPollingStatus,
  TwoFactorSetupDetails,
} from '../model/User'
import { BackendError, ErrorType } from './Errors'
import { BASE_URL, DEFAULT_ERROR } from './networkingDefaults'

export let currentToken: string | undefined

/**
 * Sets the authentication token that is passed with every request
 * @param token Infinity backend token
 */
const setCurrentToken = (token: string) => {
  if (!token) return
  currentToken = token
}

/**
 * Attempts to authenticate the user using the provided credentials
 * @param email E-mail address of the user
 * @param password Password of the user
 * @param twoFactorCode Two-factor authentication code
 * @param recoveryCode Two-factor recovery code
 * @throws {BackendError} Possibly InvalidCredentials or Unauthorised
 * @throws {Error} Can throw a generic error with a message
 */
const loginUsingCredentials = async (
  email: string,
  password: string,
  twoFactorCode?: string,
  recoveryCode?: string
): Promise<[User, string]> => {
  const response = await fetch(BASE_URL + '/user/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password, twoFactorCode, recoveryCode }),
  })
  const body = await response.json()

  if (!response.ok || !body.user || !body.token) {
    switch (body.message) {
      case 'Wrong email or password':
        throw new BackendError(ErrorType.InvalidCredentials, body.message)
      case 'Required fields are missing':
        throw new BackendError(ErrorType.MissingRequiredFields, body.message)
      case 'Two-factor authentication code or recovery code is required':
        throw new BackendError(ErrorType.TwoFactorRequired, body.message)
      case 'Invalid two-factor authentication code.':
      case 'Invalid two-factor authentication recovery code.':
        throw new BackendError(ErrorType.TwoFactorIncorrect, body.message)
      case 'Two-factor authentication code has expired.':
        throw new BackendError(ErrorType.TwoFactorExpired, body.message)
      case 'Verification pending':
        throw new BackendError(
          ErrorType.VerificationPending,
          body.message,
          body.user
        )
      default:
        throw new Error(body.message ?? DEFAULT_ERROR)
    }
  }
  return [body.user as User, body.token]
}

/**
 * Retrieves the user profile (containing name, email, ...) for the
 * currently authenticated user
 * @throws {BackendError} Possibly Unauthorised
 * @throws {Error} Can throw a generic error with a message
 */
const retrieveCurrentUser = async (): Promise<User> => {
  const response = await fetch(BASE_URL + '/user/current', {
    headers: {
      Authorization: `Bearer ${currentToken}`,
    },
  })
  const body = await response.json()

  if (!response.ok || !body.user) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }
  return body.user as User
}

/**
 * Attempts to create a new user account using the provided account details
 * @param user Payload containing new account information such as firstname, lastname, email and password
 * @throws {BackendError} Possibly Conflict, InvalidCredentials or MissingRequiredFields
 * @throws {Error} Can throw a generic error with a message
 */
const registerNewAccount = async (user: {
  firstname: string
  lastname: string
  email: string
  password: string
  invite?: string | null
  source?: string | null
  flow?: string | null
}): Promise<[User, string]> => {
  const response = await fetch(
    user.invite
      ? BASE_URL + '/user/claim/' + encodeURIComponent(user.invite)
      : BASE_URL + '/user',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...user }),
    }
  )
  const body = await response.json()

  const ALREADY_REGISTERED = 'This email is already registered'
  const INVALID_PASSWORD =
    'The password must include at least one capital letter, one small letter, one number and the length must be at least 10'
  const MISSING_FIELDS = 'Required fields are missing'

  if (!response.ok || !body.user) {
    switch (body.message) {
      case ALREADY_REGISTERED:
        throw new BackendError(ErrorType.Conflict, body.message)
      case INVALID_PASSWORD:
        throw new BackendError(ErrorType.InvalidCredentials, body.message)
      case MISSING_FIELDS:
        throw new BackendError(ErrorType.MissingRequiredFields, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }
  return [body.user as User, body.verificationPollingId as string]
}

/**
 * Attempts to resend the verification email (to be used during
 * the registration process)
 * @param user The user object containing the current user's email
 * @throws {BackendError} Possibly Unauthorised or TooManyRequests
 * @throws {Error} Can throw a generic error with a message
 */
const resendVerficationEmail = async (user: User) => {
  const response = await fetch(BASE_URL + `/user/${user.id}/verify-email`, {
    method: 'POST',
  })
  const body = await response.json()

  if (!response.ok) {
    switch (response.status) {
      case 401:
        throw new BackendError(ErrorType.Unauthorised, body.message)
      case 429:
        throw new BackendError(ErrorType.TooManyRequests, body.message)
      default:
        throw new Error(body.message ?? DEFAULT_ERROR)
    }
  }
  return
}

/**
 * Returns the current verification status.
 */
const currentVerificationStatus = async (
  user: User,
  verificationStatusId: string
): Promise<EmailVerificationPollingStatus> => {
  const response = await fetch(
    BASE_URL + `/user/${user.id}/verify-email-status/${verificationStatusId}`,
    {
      method: 'POST',
      body: JSON.stringify({ email: user.email }),
      headers: { 'Content-Type': 'application/json' },
    }
  )
  const body = await response.json()

  if (!response.ok || !body.status) {
    switch (response.status) {
      case 401:
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body.message ?? DEFAULT_ERROR)
    }
  }
  return body.status
}

/**
 * Attempts to verify the the e-mail-address of a user using
 * the information that is provided in the query
 * @param token Secret token necessary from e-mail link required for confirmation, will expire after 10 minutes
 * @param userId Identifier belonging to the user account
 */
const verifyEmailUsingToken = async (
  token: string,
  userId: string
): Promise<string | undefined> => {
  const response = await fetch(
    BASE_URL + `/user/${userId}/verify-email/${token}`,
    {
      method: 'POST',
    }
  )
  const body = await response.json()

  if (!response.ok) {
    throw new Error(body.message ?? DEFAULT_ERROR)
  }
  return body.token as string | undefined
}

/**
 * Attempts change a users account data using the provided account details
 * @param changes Payload containing new account information such as firstname, lastname and email
 * @throws {BackendError} Possibly Conflict, InvalidCredentials or MissingRequiredFields
 * @throws {Error} Can throw a generic error with a message
 */
const changeUserInformation = async (changes: {
  firstname?: string
  lastname?: string
  email?: string
}): Promise<User> => {
  const response = await fetch(BASE_URL + '/user/current', {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${currentToken}`,
    },
    body: JSON.stringify({ ...changes }),
  })
  const body = await response.json()

  if (!response.ok || !body.user) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }
  return body.user as User
}

/**
 * Sets up 2FA for the current user
 * @throws {BackendError} Possibly Unauthorised
 * @throws {Error} Can throw a generic error with a message
 */
const setup2FA = async (): Promise<TwoFactorSetupDetails> => {
  const response = await fetch(BASE_URL + '/user/current/two-factor/enable', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${currentToken}`,
    },
  })
  const body = await response.json()

  if (
    !response.ok ||
    !body.isEnabled ||
    !body.setupUri ||
    !body.recoveryCodes
  ) {
    switch (response.status) {
      case 401:
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }
  return {
    setupUri: body.setupUri,
    recoveryCodes: body.recoveryCodes,
  }
}

/**
 * Enforces 2FA for the current user using the provided confirmation code
 * @throws {BackendError} Possibly Unauthorised
 * @throws {Error} Can throw a generic error with a message
 */
const enforce2FA = async (confirmationCode: string): Promise<void> => {
  const response = await fetch(
    BASE_URL + `/user/current/two-factor/enable/${confirmationCode}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
      body: JSON.stringify({ confirmationCode }),
    }
  )
  const body = await response.json()

  if (!response.ok || !body.isEnforced) {
    switch (response.status) {
      case 401:
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }
}

/**
 * Disables 2FA for the current user, requiring the users account password
 * @throws {BackendError} Possibly Unauthorised
 * @throws {Error} Can throw a generic error with a message
 */
const disable2FA = async (password: string): Promise<void> => {
  const response = await fetch(BASE_URL + '/user/current/two-factor/disable', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${currentToken}`,
    },
    body: JSON.stringify({ password }),
  })
  const body = await response.json()

  if (!response.ok || body.isEnabled !== false) {
    switch (response.status) {
      case 401:
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }
}

/**
 * Attempts change a users password using the provided credentials
 * @param passwords Payload containing the old and new password
 * @throws {BackendError} Possibly Unauthorised, InvalidCredentials or MissingRequiredFields
 * @throws {Error} Can throw a generic error with a message
 */
const changeUserPassword = async (passwords: {
  old_password: string
  new_password: string
}): Promise<User> => {
  const response = await fetch(BASE_URL + '/user/current/password', {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${currentToken}`,
    },
    body: JSON.stringify({ ...passwords }),
  })
  const body = await response.json()

  if (!response.ok || !body.user) {
    switch (response.status) {
      case 400:
        throw new BackendError(ErrorType.MissingRequiredFields, body.message)
      case 401:
        throw new BackendError(ErrorType.Unauthorised, body.message)
      case 409:
        throw new BackendError(ErrorType.InvalidCredentials, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }
  return body.user as User
}

/**
 * Attempts to send a reset password linkt
 * @param email Payload containing the email address of the user
 * @throws {Error} Can throw a generic error with a message
 */
const sendResetPasswordLink = async (email: string): Promise<void> => {
  const response = await fetch(BASE_URL + '/users/restorePassword', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  })
  const body = await response.json()

  if (!response.ok) {
    switch (response.status) {
      case 409:
        throw new BackendError(ErrorType.InvalidCredentials, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }
}

/**
 * Attempts to reset a users password using the provided email address
 * @param new_password Payload containing the new password
 * @param userId Payload containing the userId
 * @param secretId Payload containing the secretCode
 * @throws {Error} Can throw a generic error with a message
 */
const resetUserPassword = async (
  new_password: string,
  userId: string,
  secretId: string
): Promise<void> => {
  const response = await fetch(
    BASE_URL + `/user/${userId}/restore/${secretId}`,
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ new_password }),
    }
  )
  const body = await response.json()

  if (!response.ok) {
    switch (response.status) {
      case 409:
        throw new BackendError(ErrorType.InvalidCredentials, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }
}

/**
 * Attempts to delete a user
 * @throws {BackendError} Possibly Unauthorised or InvalidCredentials
 * @throws {Error} Can throw a generic error with a message
 */
const deleteUser = async (): Promise<User> => {
  const response = await fetch(BASE_URL + '/user/current', {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${currentToken}`,
    },
  })
  const body = await response.json()

  if (!response.ok || !body.user) {
    switch (response.status) {
      case 401:
        throw new BackendError(ErrorType.Unauthorised, body.message)
      case 409:
        throw new BackendError(
          ErrorType.InvalidCredentials,
          body.message,
          body.organisations
        )
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }
  return body.user as User
}

const Authentication = {
  setCurrentToken,
  loginUsingCredentials,
  retrieveCurrentUser,
  registerNewAccount,
  resendVerficationEmail,
  verifyEmailUsingToken,
  changeUserInformation,
  changeUserPassword,
  sendResetPasswordLink,
  resetUserPassword,
  deleteUser,
  currentVerificationStatus,
  setup2FA,
  enforce2FA,
  disable2FA,
}

export default Authentication
