import { Button, InputField } from '@nextbusiness/infinity-ui'
import { Icon } from '@nextbusiness/infinity-ui-icons'
import { useCallback, useState } from 'react'
import { useEnter } from '../../../utility/hooks'
import MarketplaceApp from '../../marketplace/MarketplaceApp'
import RegistrationStep from '../registration/RegistrationStep'
import SignupStepper, { SignupStep } from './SignupStepper'

interface AppSignupStepNameProps {
  firstName: string
  lastName: string
  email: string

  setFirstName: (firstName: string) => void
  setLastName: (lastName: string) => void
  setEmail: (email: string) => void

  emailErrorMessage: string | null

  app: MarketplaceApp
  onContinue: () => void
}

const isEmailValid = (email: string) => {
  const re = /\S+@\S+\.\S+/
  return re.test(email)
}

const AppSignupStepName = (props: AppSignupStepNameProps) => {
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const hasErrors =
    !props.firstName ||
    !props.lastName ||
    !props.email ||
    !isEmailValid(props.email)

  const onContinue = useCallback(() => {
    setShouldValidate(true)
    if (!hasErrors) props.onContinue()
  }, [props.onContinue, setShouldValidate, hasErrors])

  useEnter(() => onContinue())

  return (
    <RegistrationStep
      stepper={<SignupStepper currentStep={SignupStep.NameAndEmail} />}
      contentClassName='signup-step-content'
    >
      <p className='instructions'>
        Erstelle ein Infinity-Login, um Infinity Finance kostenlos zu testen.
        Keine Sorge, es dauert nicht lange.
      </p>
      <div className='signup-fields'>
        <div className='signup-field'>
          <label>Dein Name</label>
          <div className='signup-field-stack'>
            <InputField
              value={props.firstName}
              onChange={props.setFirstName}
              placeholder='Vorname'
              fullWidth
              hasError={shouldValidate && !props.firstName}
              helperText={
                shouldValidate && !props.firstName
                  ? 'Vorname ist erforderlich'
                  : ''
              }
            />
            <InputField
              value={props.lastName}
              onChange={props.setLastName}
              placeholder='Nachname'
              fullWidth
              hasError={shouldValidate && !props.lastName}
              helperText={
                shouldValidate && !props.lastName
                  ? 'Nachname ist erforderlich'
                  : ''
              }
            />
          </div>
        </div>
        <div className='signup-field'>
          <label>E-Mail-Adresse</label>
          <InputField
            value={props.email}
            onChange={props.setEmail}
            placeholder='svenja.holzberg@beispiel.com'
            icon='email'
            type='email'
            fullWidth
            hasError={
              !!props.emailErrorMessage ||
              (shouldValidate && (!props.email || !isEmailValid(props.email)))
            }
            helperText={
              props.emailErrorMessage
                ? props.emailErrorMessage
                : shouldValidate && !props.email
                ? 'E-Mail ist erforderlich'
                : shouldValidate && !isEmailValid(props.email)
                ? 'E-Mail ist ungültig'
                : ''
            }
          />
        </div>
      </div>
      <Button variant='primary' onClick={onContinue}>
        Weiter
      </Button>
      <div className='security-note'>
        <Icon icon='check' size={12} />
        <small>Deine Informationen werden verschlüsselt übertragen.</small>
      </div>
    </RegistrationStep>
  )
}

export default AppSignupStepName
