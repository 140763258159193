import { Flex, Heading, Text } from '@nextbusiness/infinity-ui'
import MarketplaceApp from '../../marketplace/MarketplaceApp'
import AppLauncherHelpText from '../AppLauncherHelpText'
import AppLauncherUpgradeAction from '../AppLauncherUpgradeAction'

interface SubscriptionCanceledProps {
  applicationIdentifier: string
  appDetails: MarketplaceApp | undefined
}

const SubscriptionCanceled = (props: SubscriptionCanceledProps) => (
  <Flex
    className='subscription-expired'
    horizontalAlignment='center'
    verticalAlignment='center'
    direction='vertical'
    gap='small'
    fillContainer
    style={{
      height: '100vh',
      backgroundColor: '#FCFDFE',
    }}
  >
    <img
      src={'/assets/apps/icons/' + props.appDetails?.icon}
      alt=''
      className='app-icon'
      width={48}
    />
    <Heading type='h3' bare>
      Abonnement wurde storniert
    </Heading>
    <Text className='explanation'>
      Du hast das Abonnement für {props.appDetails?.title ?? 'diese App'}{' '}
      storniert. Reaktiviere dein Abonnement, um die App weiterzunutzen.
    </Text>
    <AppLauncherUpgradeAction
      applicationIdentifier={props.applicationIdentifier}
      appDetails={props.appDetails}
      customText='Abonnement reaktivieren'
    />
    <AppLauncherHelpText />
  </Flex>
)

export default SubscriptionCanceled
