import { LoadingIndicator } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory, useParams } from 'react-router-dom'
import { useRootStore } from '../../../stores/RootStoreContext'
import AuthPage from '../AuthPage'

const LoginAsPage = () => {
  const { authenticationStore, organisationStore } = useRootStore()
  const history = useHistory()
  const { secretId } = useParams<{ secretId: string }>()

  const isHydrated = authenticationStore.isHydrated
  const DELAY_FOR_POST_HYDRATE_ACTIONS = 2500

  const loginUser = async (token: string) => {
    authenticationStore.logout()
    authenticationStore.setCurrentToken(token)

    organisationStore.currentIdentifier = null
    organisationStore.currentOrganisation = null

    window.setTimeout(async () => {
      await authenticationStore.loadUserData()
      authenticationStore.onLogin()
      history.replace('/')
    }, DELAY_FOR_POST_HYDRATE_ACTIONS)
  }

  useEffect(() => {
    if (secretId && isHydrated) loginUser(secretId)
  }, [secretId, isHydrated])

  return (
    <>
      <AuthPage className='login-page'>
        <Helmet>
          <title>Du wirst angemeldet - Infinity</title>
        </Helmet>
        <LoadingIndicator />
      </AuthPage>
    </>
  )
}

export default observer(LoginAsPage)
