import { Button, Flex, LoadingIndicator } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useRootStore } from '../../stores/RootStoreContext'
import MarketplaceApp from '../marketplace/MarketplaceApp'

interface AppLauncherUpgradeActionProps {
  applicationIdentifier: string
  appDetails?: MarketplaceApp
  additionalActions?: React.ReactNode
  customText?: string
}

const AppLauncherUpgradeAction = (props: AppLauncherUpgradeActionProps) => {
  const history = useHistory()
  const { billingStore, organisationStore } = useRootStore()

  const isLoading =
    organisationStore.currentOrganisation?.stripeCustomerId &&
    !billingStore.details
  const hasSetupPayments =
    billingStore.details &&
    billingStore.details.invoice_settings.default_payment_method &&
    organisationStore.currentOrganisation?.stripeCustomerId

  useEffect(() => {
    if (organisationStore.currentOrganisation?.stripeCustomerId)
      billingStore.loadBillingDetails()
  }, [organisationStore.currentOrganisation?.stripeCustomerId])

  if (isLoading) return <LoadingIndicator />

  return (
    <Flex style={{ gap: '1rem' }}>
      <Button
        className='app-launcher-upgrade-action'
        variant='primary'
        onClick={() => {
          const marketplaceURL =
            '/marketplace/apps/' + props.applicationIdentifier
          if (
            !hasSetupPayments ||
            props.appDetails?.pricing.kind === 'tiered'
          ) {
            history.push(`${marketplaceURL}?action=select-plan`)
          } else {
            history.push(marketplaceURL)
          }
        }}
        style={{ backgroundColor: props.appDetails?.keyColor }}
      >
        {props.customText ?? 'Abonnement auswählen'}
      </Button>
      {props.additionalActions}
    </Flex>
  )
}

export default observer(AppLauncherUpgradeAction)
