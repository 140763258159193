import {
  Heading,
  InlineEdit,
  Option,
  Select,
  Spacer,
} from '@nextbusiness/infinity-ui'
import { InlineEditProps } from '@nextbusiness/infinity-ui/dist/input/inline-edit/InlineEdit'
import React, { useState } from 'react'
import { TypedKeyValueStore } from '../../utility/types'
import './InlineEditList.scss'

type InlineEditType = 'input' | 'select' | 'other'

interface InlineEditField extends InlineEditProps {
  title?: string
  selectWithOptions?: Option[] | undefined
  key?: string
  type: InlineEditType
  helperText?: string
}

interface InlineEditListProps {
  fields: InlineEditField[]
  onCommit?: (changes: TypedKeyValueStore<any>) => Promise<void>
  appearance?: 'default' | 'aligned'
}

const inputControlForInlineEdit = (field: InlineEditField) => {
  switch (field.type) {
    case 'input':
      return undefined
    case 'select':
      return <Select options={field.selectWithOptions} />
    default:
      return field.inputControl
  }
}

const InlineEditList = (props: InlineEditListProps) => {
  const [activeInlineEdit, setActiveInlineEdit] = useState<number>()

  return (
    <div
      className={`inline-edit-list apperance-${props.appearance ?? 'default'}`}
    >
      {props.fields?.map((field, index) => (
        <div key={index} className='inline-edit-list-row'>
          {field.title && (
            <Heading type='h4' bareTop className='field-title'>
              {field.title}
            </Heading>
          )}
          <div className='inline-edit-field'>
            <InlineEdit
              className='field-value'
              onActivate={() => setActiveInlineEdit(index)}
              shouldDeactivate={activeInlineEdit !== index}
              key={field.key ?? index}
              inputControl={inputControlForInlineEdit(field)}
              renderValue={
                field.type === 'select'
                  ? (option: Option) => option.label
                  : undefined
              }
              onCommit={async (value) => {
                if (!field.key || !props.onCommit) return
                if (field.type === 'select') {
                  await props.onCommit({ [field.key]: value.value })
                } else {
                  await props.onCommit({ [field.key]: value })
                }
              }}
              {...field}
            />
            {field.helperText && (
              <p className='inline-edit-helper-text'>{field.helperText}</p>
            )}
          </div>
          {props.appearance !== 'aligned' && (
            <Spacer size='tiny' className='inline-edit-field-spacing' />
          )}
        </div>
      ))}
    </div>
  )
}

export default InlineEditList
