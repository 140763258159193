import { Button, Flex, Heading, Text } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useState } from 'react'
import { useRootStore } from '../../../stores/RootStoreContext'
import MarketplaceApp from '../../marketplace/MarketplaceApp'

interface AskForConfirmationProps {
  organisationId: string | null
  appDetails: MarketplaceApp | undefined
  onLaunch: () => void
  memoriseLoginIntent: () => void
}

const AskForConfirmation = (props: AskForConfirmationProps) => {
  const { authenticationStore } = useRootStore()

  const [isLaunching, setIsLaunching] = useState<boolean>(false)
  const organisation = authenticationStore.currentUser?.organisations.find(
    (org) => org.IDForAPI === props.organisationId
  )
  return (
    <Flex
      className='launch-confirmation'
      horizontalAlignment='center'
      verticalAlignment='center'
      direction='vertical'
      gap='small'
      fillContainer
      style={{
        height: '100vh',
        backgroundColor: '#FCFDFE',
      }}
    >
      <Flex direction='vertical' gap='small' className='confirmation-container'>
        <img
          src={'/assets/apps/icons/' + props.appDetails?.icon}
          alt=''
          className='app-icon'
          width={48}
        />
        <Heading type='h2'>
          In {props.appDetails?.title ?? 'dieser App'} als {organisation?.name}{' '}
          anmelden
        </Heading>
        <Text>
          Möchtest du dich in {props.appDetails?.title ?? 'dieser App'} mit der
          Organisation {organisation?.name} anmelden?
        </Text>
        <Text>
          Du bist zurzeit mit dem Konto{' '}
          <strong>{authenticationStore.currentUser?.email}</strong> angemeldet.
        </Text>
        <Flex gap='tiny' className='actions' horizontalAlignment='flex-end'>
          <Button
            onClick={() => {
              props.memoriseLoginIntent()
              authenticationStore.logout(true)
            }}
          >
            Anderes Konto
          </Button>
          <Button
            variant='primary'
            onClick={() => {
              setIsLaunching(true)
              props.onLaunch()
            }}
            isLoading={isLaunching}
          >
            Fortfahren
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default observer(AskForConfirmation)
