import { UserMenuOrganisation, UserMenuProps } from '@nextbusiness/infinity-ui'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import ILoginIntent from '../model/LoginIntent'
import User from '../model/User'
import Authentication from '../networking/Authentication'
import { DashboardBackground } from '../pages/dashboard/DashboardPage'
import RootStore from './RootStore'
import Store from './Store'

export default class AuthenticationStore extends Store {
  @persist('object') @observable currentUser: User | null = null

  @persist @observable loginEmail: string | undefined = undefined
  @persist @observable loginName: string | undefined = undefined

  @persist @observable token: string | undefined = undefined

  @persist @observable hasLoggedInBefore: boolean = false
  @observable isHydrated: boolean = false

  @persist @observable dashboardBackground: DashboardBackground = 'bliss'

  rememberedLoginIntent: ILoginIntent | null = null
  signupIntent: { userId: string; verificationId: string } | null = null

  constructor(root: RootStore) {
    super(root)
    makeObservable(this)
  }

  @action
  forgetLogin() {
    this.loginEmail = undefined
    this.loginName = undefined
  }

  @action
  setCurrentToken(token: string) {
    this.token = token
    Authentication.setCurrentToken(token)
  }

  @action
  handleSignIn(user: User, token: string, email: string) {
    this.currentUser = user
    this.rememberUser(user, email)
    this.setCurrentToken(token)
    this.onLogin()
  }

  @action
  rememberUser(user: User, email: string) {
    this.loginEmail = email
    this.loginName = user.firstname + ' ' + user.lastname
  }

  @action
  onLogin() {
    this.selectDefaultOrganisation()
  }

  @computed
  get fullUserName(): string {
    if (this.currentUser) {
      return (
        this.currentUser.firstname +
        ' ' +
        this.currentUser.lastname
      ).trim()
    }
    return ''
  }

  @computed
  get isAuthenticated(): boolean {
    return this.token !== undefined
  }

  @computed
  get userMenu(): UserMenuProps {
    return {
      baseURL: document.location.origin,
      name: this.currentUser?.firstname ?? '',
      allOrganisations: this.organisationsForUserMenu(),
      currentOrganisation: this.currentOrganisationForUserMenu(),
    }
  }

  private currentOrganisationForUserMenu(): UserMenuOrganisation {
    const currentOrganisationId =
      this.rootStore.organisationStore.currentIdentifier
    const currentOrganisation = this.currentUser?.organisations.find(
      (organisation) => organisation.IDForAPI === currentOrganisationId
    )
    return {
      id: currentOrganisationId ?? 'none',
      name: currentOrganisation?.name ?? 'Keine Organisation',
      onClick: () => {},
    }
  }

  private organisationsForUserMenu(): UserMenuOrganisation[] {
    const createOrganisationOption: UserMenuOrganisation = {
      id: 'create',
      name: 'Organisation erstellen',
      onClick: () => (document.location.href = '/setup-organisation'),
    }
    const organisationOptions = this.currentUser?.organisations.map(
      (organisation) => ({
        id: organisation.IDForAPI,
        name: organisation.name,
        onClick: () =>
          this.rootStore.organisationStore.selectOrganisation(
            organisation.IDForAPI,
            true
          ),
      })
    )
    return [...(organisationOptions ?? []), createOrganisationOption]
  }

  @action
  logout(forgetLogin: boolean = false) {
    if (!this.isHydrated) return

    this.currentUser = null
    this.token = undefined
    this.rootStore.organisationStore?.reset()

    if (forgetLogin) {
      this.forgetLogin()
    }
  }

  @action
  async loadUserData() {
    try {
      const user = await Authentication.retrieveCurrentUser()
      runInAction(() => {
        this.currentUser = user
        this.selectDefaultOrganisation()
      })
    } catch {
      this.logout()
    }
  }

  @action
  private selectDefaultOrganisation() {
    const isCurrentIdentifierValid =
      this.rootStore.organisationStore.currentIdentifier &&
      this.currentUser?.organisations.find(
        (organisation) =>
          organisation.IDForAPI ===
          this.rootStore.organisationStore.currentIdentifier
      )
    if (
      this.currentUser &&
      this.currentUser.organisations.length > 0 &&
      !isCurrentIdentifierValid
    ) {
      this.rootStore.organisationStore.selectOrganisation(
        this.currentUser.organisations[0].IDForAPI
      )
    }
  }

  onHydrate() {
    if (this.token) {
      Authentication.setCurrentToken(this.token)
      this.selectDefaultOrganisation()
      this.loadUserData()
    }
    this.isHydrated = true
  }
}
